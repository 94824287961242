const Estados =
  {
    "estados": [
      {"uf" : "RO", "name" : "Rondônia"},
      {"uf" : "AC", "name" : "Acre"},
      {"uf" : "AM", "name" : "Amazonas"},
      {"uf" : "RR", "name" : "Roraima"},
      {"uf" : "PA", "name" : "Pará"},
      {"uf" : "AP", "name" : "Amapá"},
      {"uf" : "TO", "name" : "Tocantins"},
      {"uf" : "MA", "name" : "Maranhão"},
      {"uf" : "PI", "name" : "Piauí"},
      {"uf" : "CE", "name" : "Ceará"},
      {"uf" : "RN", "name" : "Rio Grande do Norte"},
      {"uf" : "PB", "name" : "Paraíba"},
      {"uf" : "PE", "name" : "Pernambuco"},
      {"uf" : "AL", "name" : "Alagoas"},
      {"uf" : "SE", "name" : "Sergipe"},
      {"uf" : "BA", "name" : "Bahia"},
      {"uf" : "MG", "name" : "Minas Gerais"},
      {"uf" : "ES", "name" : "Espírito Santo"},
      {"uf" : "RJ", "name" : "Rio de Janeiro"},
      {"uf" : "SP", "name" : "São Paulo"},
      {"uf" : "PR", "name" : "Paraná"},
      {"uf" : "SC", "name" : "Santa Catarina"},
      {"uf" : "RS", "name" : "Rio Grande do Sul"},
      {"uf" : "MS", "name" : "Mato Grosso do Sul"},
      {"uf" : "MT", "name" : "Mato Grosso"},
      {"uf" : "GO", "name" : "Goiás"},
      {"uf" : "DF", "name" : "Distrito Federal"}
    ],
    "cidades": [
      {"name_uf": "RO",  "name": "Alta Floresta D'Oeste"},
      {"name_uf": "RO",  "name": "Alto Alegre dos Parecis"},
      {"name_uf": "RO",  "name": "Alto Paraíso"},
      {"name_uf": "RO",  "name": "Alvorada D'Oeste"},
      {"name_uf": "RO",  "name": "Ariquemes"},
      {"name_uf": "RO",  "name": "Buritis"},
      {"name_uf": "RO",  "name": "Cabixi"},
      {"name_uf": "RO",  "name": "Cacaulândia"},
      {"name_uf": "RO",  "name": "Cacoal"},
      {"name_uf": "RO",  "name": "Campo Novo de RO"},
      {"name_uf": "RO",  "name": "Candeias do Jamari"},
      {"name_uf": "RO",  "name": "Castanheiras"},
      {"name_uf": "RO",  "name": "Cerejeiras"},
      {"name_uf": "RO",  "name": "Chupinguaia"},
      {"name_uf": "RO",  "name": "Colorado do Oeste"},
      {"name_uf": "RO",  "name": "Corumbiara"},
      {"name_uf": "RO",  "name": "Costa Marques"},
      {"name_uf": "RO",  "name": "Cujubim"},
      {"name_uf": "RO",  "name": "Espigão D'Oeste"},
      {"name_uf": "RO",  "name": "Governador Jorge Teixeira"},
      {"name_uf": "RO",  "name": "Guajará-Mirim"},
      {"name_uf": "RO",  "name": "Itapuã do Oeste"},
      {"name_uf": "RO",  "name": "Jaru"},
      {"name_uf": "RO",  "name": "Ji-PR"},
      {"name_uf": "RO",  "name": "Machadinho D'Oeste"},
      {"name_uf": "RO",  "name": "Ministro Andreazza"},
      {"name_uf": "RO",  "name": "Mirante da Serra"},
      {"name_uf": "RO",  "name": "Monte Negro"},
      {"name_uf": "RO",  "name": "Nova Brasilândia D'Oeste"},
      {"name_uf": "RO",  "name": "Nova Mamoré"},
      {"name_uf": "RO",  "name": "Nova União"},
      {"name_uf": "RO",  "name": "Novo Horizonte do Oeste"},
      {"name_uf": "RO",  "name": "Ouro Preto do Oeste"},
      {"name_uf": "RO",  "name": "Parecis"},
      {"name_uf": "RO",  "name": "Pimenta Bueno"},
      {"name_uf": "RO",  "name": "Pimenteiras do Oeste"},
      {"name_uf": "RO",  "name": "Porto Velho"},
      {"name_uf": "RO",  "name": "Presidente Médici"},
      {"name_uf": "RO",  "name": "Primavera de RO"},
      {"name_uf": "RO",  "name": "Rio Crespo"},
      {"name_uf": "RO",  "name": "Rolim de Moura"},
      {"name_uf": "RO",  "name": "Santa Luzia D'Oeste"},
      {"name_uf": "RO",  "name": "São Felipe D'Oeste"},
      {"name_uf": "RO",  "name": "São Francisco do Guaporé"},
      {"name_uf": "RO",  "name": "São Miguel do Guaporé"},
      {"name_uf": "RO",  "name": "Seringueiras"},
      {"name_uf": "RO",  "name": "Teixeirópolis"},
      {"name_uf": "RO",  "name": "Theobroma"},
      {"name_uf": "RO",  "name": "Urupá"},
      {"name_uf": "RO",  "name": "Vale do Anari"},
      {"name_uf": "RO",  "name": "Vale do Paraíso"},
      {"name_uf": "RO",  "name": "Vilhena"},
      {"name_uf": "AC",  "name": "AClândia"},
      {"name_uf": "AC",  "name": "Assis Brasil"},
      {"name_uf": "AC",  "name": "Brasiléia"},
      {"name_uf": "AC",  "name": "Bujari"},
      {"name_uf": "AC",  "name": "Capixaba"},
      {"name_uf": "AC",  "name": "Cruzeiro do Sul"},
      {"name_uf": "AC",  "name": "Epitaciolândia"},
      {"name_uf": "AC",  "name": "Feijó"},
      {"name_uf": "AC",  "name": "Jordão"},
      {"name_uf": "AC",  "name": "Mâncio Lima"},
      {"name_uf": "AC",  "name": "Manoel Urbano"},
      {"name_uf": "AC",  "name": "Marechal Thaumaturgo"},
      {"name_uf": "AC",  "name": "Plácido de Castro"},
      {"name_uf": "AC",  "name": "Porto AC"},
      {"name_uf": "AC",  "name": "Porto Walter"},
      {"name_uf": "AC",  "name": "Rio Branco"},
      {"name_uf": "AC",  "name": "Rodrigues Alves"},
      {"name_uf": "AC",  "name": "Santa Rosa do Purus"},
      {"name_uf": "AC",  "name": "Sena Madureira"},
      {"name_uf": "AC",  "name": "Senador Guiomard"},
      {"name_uf": "AC",  "name": "Tarauacá"},
      {"name_uf": "AC",  "name": "Xapuri"},
      {"name_uf": "AM",  "name": "Alvarães"},
      {"name_uf": "AM",  "name": "Amaturá"},
      {"name_uf": "AM",  "name": "Anamã"},
      {"name_uf": "AM",  "name": "Anori"},
      {"name_uf": "AM",  "name": "Apuí"},
      {"name_uf": "AM",  "name": "Atalaia do Norte"},
      {"name_uf": "AM",  "name": "Autazes"},
      {"name_uf": "AM",  "name": "Barcelos"},
      {"name_uf": "AM",  "name": "Barreirinha"},
      {"name_uf": "AM",  "name": "Benjamin Constant"},
      {"name_uf": "AM",  "name": "Beruri"},
      {"name_uf": "AM",  "name": "Boa Vista do Ramos"},
      {"name_uf": "AM",  "name": "Boca do AC"},
      {"name_uf": "AM",  "name": "Borba"},
      {"name_uf": "AM",  "name": "Caapiranga"},
      {"name_uf": "AM",  "name": "Canutama"},
      {"name_uf": "AM",  "name": "Carauari"},
      {"name_uf": "AM",  "name": "Careiro"},
      {"name_uf": "AM",  "name": "Careiro da Várzea"},
      {"name_uf": "AM",  "name": "Coari"},
      {"name_uf": "AM",  "name": "Codajás"},
      {"name_uf": "AM",  "name": "Eirunepé"},
      {"name_uf": "AM",  "name": "Envira"},
      {"name_uf": "AM",  "name": "Fonte Boa"},
      {"name_uf": "AM",  "name": "Guajará"},
      {"name_uf": "AM",  "name": "Humaitá"},
      {"name_uf": "AM",  "name": "Ipixuna"},
      {"name_uf": "AM",  "name": "Iranduba"},
      {"name_uf": "AM",  "name": "Itacoatiara"},
      {"name_uf": "AM",  "name": "Itamarati"},
      {"name_uf": "AM",  "name": "Itapiranga"},
      {"name_uf": "AM",  "name": "Japurá"},
      {"name_uf": "AM",  "name": "Juruá"},
      {"name_uf": "AM",  "name": "Jutaí"},
      {"name_uf": "AM",  "name": "Lábrea"},
      {"name_uf": "AM",  "name": "Manacapuru"},
      {"name_uf": "AM",  "name": "Manaquiri"},
      {"name_uf": "AM",  "name": "Manaus"},
      {"name_uf": "AM",  "name": "Manicoré"},
      {"name_uf": "AM",  "name": "Maraã"},
      {"name_uf": "AM",  "name": "Maués"},
      {"name_uf": "AM",  "name": "Nhamundá"},
      {"name_uf": "AM",  "name": "Nova Olinda do Norte"},
      {"name_uf": "AM",  "name": "Novo Airão"},
      {"name_uf": "AM",  "name": "Novo Aripuanã"},
      {"name_uf": "AM",  "name": "Parintins"},
      {"name_uf": "AM",  "name": "Pauini"},
      {"name_uf": "AM",  "name": "Presidente Figueiredo"},
      {"name_uf": "AM",  "name": "Rio Preto da Eva"},
      {"name_uf": "AM",  "name": "Santa Isabel do Rio Negro"},
      {"name_uf": "AM",  "name": "Santo Antônio do Içá"},
      {"name_uf": "AM",  "name": "São Gabriel da Cachoeira"},
      {"name_uf": "AM",  "name": "SP de Olivença"},
      {"name_uf": "AM",  "name": "São Sebastião do Uatumã"},
      {"name_uf": "AM",  "name": "Silves"},
      {"name_uf": "AM",  "name": "Tabatinga"},
      {"name_uf": "AM",  "name": "Tapauá"},
      {"name_uf": "AM",  "name": "Tefé"},
      {"name_uf": "AM",  "name": "Tonantins"},
      {"name_uf": "AM",  "name": "Uarini"},
      {"name_uf": "AM",  "name": "Urucará"},
      {"name_uf": "AM",  "name": "Urucurituba"},
      {"name_uf": "RR",  "name": "Alto Alegre"},
      {"name_uf": "RR",  "name": "Amajari"},
      {"name_uf": "RR",  "name": "Boa Vista"},
      {"name_uf": "RR",  "name": "Bonfim"},
      {"name_uf": "RR",  "name": "Cantá"},
      {"name_uf": "RR",  "name": "Caracaraí"},
      {"name_uf": "RR",  "name": "Caroebe"},
      {"name_uf": "RR",  "name": "Iracema"},
      {"name_uf": "RR",  "name": "Mucajaí"},
      {"name_uf": "RR",  "name": "Normandia"},
      {"name_uf": "RR",  "name": "Pacaraima"},
      {"name_uf": "RR",  "name": "Rorainópolis"},
      {"name_uf": "RR",  "name": "São João da Baliza"},
      {"name_uf": "RR",  "name": "São Luiz"},
      {"name_uf": "RR",  "name": "Uiramutã"},
      {"name_uf": "PA",  "name": "Abaetetuba"},
      {"name_uf": "PA",  "name": "Abel Figueiredo"},
      {"name_uf": "PA",  "name": "Acará"},
      {"name_uf": "PA",  "name": "Afuá"},
      {"name_uf": "PA",  "name": "Água Azul do Norte"},
      {"name_uf": "PA",  "name": "Alenquer"},
      {"name_uf": "PA",  "name": "Almeirim"},
      {"name_uf": "PA",  "name": "Altamira"},
      {"name_uf": "PA",  "name": "Anajás"},
      {"name_uf": "PA",  "name": "Ananindeua"},
      {"name_uf": "PA",  "name": "Anapu"},
      {"name_uf": "PA",  "name": "Augusto Corrêa"},
      {"name_uf": "PA",  "name": "Aurora do PA"},
      {"name_uf": "PA",  "name": "Aveiro"},
      {"name_uf": "PA",  "name": "Bagre"},
      {"name_uf": "PA",  "name": "Baião"},
      {"name_uf": "PA",  "name": "Bannach"},
      {"name_uf": "PA",  "name": "Barcarena"},
      {"name_uf": "PA",  "name": "Belém"},
      {"name_uf": "PA",  "name": "Belterra"},
      {"name_uf": "PA",  "name": "Benevides"},
      {"name_uf": "PA",  "name": "Bom Jesus do TO"},
      {"name_uf": "PA",  "name": "Bonito"},
      {"name_uf": "PA",  "name": "Bragança"},
      {"name_uf": "PA",  "name": "Brasil Novo"},
      {"name_uf": "PA",  "name": "Brejo Grande do Araguaia"},
      {"name_uf": "PA",  "name": "Breu Branco"},
      {"name_uf": "PA",  "name": "Breves"},
      {"name_uf": "PA",  "name": "Bujaru"},
      {"name_uf": "PA",  "name": "Cachoeira do Arari"},
      {"name_uf": "PA",  "name": "Cachoeira do Piriá"},
      {"name_uf": "PA",  "name": "Cametá"},
      {"name_uf": "PA",  "name": "Canaã dos Carajás"},
      {"name_uf": "PA",  "name": "Capanema"},
      {"name_uf": "PA",  "name": "Capitão Poço"},
      {"name_uf": "PA",  "name": "Castanhal"},
      {"name_uf": "PA",  "name": "Chaves"},
      {"name_uf": "PA",  "name": "Colares"},
      {"name_uf": "PA",  "name": "Conceição do Araguaia"},
      {"name_uf": "PA",  "name": "Concórdia do PA"},
      {"name_uf": "PA",  "name": "Cumaru do Norte"},
      {"name_uf": "PA",  "name": "Curionópolis"},
      {"name_uf": "PA",  "name": "Curralinho"},
      {"name_uf": "PA",  "name": "Curuá"},
      {"name_uf": "PA",  "name": "Curuçá"},
      {"name_uf": "PA",  "name": "Dom Eliseu"},
      {"name_uf": "PA",  "name": "Eldorado do Carajás"},
      {"name_uf": "PA",  "name": "Faro"},
      {"name_uf": "PA",  "name": "Floresta do Araguaia"},
      {"name_uf": "PA",  "name": "Garrafão do Norte"},
      {"name_uf": "PA",  "name": "Goianésia do PA"},
      {"name_uf": "PA",  "name": "Gurupá"},
      {"name_uf": "PA",  "name": "Igarapé-Açu"},
      {"name_uf": "PA",  "name": "Igarapé-Miri"},
      {"name_uf": "PA",  "name": "Inhangapi"},
      {"name_uf": "PA",  "name": "Ipixuna do PA"},
      {"name_uf": "PA",  "name": "Irituia"},
      {"name_uf": "PA",  "name": "Itaituba"},
      {"name_uf": "PA",  "name": "Itupiranga"},
      {"name_uf": "PA",  "name": "Jacareacanga"},
      {"name_uf": "PA",  "name": "Jacundá"},
      {"name_uf": "PA",  "name": "Juruti"},
      {"name_uf": "PA",  "name": "Limoeiro do Ajuru"},
      {"name_uf": "PA",  "name": "Mãe do Rio"},
      {"name_uf": "PA",  "name": "Magalhães Barata"},
      {"name_uf": "PA",  "name": "Marabá"},
      {"name_uf": "PA",  "name": "Maracanã"},
      {"name_uf": "PA",  "name": "Marapanim"},
      {"name_uf": "PA",  "name": "Marituba"},
      {"name_uf": "PA",  "name": "Medicilândia"},
      {"name_uf": "PA",  "name": "Melgaço"},
      {"name_uf": "PA",  "name": "Mocajuba"},
      {"name_uf": "PA",  "name": "Moju"},
      {"name_uf": "PA",  "name": "Mojuí dos Campos"},
      {"name_uf": "PA",  "name": "Monte Alegre"},
      {"name_uf": "PA",  "name": "Muaná"},
      {"name_uf": "PA",  "name": "Nova Esperança do Piriá"},
      {"name_uf": "PA",  "name": "Nova Ipixuna"},
      {"name_uf": "PA",  "name": "Nova Timboteua"},
      {"name_uf": "PA",  "name": "Novo Progresso"},
      {"name_uf": "PA",  "name": "Novo Repartimento"},
      {"name_uf": "PA",  "name": "Óbidos"},
      {"name_uf": "PA",  "name": "Oeiras do PA"},
      {"name_uf": "PA",  "name": "Oriximiná"},
      {"name_uf": "PA",  "name": "Ourém"},
      {"name_uf": "PA",  "name": "Ourilândia do Norte"},
      {"name_uf": "PA",  "name": "Pacajá"},
      {"name_uf": "PA",  "name": "Palestina do PA"},
      {"name_uf": "PA",  "name": "Paragominas"},
      {"name_uf": "PA",  "name": "Parauapebas"},
      {"name_uf": "PA",  "name": "Pau D'Arco"},
      {"name_uf": "PA",  "name": "Peixe-Boi"},
      {"name_uf": "PA",  "name": "Piçarra"},
      {"name_uf": "PA",  "name": "Placas"},
      {"name_uf": "PA",  "name": "Ponta de Pedras"},
      {"name_uf": "PA",  "name": "Portel"},
      {"name_uf": "PA",  "name": "Porto de Moz"},
      {"name_uf": "PA",  "name": "Prainha"},
      {"name_uf": "PA",  "name": "Primavera"},
      {"name_uf": "PA",  "name": "Quatipuru"},
      {"name_uf": "PA",  "name": "Redenção"},
      {"name_uf": "PA",  "name": "Rio Maria"},
      {"name_uf": "PA",  "name": "Rondon do PA"},
      {"name_uf": "PA",  "name": "Rurópolis"},
      {"name_uf": "PA",  "name": "Salinópolis"},
      {"name_uf": "PA",  "name": "Salvaterra"},
      {"name_uf": "PA",  "name": "Santa Bárbara do PA"},
      {"name_uf": "PA",  "name": "Santa Cruz do Arari"},
      {"name_uf": "PA",  "name": "Santa Izabel do PA"},
      {"name_uf": "PA",  "name": "Santa Luzia do PA"},
      {"name_uf": "PA",  "name": "Santa Maria das Barreiras"},
      {"name_uf": "PA",  "name": "Santa Maria do PA"},
      {"name_uf": "PA",  "name": "Santana do Araguaia"},
      {"name_uf": "PA",  "name": "Santarém"},
      {"name_uf": "PA",  "name": "Santarém Novo"},
      {"name_uf": "PA",  "name": "Santo Antônio do Tauá"},
      {"name_uf": "PA",  "name": "São Caetano de Odivelas"},
      {"name_uf": "PA",  "name": "São Domingos do Araguaia"},
      {"name_uf": "PA",  "name": "São Domingos do Capim"},
      {"name_uf": "PA",  "name": "São Félix do Xingu"},
      {"name_uf": "PA",  "name": "São Francisco do PA"},
      {"name_uf": "PA",  "name": "São Geraldo do Araguaia"},
      {"name_uf": "PA",  "name": "São João da Ponta"},
      {"name_uf": "PA",  "name": "São João de Pirabas"},
      {"name_uf": "PA",  "name": "São João do Araguaia"},
      {"name_uf": "PA",  "name": "São Miguel do Guamá"},
      {"name_uf": "PA",  "name": "São Sebastião da Boa Vista"},
      {"name_uf": "PA",  "name": "Sapucaia"},
      {"name_uf": "PA",  "name": "Senador José Porfírio"},
      {"name_uf": "PA",  "name": "Soure"},
      {"name_uf": "PA",  "name": "Tailândia"},
      {"name_uf": "PA",  "name": "Terra Alta"},
      {"name_uf": "PA",  "name": "Terra Santa"},
      {"name_uf": "PA",  "name": "Tomé-Açu"},
      {"name_uf": "PA",  "name": "Tracuateua"},
      {"name_uf": "PA",  "name": "Trairão"},
      {"name_uf": "PA",  "name": "Tucumã"},
      {"name_uf": "PA",  "name": "Tucuruí"},
      {"name_uf": "PA",  "name": "Ulianópolis"},
      {"name_uf": "PA",  "name": "Uruará"},
      {"name_uf": "PA",  "name": "Vigia"},
      {"name_uf": "PA",  "name": "Viseu"},
      {"name_uf": "PA",  "name": "Vitória do Xingu"},
      {"name_uf": "PA",  "name": "Xinguara"},
      {"name_uf": "AP",  "name": "AP"},
      {"name_uf": "AP",  "name": "Calçoene"},
      {"name_uf": "AP",  "name": "Cutias"},
      {"name_uf": "AP",  "name": "Ferreira Gomes"},
      {"name_uf": "AP",  "name": "Itaubal"},
      {"name_uf": "AP",  "name": "Laranjal do Jari"},
      {"name_uf": "AP",  "name": "Macapá"},
      {"name_uf": "AP",  "name": "Mazagão"},
      {"name_uf": "AP",  "name": "Oiapoque"},
      {"name_uf": "AP",  "name": "Pedra Branca do Amapari"},
      {"name_uf": "AP",  "name": "Porto Grande"},
      {"name_uf": "AP",  "name": "Pracuúba"},
      {"name_uf": "AP",  "name": "Santana"},
      {"name_uf": "AP",  "name": "Serra do Navio"},
      {"name_uf": "AP",  "name": "Tartarugalzinho"},
      {"name_uf": "AP",  "name": "Vitória do Jari"},
      {"name_uf": "TO",  "name": "Abreulândia"},
      {"name_uf": "TO",  "name": "Aguiarnópolis"},
      {"name_uf": "TO",  "name": "Aliança do TO"},
      {"name_uf": "TO",  "name": "Almas"},
      {"name_uf": "TO",  "name": "Alvorada"},
      {"name_uf": "TO",  "name": "Ananás"},
      {"name_uf": "TO",  "name": "Angico"},
      {"name_uf": "TO",  "name": "Aparecida do Rio Negro"},
      {"name_uf": "TO",  "name": "Aragominas"},
      {"name_uf": "TO",  "name": "Araguacema"},
      {"name_uf": "TO",  "name": "Araguaçu"},
      {"name_uf": "TO",  "name": "Araguaína"},
      {"name_uf": "TO",  "name": "Araguanã"},
      {"name_uf": "TO",  "name": "Araguatins"},
      {"name_uf": "TO",  "name": "Arapoema"},
      {"name_uf": "TO",  "name": "Arraias"},
      {"name_uf": "TO",  "name": "Augustinópolis"},
      {"name_uf": "TO",  "name": "Aurora do TO"},
      {"name_uf": "TO",  "name": "Axixá do TO"},
      {"name_uf": "TO",  "name": "Babaçulândia"},
      {"name_uf": "TO",  "name": "Bandeirantes do TO"},
      {"name_uf": "TO",  "name": "Barra do Ouro"},
      {"name_uf": "TO",  "name": "Barrolândia"},
      {"name_uf": "TO",  "name": "Bernardo Sayão"},
      {"name_uf": "TO",  "name": "Bom Jesus do TO"},
      {"name_uf": "TO",  "name": "Brasilândia do TO"},
      {"name_uf": "TO",  "name": "Brejinho de Nazaré"},
      {"name_uf": "TO",  "name": "Buriti do TO"},
      {"name_uf": "TO",  "name": "Cachoeirinha"},
      {"name_uf": "TO",  "name": "Campos Lindos"},
      {"name_uf": "TO",  "name": "Cariri do TO"},
      {"name_uf": "TO",  "name": "Carmolândia"},
      {"name_uf": "TO",  "name": "Carrasco Bonito"},
      {"name_uf": "TO",  "name": "Caseara"},
      {"name_uf": "TO",  "name": "Centenário"},
      {"name_uf": "TO",  "name": "Chapada da Natividade"},
      {"name_uf": "TO",  "name": "Chapada de Areia"},
      {"name_uf": "TO",  "name": "Colinas do TO"},
      {"name_uf": "TO",  "name": "Colméia"},
      {"name_uf": "TO",  "name": "Combinado"},
      {"name_uf": "TO",  "name": "Conceição do TO"},
      {"name_uf": "TO",  "name": "Couto Magalhães"},
      {"name_uf": "TO",  "name": "Cristalândia"},
      {"name_uf": "TO",  "name": "Crixás do TO"},
      {"name_uf": "TO",  "name": "Darcinópolis"},
      {"name_uf": "TO",  "name": "Dianópolis"},
      {"name_uf": "TO",  "name": "Divinópolis do TO"},
      {"name_uf": "TO",  "name": "Dois Irmãos do TO"},
      {"name_uf": "TO",  "name": "Dueré"},
      {"name_uf": "TO",  "name": "Esperantina"},
      {"name_uf": "TO",  "name": "Fátima"},
      {"name_uf": "TO",  "name": "Figueirópolis"},
      {"name_uf": "TO",  "name": "Filadélfia"},
      {"name_uf": "TO",  "name": "Formoso do Araguaia"},
      {"name_uf": "TO",  "name": "Fortaleza do Tabocão"},
      {"name_uf": "TO",  "name": "Goianorte"},
      {"name_uf": "TO",  "name": "Goiatins"},
      {"name_uf": "TO",  "name": "Guaraí"},
      {"name_uf": "TO",  "name": "Gurupi"},
      {"name_uf": "TO",  "name": "Ipueiras"},
      {"name_uf": "TO",  "name": "Itacajá"},
      {"name_uf": "TO",  "name": "Itaguatins"},
      {"name_uf": "TO",  "name": "Itapiratins"},
      {"name_uf": "TO",  "name": "Itaporã do TO"},
      {"name_uf": "TO",  "name": "Jaú do TO"},
      {"name_uf": "TO",  "name": "Juarina"},
      {"name_uf": "TO",  "name": "Lagoa da Confusão"},
      {"name_uf": "TO",  "name": "Lagoa do TO"},
      {"name_uf": "TO",  "name": "Lajeado"},
      {"name_uf": "TO",  "name": "Lavandeira"},
      {"name_uf": "TO",  "name": "Lizarda"},
      {"name_uf": "TO",  "name": "Luzinópolis"},
      {"name_uf": "TO",  "name": "Marianópolis do TO"},
      {"name_uf": "TO",  "name": "Mateiros"},
      {"name_uf": "TO",  "name": "Maurilândia do TO"},
      {"name_uf": "TO",  "name": "Miracema do TO"},
      {"name_uf": "TO",  "name": "Miranorte"},
      {"name_uf": "TO",  "name": "Monte do Carmo"},
      {"name_uf": "TO",  "name": "Monte Santo do TO"},
      {"name_uf": "TO",  "name": "Muricilândia"},
      {"name_uf": "TO",  "name": "Natividade"},
      {"name_uf": "TO",  "name": "Nazaré"},
      {"name_uf": "TO",  "name": "Nova Olinda"},
      {"name_uf": "TO",  "name": "Nova Rosalândia"},
      {"name_uf": "TO",  "name": "Novo Acordo"},
      {"name_uf": "TO",  "name": "Novo Alegre"},
      {"name_uf": "TO",  "name": "Novo Jardim"},
      {"name_uf": "TO",  "name": "Oliveira de Fátima"},
      {"name_uf": "TO",  "name": "Palmas"},
      {"name_uf": "TO",  "name": "Palmeirante"},
      {"name_uf": "TO",  "name": "Palmeiras do TO"},
      {"name_uf": "TO",  "name": "Palmeirópolis"},
      {"name_uf": "TO",  "name": "Paraíso do TO"},
      {"name_uf": "TO",  "name": "Paranã"},
      {"name_uf": "TO",  "name": "Pau D'Arco"},
      {"name_uf": "TO",  "name": "Pedro Afonso"},
      {"name_uf": "TO",  "name": "Peixe"},
      {"name_uf": "TO",  "name": "Pequizeiro"},
      {"name_uf": "TO",  "name": "Pindorama do TO"},
      {"name_uf": "TO",  "name": "Piraquê"},
      {"name_uf": "TO",  "name": "Pium"},
      {"name_uf": "TO",  "name": "Ponte Alta do Bom Jesus"},
      {"name_uf": "TO",  "name": "Ponte Alta do TO"},
      {"name_uf": "TO",  "name": "Porto Alegre do TO"},
      {"name_uf": "TO",  "name": "Porto Nacional"},
      {"name_uf": "TO",  "name": "Praia Norte"},
      {"name_uf": "TO",  "name": "Presidente Kennedy"},
      {"name_uf": "TO",  "name": "Pugmil"},
      {"name_uf": "TO",  "name": "Recursolândia"},
      {"name_uf": "TO",  "name": "Riachinho"},
      {"name_uf": "TO",  "name": "Rio da Conceição"},
      {"name_uf": "TO",  "name": "Rio dos Bois"},
      {"name_uf": "TO",  "name": "Rio Sono"},
      {"name_uf": "TO",  "name": "Sampaio"},
      {"name_uf": "TO",  "name": "Sandolândia"},
      {"name_uf": "TO",  "name": "Santa Fé do Araguaia"},
      {"name_uf": "TO",  "name": "Santa Maria do TO"},
      {"name_uf": "TO",  "name": "Santa Rita do TO"},
      {"name_uf": "TO",  "name": "Santa Rosa do TO"},
      {"name_uf": "TO",  "name": "Santa Tereza do TO"},
      {"name_uf": "TO",  "name": "Santa Terezinha do TO"},
      {"name_uf": "TO",  "name": "São Bento do TO"},
      {"name_uf": "TO",  "name": "São Félix do TO"},
      {"name_uf": "TO",  "name": "São Miguel do TO"},
      {"name_uf": "TO",  "name": "São Salvador do TO"},
      {"name_uf": "TO",  "name": "São Sebastião do TO"},
      {"name_uf": "TO",  "name": "São Valério"},
      {"name_uf": "TO",  "name": "Silvanópolis"},
      {"name_uf": "TO",  "name": "Sítio Novo do TO"},
      {"name_uf": "TO",  "name": "Sucupira"},
      {"name_uf": "TO",  "name": "Taguatinga"},
      {"name_uf": "TO",  "name": "Taipas do TO"},
      {"name_uf": "TO",  "name": "Talismã"},
      {"name_uf": "TO",  "name": "Tocantínia"},
      {"name_uf": "TO",  "name": "Tocantinópolis"},
      {"name_uf": "TO",  "name": "Tupirama"},
      {"name_uf": "TO",  "name": "Tupiratins"},
      {"name_uf": "TO",  "name": "Wanderlândia"},
      {"name_uf": "TO",  "name": "Xambioá"},
      {"name_uf": "MA", "name": "Açailândia"},
      {"name_uf": "MA", "name": "Afonso Cunha"},
      {"name_uf": "MA", "name": "Água Doce do MA"},
      {"name_uf": "MA", "name": "Alcântara"},
      {"name_uf": "MA", "name": "Aldeias Altas"},
      {"name_uf": "MA", "name": "Altamira do MA"},
      {"name_uf": "MA", "name": "Alto Alegre do MA"},
      {"name_uf": "MA", "name": "Alto Alegre do Pindaré"},
      {"name_uf": "MA", "name": "Alto Parnaíba"},
      {"name_uf": "MA", "name": "AP do MA"},
      {"name_uf": "MA", "name": "Amarante do MA"},
      {"name_uf": "MA", "name": "Anajatuba"},
      {"name_uf": "MA", "name": "Anapurus"},
      {"name_uf": "MA", "name": "Apicum-Açu"},
      {"name_uf": "MA", "name": "Araguanã"},
      {"name_uf": "MA", "name": "Araioses"},
      {"name_uf": "MA", "name": "Arame"},
      {"name_uf": "MA", "name": "Arari"},
      {"name_uf": "MA", "name": "Axixá"},
      {"name_uf": "MA", "name": "Bacabal"},
      {"name_uf": "MA", "name": "Bacabeira"},
      {"name_uf": "MA", "name": "Bacuri"},
      {"name_uf": "MA", "name": "Bacurituba"},
      {"name_uf": "MA", "name": "Balsas"},
      {"name_uf": "MA", "name": "Barão de Grajaú"},
      {"name_uf": "MA", "name": "Barra do Corda"},
      {"name_uf": "MA", "name": "Barreirinhas"},
      {"name_uf": "MA", "name": "Bela Vista do MA"},
      {"name_uf": "MA", "name": "Belágua"},
      {"name_uf": "MA", "name": "Benedito Leite"},
      {"name_uf": "MA", "name": "Bequimão"},
      {"name_uf": "MA", "name": "Bernardo do Mearim"},
      {"name_uf": "MA", "name": "Boa Vista do Gurupi"},
      {"name_uf": "MA", "name": "Bom Jardim"},
      {"name_uf": "MA", "name": "Bom Jesus das Selvas"},
      {"name_uf": "MA", "name": "Bom Lugar"},
      {"name_uf": "MA", "name": "Brejo"},
      {"name_uf": "MA", "name": "Brejo de Areia"},
      {"name_uf": "MA", "name": "Buriti"},
      {"name_uf": "MA", "name": "Buriti Bravo"},
      {"name_uf": "MA", "name": "Buriticupu"},
      {"name_uf": "MA", "name": "Buritirana"},
      {"name_uf": "MA", "name": "Cachoeira Grande"},
      {"name_uf": "MA", "name": "Cajapió"},
      {"name_uf": "MA", "name": "Cajari"},
      {"name_uf": "MA", "name": "Campestre do MA"},
      {"name_uf": "MA", "name": "Cândido Mendes"},
      {"name_uf": "MA", "name": "Cantanhede"},
      {"name_uf": "MA", "name": "Capinzal do Norte"},
      {"name_uf": "MA", "name": "Carolina"},
      {"name_uf": "MA", "name": "Carutapera"},
      {"name_uf": "MA", "name": "Caxias"},
      {"name_uf": "MA", "name": "Cedral"},
      {"name_uf": "MA", "name": "Central do MA"},
      {"name_uf": "MA", "name": "Centro do Guilherme"},
      {"name_uf": "MA", "name": "Centro Novo do MA"},
      {"name_uf": "MA", "name": "Chapadinha"},
      {"name_uf": "MA", "name": "Cidelândia"},
      {"name_uf": "MA", "name": "Codó"},
      {"name_uf": "MA", "name": "Coelho Neto"},
      {"name_uf": "MA", "name": "Colinas"},
      {"name_uf": "MA", "name": "Conceição do Lago-Açu"},
      {"name_uf": "MA", "name": "Coroatá"},
      {"name_uf": "MA", "name": "Cururupu"},
      {"name_uf": "MA", "name": "Davinópolis"},
      {"name_uf": "MA", "name": "Dom Pedro"},
      {"name_uf": "MA", "name": "Duque Bacelar"},
      {"name_uf": "MA", "name": "Esperantinópolis"},
      {"name_uf": "MA", "name": "Estreito"},
      {"name_uf": "MA", "name": "Feira Nova do MA"},
      {"name_uf": "MA", "name": "Fernando Falcão"},
      {"name_uf": "MA", "name": "Formosa da Serra Negra"},
      {"name_uf": "MA", "name": "Fortaleza dos Nogueiras"},
      {"name_uf": "MA", "name": "Fortuna"},
      {"name_uf": "MA", "name": "Godofredo Viana"},
      {"name_uf": "MA", "name": "Gonçalves Dias"},
      {"name_uf": "MA", "name": "Governador Archer"},
      {"name_uf": "MA", "name": "Governador Edison Lobão"},
      {"name_uf": "MA", "name": "Governador Eugênio Barros"},
      {"name_uf": "MA", "name": "Governador Luiz Rocha"},
      {"name_uf": "MA", "name": "Governador Newton Bello"},
      {"name_uf": "MA", "name": "Governador Nunes Freire"},
      {"name_uf": "MA", "name": "Graça Aranha"},
      {"name_uf": "MA", "name": "Grajaú"},
      {"name_uf": "MA", "name": "Guimarães"},
      {"name_uf": "MA", "name": "Humberto de Campos"},
      {"name_uf": "MA", "name": "Icatu"},
      {"name_uf": "MA", "name": "Igarapé do Meio"},
      {"name_uf": "MA", "name": "Igarapé Grande"},
      {"name_uf": "MA", "name": "Imperatriz"},
      {"name_uf": "MA", "name": "Itaipava do Grajaú"},
      {"name_uf": "MA", "name": "Itapecuru Mirim"},
      {"name_uf": "MA", "name": "Itinga do MA"},
      {"name_uf": "MA", "name": "Jatobá"},
      {"name_uf": "MA", "name": "Jenipapo dos Vieiras"},
      {"name_uf": "MA", "name": "João Lisboa"},
      {"name_uf": "MA", "name": "Joselândia"},
      {"name_uf": "MA", "name": "Junco do MA"},
      {"name_uf": "MA", "name": "Lago da Pedra"},
      {"name_uf": "MA", "name": "Lago do Junco"},
      {"name_uf": "MA", "name": "Lago dos Rodrigues"},
      {"name_uf": "MA", "name": "Lago Verde"},
      {"name_uf": "MA", "name": "Lagoa do Mato"},
      {"name_uf": "MA", "name": "Lagoa Grande do MA"},
      {"name_uf": "MA", "name": "Lajeado Novo"},
      {"name_uf": "MA", "name": "Lima Campos"},
      {"name_uf": "MA", "name": "Loreto"},
      {"name_uf": "MA", "name": "Luís Domingues"},
      {"name_uf": "MA", "name": "Magalhães de Almeida"},
      {"name_uf": "MA", "name": "Maracaçumé"},
      {"name_uf": "MA", "name": "Marajá do Sena"},
      {"name_uf": "MA", "name": "MAzinho"},
      {"name_uf": "MA", "name": "Mata Roma"},
      {"name_uf": "MA", "name": "Matinha"},
      {"name_uf": "MA", "name": "Matões"},
      {"name_uf": "MA", "name": "Matões do Norte"},
      {"name_uf": "MA", "name": "Milagres do MA"},
      {"name_uf": "MA", "name": "Mirador"},
      {"name_uf": "MA", "name": "Miranda do Norte"},
      {"name_uf": "MA", "name": "Mirinzal"},
      {"name_uf": "MA", "name": "Monção"},
      {"name_uf": "MA", "name": "Montes Altos"},
      {"name_uf": "MA", "name": "Morros"},
      {"name_uf": "MA", "name": "Nina Rodrigues"},
      {"name_uf": "MA", "name": "Nova Colinas"},
      {"name_uf": "MA", "name": "Nova Iorque"},
      {"name_uf": "MA", "name": "Nova Olinda do MA"},
      {"name_uf": "MA", "name": "Olho d'Água das Cunhãs"},
      {"name_uf": "MA", "name": "Olinda Nova do MA"},
      {"name_uf": "MA", "name": "Paço do Lumiar"},
      {"name_uf": "MA", "name": "Palmeirândia"},
      {"name_uf": "MA", "name": "Paraibano"},
      {"name_uf": "MA", "name": "Parnarama"},
      {"name_uf": "MA", "name": "Passagem Franca"},
      {"name_uf": "MA", "name": "Pastos Bons"},
      {"name_uf": "MA", "name": "Paulino Neves"},
      {"name_uf": "MA", "name": "Paulo Ramos"},
      {"name_uf": "MA", "name": "Pedreiras"},
      {"name_uf": "MA", "name": "Pedro do Rosário"},
      {"name_uf": "MA", "name": "Penalva"},
      {"name_uf": "MA", "name": "Peri Mirim"},
      {"name_uf": "MA", "name": "Peritoró"},
      {"name_uf": "MA", "name": "Pindaré-Mirim"},
      {"name_uf": "MA", "name": "Pinheiro"},
      {"name_uf": "MA", "name": "Pio XII"},
      {"name_uf": "MA", "name": "Pirapemas"},
      {"name_uf": "MA", "name": "Poção de Pedras"},
      {"name_uf": "MA", "name": "Porto Franco"},
      {"name_uf": "MA", "name": "Porto Rico do MA"},
      {"name_uf": "MA", "name": "Presidente Dutra"},
      {"name_uf": "MA", "name": "Presidente Juscelino"},
      {"name_uf": "MA", "name": "Presidente Médici"},
      {"name_uf": "MA", "name": "Presidente Sarney"},
      {"name_uf": "MA", "name": "Presidente Vargas"},
      {"name_uf": "MA", "name": "Primeira Cruz"},
      {"name_uf": "MA", "name": "Raposa"},
      {"name_uf": "MA", "name": "Riachão"},
      {"name_uf": "MA", "name": "Ribamar Fiquene"},
      {"name_uf": "MA", "name": "Rosário"},
      {"name_uf": "MA", "name": "Sambaíba"},
      {"name_uf": "MA", "name": "Santa Filomena do MA"},
      {"name_uf": "MA", "name": "Santa Helena"},
      {"name_uf": "MA", "name": "Santa Inês"},
      {"name_uf": "MA", "name": "Santa Luzia"},
      {"name_uf": "MA", "name": "Santa Luzia do Paruá"},
      {"name_uf": "MA", "name": "Santa Quitéria do MA"},
      {"name_uf": "MA", "name": "Santa Rita"},
      {"name_uf": "MA", "name": "Santana do MA"},
      {"name_uf": "MA", "name": "Santo Amaro do MA"},
      {"name_uf": "MA", "name": "Santo Antônio dos Lopes"},
      {"name_uf": "MA", "name": "São Benedito do Rio Preto"},
      {"name_uf": "MA", "name": "São Bento"},
      {"name_uf": "MA", "name": "São Bernardo"},
      {"name_uf": "MA", "name": "São Domingos do Azeitão"},
      {"name_uf": "MA", "name": "São Domingos do MA"},
      {"name_uf": "MA", "name": "São Félix de Balsas"},
      {"name_uf": "MA", "name": "São Francisco do Brejão"},
      {"name_uf": "MA", "name": "São Francisco do MA"},
      {"name_uf": "MA", "name": "São João Batista"},
      {"name_uf": "MA", "name": "São João do Carú"},
      {"name_uf": "MA", "name": "São João do Paraíso"},
      {"name_uf": "MA", "name": "São João do Soter"},
      {"name_uf": "MA", "name": "São João dos Patos"},
      {"name_uf": "MA", "name": "São José de Ribamar"},
      {"name_uf": "MA", "name": "São José dos Basílios"},
      {"name_uf": "MA", "name": "São Luís"},
      {"name_uf": "MA", "name": "São Luís Gonzaga do MA"},
      {"name_uf": "MA", "name": "São Mateus do MA"},
      {"name_uf": "MA", "name": "São Pedro da Água Branca"},
      {"name_uf": "MA", "name": "São Pedro dos Crentes"},
      {"name_uf": "MA", "name": "São Raimundo das Mangabeiras"},
      {"name_uf": "MA", "name": "São Raimundo do Doca Bezerra"},
      {"name_uf": "MA", "name": "São Roberto"},
      {"name_uf": "MA", "name": "São Vicente Ferrer"},
      {"name_uf": "MA", "name": "Satubinha"},
      {"name_uf": "MA", "name": "Senador Alexandre Costa"},
      {"name_uf": "MA", "name": "Senador La Rocque"},
      {"name_uf": "MA", "name": "Serrano do MA"},
      {"name_uf": "MA", "name": "Sítio Novo"},
      {"name_uf": "MA", "name": "Sucupira do Norte"},
      {"name_uf": "MA", "name": "Sucupira do Riachão"},
      {"name_uf": "MA", "name": "Tasso Fragoso"},
      {"name_uf": "MA", "name": "Timbiras"},
      {"name_uf": "MA", "name": "Timon"},
      {"name_uf": "MA", "name": "Trizidela do Vale"},
      {"name_uf": "MA", "name": "Tufilândia"},
      {"name_uf": "MA", "name": "Tuntum"},
      {"name_uf": "MA", "name": "Turiaçu"},
      {"name_uf": "MA", "name": "Turilândia"},
      {"name_uf": "MA", "name": "Tutóia"},
      {"name_uf": "MA", "name": "Urbano Santos"},
      {"name_uf": "MA", "name": "Vargem Grande"},
      {"name_uf": "MA", "name": "Viana"},
      {"name_uf": "MA", "name": "Vila Nova dos Martírios"},
      {"name_uf": "MA", "name": "Vitória do Mearim"},
      {"name_uf": "MA", "name": "Vitorino Freire"},
      {"name_uf": "MA", "name": "Zé Doca"},
      {"name_uf": "PI", "name": "Acauã"},
      {"name_uf": "PI", "name": "Agricolândia"},
      {"name_uf": "PI", "name": "Água Branca"},
      {"name_uf": "PI", "name": "Alagoinha do PI"},
      {"name_uf": "PI", "name": "Alegrete do PI"},
      {"name_uf": "PI", "name": "Alto Longá"},
      {"name_uf": "PI", "name": "Altos"},
      {"name_uf": "PI", "name": "Alvorada do Gurguéia"},
      {"name_uf": "PI", "name": "Amarante"},
      {"name_uf": "PI", "name": "Angical do PI"},
      {"name_uf": "PI", "name": "Anísio de Abreu"},
      {"name_uf": "PI", "name": "Antônio Almeida"},
      {"name_uf": "PI", "name": "Aroazes"},
      {"name_uf": "PI", "name": "Aroeiras do Itaim"},
      {"name_uf": "PI", "name": "Arraial"},
      {"name_uf": "PI", "name": "Assunção do PI"},
      {"name_uf": "PI", "name": "Avelino Lopes"},
      {"name_uf": "PI", "name": "Baixa Grande do Ribeiro"},
      {"name_uf": "PI", "name": "Barra D'Alcântara"},
      {"name_uf": "PI", "name": "Barras"},
      {"name_uf": "PI", "name": "Barreiras do PI"},
      {"name_uf": "PI", "name": "Barro Duro"},
      {"name_uf": "PI", "name": "Batalha"},
      {"name_uf": "PI", "name": "Bela Vista do PI"},
      {"name_uf": "PI", "name": "Belém do PI"},
      {"name_uf": "PI", "name": "Beneditinos"},
      {"name_uf": "PI", "name": "Bertolínia"},
      {"name_uf": "PI", "name": "Betânia do PI"},
      {"name_uf": "PI", "name": "Boa Hora"},
      {"name_uf": "PI", "name": "Bocaina"},
      {"name_uf": "PI", "name": "Bom Jesus"},
      {"name_uf": "PI", "name": "Bom Princípio do PI"},
      {"name_uf": "PI", "name": "Bonfim do PI"},
      {"name_uf": "PI", "name": "Boqueirão do PI"},
      {"name_uf": "PI", "name": "Brasileira"},
      {"name_uf": "PI", "name": "Brejo do PI"},
      {"name_uf": "PI", "name": "Buriti dos Lopes"},
      {"name_uf": "PI", "name": "Buriti dos Montes"},
      {"name_uf": "PI", "name": "Cabeceiras do PI"},
      {"name_uf": "PI", "name": "Cajazeiras do PI"},
      {"name_uf": "PI", "name": "Cajueiro da Praia"},
      {"name_uf": "PI", "name": "Caldeirão Grande do PI"},
      {"name_uf": "PI", "name": "Campinas do PI"},
      {"name_uf": "PI", "name": "Campo Alegre do Fidalgo"},
      {"name_uf": "PI", "name": "Campo Grande do PI"},
      {"name_uf": "PI", "name": "Campo Largo do PI"},
      {"name_uf": "PI", "name": "Campo Maior"},
      {"name_uf": "PI", "name": "Canavieira"},
      {"name_uf": "PI", "name": "Canto do Buriti"},
      {"name_uf": "PI", "name": "Capitão de Campos"},
      {"name_uf": "PI", "name": "Capitão Gervásio Oliveira"},
      {"name_uf": "PI", "name": "Caracol"},
      {"name_uf": "PI", "name": "Caraúbas do PI"},
      {"name_uf": "PI", "name": "Caridade do PI"},
      {"name_uf": "PI", "name": "Castelo do PI"},
      {"name_uf": "PI", "name": "Caxingó"},
      {"name_uf": "PI", "name": "Cocal"},
      {"name_uf": "PI", "name": "Cocal de Telha"},
      {"name_uf": "PI", "name": "Cocal dos Alves"},
      {"name_uf": "PI", "name": "Coivaras"},
      {"name_uf": "PI", "name": "Colônia do Gurguéia"},
      {"name_uf": "PI", "name": "Colônia do PI"},
      {"name_uf": "PI", "name": "Conceição do Canindé"},
      {"name_uf": "PI", "name": "Coronel José Dias"},
      {"name_uf": "PI", "name": "Corrente"},
      {"name_uf": "PI", "name": "Cristalândia do PI"},
      {"name_uf": "PI", "name": "Cristino Castro"},
      {"name_uf": "PI", "name": "Curimatá"},
      {"name_uf": "PI", "name": "Currais"},
      {"name_uf": "PI", "name": "Curral Novo do PI"},
      {"name_uf": "PI", "name": "Curralinhos"},
      {"name_uf": "PI", "name": "Demerval Lobão"},
      {"name_uf": "PI", "name": "Dirceu Arcoverde"},
      {"name_uf": "PI", "name": "Dom Expedito Lopes"},
      {"name_uf": "PI", "name": "Dom Inocêncio"},
      {"name_uf": "PI", "name": "Domingos Mourão"},
      {"name_uf": "PI", "name": "Elesbão Veloso"},
      {"name_uf": "PI", "name": "Eliseu Martins"},
      {"name_uf": "PI", "name": "Esperantina"},
      {"name_uf": "PI", "name": "Fartura do PI"},
      {"name_uf": "PI", "name": "Flores do PI"},
      {"name_uf": "PI", "name": "Floresta do PI"},
      {"name_uf": "PI", "name": "Floriano"},
      {"name_uf": "PI", "name": "Francinópolis"},
      {"name_uf": "PI", "name": "Francisco Ayres"},
      {"name_uf": "PI", "name": "Francisco Macedo"},
      {"name_uf": "PI", "name": "Francisco Santos"},
      {"name_uf": "PI", "name": "Fronteiras"},
      {"name_uf": "PI", "name": "Geminiano"},
      {"name_uf": "PI", "name": "Gilbués"},
      {"name_uf": "PI", "name": "Guadalupe"},
      {"name_uf": "PI", "name": "Guaribas"},
      {"name_uf": "PI", "name": "Hugo Napoleão"},
      {"name_uf": "PI", "name": "Ilha Grande"},
      {"name_uf": "PI", "name": "Inhuma"},
      {"name_uf": "PI", "name": "Ipiranga do PI"},
      {"name_uf": "PI", "name": "Isaías Coelho"},
      {"name_uf": "PI", "name": "Itainópolis"},
      {"name_uf": "PI", "name": "Itaueira"},
      {"name_uf": "PI", "name": "Jacobina do PI"},
      {"name_uf": "PI", "name": "Jaicós"},
      {"name_uf": "PI", "name": "Jardim do Mulato"},
      {"name_uf": "PI", "name": "Jatobá do PI"},
      {"name_uf": "PI", "name": "Jerumenha"},
      {"name_uf": "PI", "name": "João Costa"},
      {"name_uf": "PI", "name": "Joaquim Pires"},
      {"name_uf": "PI", "name": "Joca Marques"},
      {"name_uf": "PI", "name": "José de Freitas"},
      {"name_uf": "PI", "name": "Juazeiro do PI"},
      {"name_uf": "PI", "name": "Júlio Borges"},
      {"name_uf": "PI", "name": "Jurema"},
      {"name_uf": "PI", "name": "Lagoa Alegre"},
      {"name_uf": "PI", "name": "Lagoa de São Francisco"},
      {"name_uf": "PI", "name": "Lagoa do Barro do PI"},
      {"name_uf": "PI", "name": "Lagoa do PI"},
      {"name_uf": "PI", "name": "Lagoa do Sítio"},
      {"name_uf": "PI", "name": "Lagoinha do PI"},
      {"name_uf": "PI", "name": "Landri Sales"},
      {"name_uf": "PI", "name": "Luís Correia"},
      {"name_uf": "PI", "name": "Luzilândia"},
      {"name_uf": "PI", "name": "Madeiro"},
      {"name_uf": "PI", "name": "Manoel Emídio"},
      {"name_uf": "PI", "name": "Marcolândia"},
      {"name_uf": "PI", "name": "Marcos Parente"},
      {"name_uf": "PI", "name": "Massapê do PI"},
      {"name_uf": "PI", "name": "Matias Olímpio"},
      {"name_uf": "PI", "name": "Miguel Alves"},
      {"name_uf": "PI", "name": "Miguel Leão"},
      {"name_uf": "PI", "name": "Milton Brandão"},
      {"name_uf": "PI", "name": "Monsenhor Gil"},
      {"name_uf": "PI", "name": "Monsenhor Hipólito"},
      {"name_uf": "PI", "name": "Monte Alegre do PI"},
      {"name_uf": "PI", "name": "Morro Cabeça no Tempo"},
      {"name_uf": "PI", "name": "Morro do Chapéu do PI"},
      {"name_uf": "PI", "name": "Murici dos Portelas"},
      {"name_uf": "PI", "name": "Nazaré do PI"},
      {"name_uf": "PI", "name": "Nazária"},
      {"name_uf": "PI", "name": "Nossa Senhora de Nazaré"},
      {"name_uf": "PI", "name": "Nossa Senhora dos Remédios"},
      {"name_uf": "PI", "name": "Nova Santa Rita"},
      {"name_uf": "PI", "name": "Novo Oriente do PI"},
      {"name_uf": "PI", "name": "Novo Santo Antônio"},
      {"name_uf": "PI", "name": "Oeiras"},
      {"name_uf": "PI", "name": "Olho D'Água do PI"},
      {"name_uf": "PI", "name": "Padre Marcos"},
      {"name_uf": "PI", "name": "Paes Landim"},
      {"name_uf": "PI", "name": "Pajeú do PI"},
      {"name_uf": "PI", "name": "Palmeira do PI"},
      {"name_uf": "PI", "name": "Palmeirais"},
      {"name_uf": "PI", "name": "Paquetá"},
      {"name_uf": "PI", "name": "Parnaguá"},
      {"name_uf": "PI", "name": "Parnaíba"},
      {"name_uf": "PI", "name": "Passagem Franca do PI"},
      {"name_uf": "PI", "name": "Patos do PI"},
      {"name_uf": "PI", "name": "Pau D'Arco do PI"},
      {"name_uf": "PI", "name": "Paulistana"},
      {"name_uf": "PI", "name": "Pavussu"},
      {"name_uf": "PI", "name": "Pedro II"},
      {"name_uf": "PI", "name": "Pedro Laurentino"},
      {"name_uf": "PI", "name": "Picos"},
      {"name_uf": "PI", "name": "Pimenteiras"},
      {"name_uf": "PI", "name": "Pio IX"},
      {"name_uf": "PI", "name": "Piracuruca"},
      {"name_uf": "PI", "name": "Piripiri"},
      {"name_uf": "PI", "name": "Porto"},
      {"name_uf": "PI", "name": "Porto Alegre do PI"},
      {"name_uf": "PI", "name": "Prata do PI"},
      {"name_uf": "PI", "name": "Queimada Nova"},
      {"name_uf": "PI", "name": "Redenção do Gurguéia"},
      {"name_uf": "PI", "name": "Regeneração"},
      {"name_uf": "PI", "name": "Riacho Frio"},
      {"name_uf": "PI", "name": "Ribeira do PI"},
      {"name_uf": "PI", "name": "Ribeiro Gonçalves"},
      {"name_uf": "PI", "name": "Rio Grande do PI"},
      {"name_uf": "PI", "name": "Santa Cruz do PI"},
      {"name_uf": "PI", "name": "Santa Cruz dos Milagres"},
      {"name_uf": "PI", "name": "Santa Filomena"},
      {"name_uf": "PI", "name": "Santa Luz"},
      {"name_uf": "PI", "name": "Santa Rosa do PI"},
      {"name_uf": "PI", "name": "Santana do PI"},
      {"name_uf": "PI", "name": "Santo Antônio de Lisboa"},
      {"name_uf": "PI", "name": "Santo Antônio dos Milagres"},
      {"name_uf": "PI", "name": "Santo Inácio do PI"},
      {"name_uf": "PI", "name": "São Braz do PI"},
      {"name_uf": "PI", "name": "São Félix do PI"},
      {"name_uf": "PI", "name": "São Francisco de Assis do PI"},
      {"name_uf": "PI", "name": "São Francisco do PI"},
      {"name_uf": "PI", "name": "São Gonçalo do Gurguéia"},
      {"name_uf": "PI", "name": "São Gonçalo do PI"},
      {"name_uf": "PI", "name": "São João da Canabrava"},
      {"name_uf": "PI", "name": "São João da Fronteira"},
      {"name_uf": "PI", "name": "São João da Serra"},
      {"name_uf": "PI", "name": "São João da Varjota"},
      {"name_uf": "PI", "name": "São João do Arraial"},
      {"name_uf": "PI", "name": "São João do PI"},
      {"name_uf": "PI", "name": "São José do Divino"},
      {"name_uf": "PI", "name": "São José do Peixe"},
      {"name_uf": "PI", "name": "São José do PI"},
      {"name_uf": "PI", "name": "São Julião"},
      {"name_uf": "PI", "name": "São Lourenço do PI"},
      {"name_uf": "PI", "name": "São Luis do PI"},
      {"name_uf": "PI", "name": "São Miguel da Baixa Grande"},
      {"name_uf": "PI", "name": "São Miguel do Fidalgo"},
      {"name_uf": "PI", "name": "São Miguel do Tapuio"},
      {"name_uf": "PI", "name": "São Pedro do PI"},
      {"name_uf": "PI", "name": "São Raimundo Nonato"},
      {"name_uf": "PI", "name": "Sebastião Barros"},
      {"name_uf": "PI", "name": "Sebastião Leal"},
      {"name_uf": "PI", "name": "Sigefredo Pacheco"},
      {"name_uf": "PI", "name": "Simões"},
      {"name_uf": "PI", "name": "Simplício Mendes"},
      {"name_uf": "PI", "name": "Socorro do PI"},
      {"name_uf": "PI", "name": "Sussuapara"},
      {"name_uf": "PI", "name": "Tamboril do PI"},
      {"name_uf": "PI", "name": "Tanque do PI"},
      {"name_uf": "PI", "name": "Teresina"},
      {"name_uf": "PI", "name": "União"},
      {"name_uf": "PI", "name": "Uruçuí"},
      {"name_uf": "PI", "name": "Valença do PI"},
      {"name_uf": "PI", "name": "Várzea Branca"},
      {"name_uf": "PI", "name": "Várzea Grande"},
      {"name_uf": "PI", "name": "Vera Mendes"},
      {"name_uf": "PI", "name": "Vila Nova do PI"},
      {"name_uf": "PI", "name": "Wall Ferraz"},
      {"name_uf": "CE", "name": "Abaiara"},
      {"name_uf": "CE", "name": "Acarape"},
      {"name_uf": "CE", "name": "Acaraú"},
      {"name_uf": "CE", "name": "Acopiara"},
      {"name_uf": "CE", "name": "Aiuaba"},
      {"name_uf": "CE", "name": "Alcântaras"},
      {"name_uf": "CE", "name": "Altaneira"},
      {"name_uf": "CE", "name": "Alto Santo"},
      {"name_uf": "CE", "name": "Amontada"},
      {"name_uf": "CE", "name": "Antonina do Norte"},
      {"name_uf": "CE", "name": "Apuiarés"},
      {"name_uf": "CE", "name": "Aquiraz"},
      {"name_uf": "CE", "name": "Aracati"},
      {"name_uf": "CE", "name": "Aracoiaba"},
      {"name_uf": "CE", "name": "Ararendá"},
      {"name_uf": "CE", "name": "Araripe"},
      {"name_uf": "CE", "name": "Aratuba"},
      {"name_uf": "CE", "name": "Arneiroz"},
      {"name_uf": "CE", "name": "Assaré"},
      {"name_uf": "CE", "name": "Aurora"},
      {"name_uf": "CE", "name": "Baixio"},
      {"name_uf": "CE", "name": "Banabuiú"},
      {"name_uf": "CE", "name": "Barbalha"},
      {"name_uf": "CE", "name": "Barreira"},
      {"name_uf": "CE", "name": "Barro"},
      {"name_uf": "CE", "name": "Barroquinha"},
      {"name_uf": "CE", "name": "Baturité"},
      {"name_uf": "CE", "name": "Beberibe"},
      {"name_uf": "CE", "name": "Bela Cruz"},
      {"name_uf": "CE", "name": "Boa Viagem"},
      {"name_uf": "CE", "name": "Brejo Santo"},
      {"name_uf": "CE", "name": "Camocim"},
      {"name_uf": "CE", "name": "Campos Sales"},
      {"name_uf": "CE", "name": "Canindé"},
      {"name_uf": "CE", "name": "Capistrano"},
      {"name_uf": "CE", "name": "Caridade"},
      {"name_uf": "CE", "name": "Cariré"},
      {"name_uf": "CE", "name": "Caririaçu"},
      {"name_uf": "CE", "name": "Cariús"},
      {"name_uf": "CE", "name": "Carnaubal"},
      {"name_uf": "CE", "name": "Cascavel"},
      {"name_uf": "CE", "name": "Catarina"},
      {"name_uf": "CE", "name": "Catunda"},
      {"name_uf": "CE", "name": "Caucaia"},
      {"name_uf": "CE", "name": "Cedro"},
      {"name_uf": "CE", "name": "Chaval"},
      {"name_uf": "CE", "name": "Choró"},
      {"name_uf": "CE", "name": "Chorozinho"},
      {"name_uf": "CE", "name": "Coreaú"},
      {"name_uf": "CE", "name": "Crateús"},
      {"name_uf": "CE", "name": "Crato"},
      {"name_uf": "CE", "name": "Croatá"},
      {"name_uf": "CE", "name": "Cruz"},
      {"name_uf": "CE", "name": "Deputado Irapuan Pinheiro"},
      {"name_uf": "CE", "name": "Ererê"},
      {"name_uf": "CE", "name": "Eusébio"},
      {"name_uf": "CE", "name": "Farias Brito"},
      {"name_uf": "CE", "name": "Forquilha"},
      {"name_uf": "CE", "name": "Fortaleza"},
      {"name_uf": "CE", "name": "Fortim"},
      {"name_uf": "CE", "name": "Frecheirinha"},
      {"name_uf": "CE", "name": "General Sampaio"},
      {"name_uf": "CE", "name": "Graça"},
      {"name_uf": "CE", "name": "Granja"},
      {"name_uf": "CE", "name": "Granjeiro"},
      {"name_uf": "CE", "name": "Groaíras"},
      {"name_uf": "CE", "name": "Guaiúba"},
      {"name_uf": "CE", "name": "Guaraciaba do Norte"},
      {"name_uf": "CE", "name": "Guaramiranga"},
      {"name_uf": "CE", "name": "Hidrolândia"},
      {"name_uf": "CE", "name": "Horizonte"},
      {"name_uf": "CE", "name": "Ibaretama"},
      {"name_uf": "CE", "name": "Ibiapina"},
      {"name_uf": "CE", "name": "Ibicuitinga"},
      {"name_uf": "CE", "name": "Icapuí"},
      {"name_uf": "CE", "name": "Icó"},
      {"name_uf": "CE", "name": "Iguatu"},
      {"name_uf": "CE", "name": "Independência"},
      {"name_uf": "CE", "name": "Ipaporanga"},
      {"name_uf": "CE", "name": "Ipaumirim"},
      {"name_uf": "CE", "name": "Ipu"},
      {"name_uf": "CE", "name": "Ipueiras"},
      {"name_uf": "CE", "name": "Iracema"},
      {"name_uf": "CE", "name": "Irauçuba"},
      {"name_uf": "CE", "name": "Itaiçaba"},
      {"name_uf": "CE", "name": "Itaitinga"},
      {"name_uf": "CE", "name": "Itapajé"},
      {"name_uf": "CE", "name": "Itapipoca"},
      {"name_uf": "CE", "name": "Itapiúna"},
      {"name_uf": "CE", "name": "Itarema"},
      {"name_uf": "CE", "name": "Itatira"},
      {"name_uf": "CE", "name": "Jaguaretama"},
      {"name_uf": "CE", "name": "Jaguaribara"},
      {"name_uf": "CE", "name": "Jaguaribe"},
      {"name_uf": "CE", "name": "Jaguaruana"},
      {"name_uf": "CE", "name": "Jardim"},
      {"name_uf": "CE", "name": "Jati"},
      {"name_uf": "CE", "name": "Jijoca de Jericoacoara"},
      {"name_uf": "CE", "name": "Juazeiro do Norte"},
      {"name_uf": "CE", "name": "Jucás"},
      {"name_uf": "CE", "name": "Lavras da Mangabeira"},
      {"name_uf": "CE", "name": "Limoeiro do Norte"},
      {"name_uf": "CE", "name": "Madalena"},
      {"name_uf": "CE", "name": "Maracanaú"},
      {"name_uf": "CE", "name": "Maranguape"},
      {"name_uf": "CE", "name": "Marco"},
      {"name_uf": "CE", "name": "Martinópole"},
      {"name_uf": "CE", "name": "Massapê"},
      {"name_uf": "CE", "name": "Mauriti"},
      {"name_uf": "CE", "name": "Meruoca"},
      {"name_uf": "CE", "name": "Milagres"},
      {"name_uf": "CE", "name": "Milhã"},
      {"name_uf": "CE", "name": "Miraíma"},
      {"name_uf": "CE", "name": "Missão Velha"},
      {"name_uf": "CE", "name": "Mombaça"},
      {"name_uf": "CE", "name": "Monsenhor Tabosa"},
      {"name_uf": "CE", "name": "Morada Nova"},
      {"name_uf": "CE", "name": "Moraújo"},
      {"name_uf": "CE", "name": "Morrinhos"},
      {"name_uf": "CE", "name": "Mucambo"},
      {"name_uf": "CE", "name": "Mulungu"},
      {"name_uf": "CE", "name": "Nova Olinda"},
      {"name_uf": "CE", "name": "Nova Russas"},
      {"name_uf": "CE", "name": "Novo Oriente"},
      {"name_uf": "CE", "name": "Ocara"},
      {"name_uf": "CE", "name": "Orós"},
      {"name_uf": "CE", "name": "Pacajus"},
      {"name_uf": "CE", "name": "Pacatuba"},
      {"name_uf": "CE", "name": "Pacoti"},
      {"name_uf": "CE", "name": "Pacujá"},
      {"name_uf": "CE", "name": "Palhano"},
      {"name_uf": "CE", "name": "Palmácia"},
      {"name_uf": "CE", "name": "Paracuru"},
      {"name_uf": "CE", "name": "Paraipaba"},
      {"name_uf": "CE", "name": "Parambu"},
      {"name_uf": "CE", "name": "Paramoti"},
      {"name_uf": "CE", "name": "Pedra Branca"},
      {"name_uf": "CE", "name": "Penaforte"},
      {"name_uf": "CE", "name": "Pentecoste"},
      {"name_uf": "CE", "name": "Pereiro"},
      {"name_uf": "CE", "name": "Pindoretama"},
      {"name_uf": "CE", "name": "Piquet Carneiro"},
      {"name_uf": "CE", "name": "Pires Ferreira"},
      {"name_uf": "CE", "name": "Poranga"},
      {"name_uf": "CE", "name": "Porteiras"},
      {"name_uf": "CE", "name": "Potengi"},
      {"name_uf": "CE", "name": "Potiretama"},
      {"name_uf": "CE", "name": "Quiterianópolis"},
      {"name_uf": "CE", "name": "Quixadá"},
      {"name_uf": "CE", "name": "Quixelô"},
      {"name_uf": "CE", "name": "Quixeramobim"},
      {"name_uf": "CE", "name": "Quixeré"},
      {"name_uf": "CE", "name": "Redenção"},
      {"name_uf": "CE", "name": "Reriutaba"},
      {"name_uf": "CE", "name": "Russas"},
      {"name_uf": "CE", "name": "Saboeiro"},
      {"name_uf": "CE", "name": "Salitre"},
      {"name_uf": "CE", "name": "Santa Quitéria"},
      {"name_uf": "CE", "name": "Santana do Acaraú"},
      {"name_uf": "CE", "name": "Santana do Cariri"},
      {"name_uf": "CE", "name": "São Benedito"},
      {"name_uf": "CE", "name": "São Gonçalo do Amarante"},
      {"name_uf": "CE", "name": "São João do Jaguaribe"},
      {"name_uf": "CE", "name": "São Luís do Curu"},
      {"name_uf": "CE", "name": "Senador Pompeu"},
      {"name_uf": "CE", "name": "Senador Sá"},
      {"name_uf": "CE", "name": "Sobral"},
      {"name_uf": "CE", "name": "Solonópole"},
      {"name_uf": "CE", "name": "Tabuleiro do Norte"},
      {"name_uf": "CE", "name": "Tamboril"},
      {"name_uf": "CE", "name": "Tarrafas"},
      {"name_uf": "CE", "name": "Tauá"},
      {"name_uf": "CE", "name": "Tejuçuoca"},
      {"name_uf": "CE", "name": "Tianguá"},
      {"name_uf": "CE", "name": "Trairi"},
      {"name_uf": "CE", "name": "Tururu"},
      {"name_uf": "CE", "name": "Ubajara"},
      {"name_uf": "CE", "name": "Umari"},
      {"name_uf": "CE", "name": "Umirim"},
      {"name_uf": "CE", "name": "Uruburetama"},
      {"name_uf": "CE", "name": "Uruoca"},
      {"name_uf": "CE", "name": "Varjota"},
      {"name_uf": "CE", "name": "Várzea Alegre"},
      {"name_uf": "CE", "name": "Viçosa do CE"},
      {"name_uf": "RN", "name": "Acari"},
      {"name_uf": "RN", "name": "Açu"},
      {"name_uf": "RN", "name": "Afonso Bezerra"},
      {"name_uf": "RN", "name": "Água Nova"},
      {"name_uf": "RN", "name": "Alexandria"},
      {"name_uf": "RN", "name": "Almino Afonso"},
      {"name_uf": "RN", "name": "Alto do Rodrigues"},
      {"name_uf": "RN", "name": "Angicos"},
      {"name_uf": "RN", "name": "Antônio Martins"},
      {"name_uf": "RN", "name": "Apodi"},
      {"name_uf": "RN", "name": "Areia Branca"},
      {"name_uf": "RN", "name": "Arês"},
      {"name_uf": "RN", "name": "Augusto Severo"},
      {"name_uf": "RN", "name": "Baía Formosa"},
      {"name_uf": "RN", "name": "Baraúna"},
      {"name_uf": "RN", "name": "Barcelona"},
      {"name_uf": "RN", "name": "Bento Fernandes"},
      {"name_uf": "RN", "name": "Bodó"},
      {"name_uf": "RN", "name": "Bom Jesus"},
      {"name_uf": "RN", "name": "Brejinho"},
      {"name_uf": "RN", "name": "Caiçara do Norte"},
      {"name_uf": "RN", "name": "Caiçara do Rio do Vento"},
      {"name_uf": "RN", "name": "Caicó"},
      {"name_uf": "RN", "name": "Campo Redondo"},
      {"name_uf": "RN", "name": "Canguaretama"},
      {"name_uf": "RN", "name": "Caraúbas"},
      {"name_uf": "RN", "name": "Carnaúba dos Dantas"},
      {"name_uf": "RN", "name": "Carnaubais"},
      {"name_uf": "RN", "name": "CE-Mirim"},
      {"name_uf": "RN", "name": "Cerro Corá"},
      {"name_uf": "RN", "name": "Coronel Ezequiel"},
      {"name_uf": "RN", "name": "Coronel João Pessoa"},
      {"name_uf": "RN", "name": "Cruzeta"},
      {"name_uf": "RN", "name": "Currais Novos"},
      {"name_uf": "RN", "name": "Doutor Severiano"},
      {"name_uf": "RN", "name": "Encanto"},
      {"name_uf": "RN", "name": "Equador"},
      {"name_uf": "RN", "name": "ES"},
      {"name_uf": "RN", "name": "Extremoz"},
      {"name_uf": "RN", "name": "Felipe Guerra"},
      {"name_uf": "RN", "name": "Fernando Pedroza"},
      {"name_uf": "RN", "name": "Florânia"},
      {"name_uf": "RN", "name": "Francisco Dantas"},
      {"name_uf": "RN", "name": "Frutuoso Gomes"},
      {"name_uf": "RN", "name": "Galinhos"},
      {"name_uf": "RN", "name": "Goianinha"},
      {"name_uf": "RN", "name": "Governador Dix-Sept Rosado"},
      {"name_uf": "RN", "name": "Grossos"},
      {"name_uf": "RN", "name": "Guamaré"},
      {"name_uf": "RN", "name": "Ielmo Marinho"},
      {"name_uf": "RN", "name": "Ipanguaçu"},
      {"name_uf": "RN", "name": "Ipueira"},
      {"name_uf": "RN", "name": "Itajá"},
      {"name_uf": "RN", "name": "Itaú"},
      {"name_uf": "RN", "name": "Jaçanã"},
      {"name_uf": "RN", "name": "Jandaíra"},
      {"name_uf": "RN", "name": "Janduís"},
      {"name_uf": "RN", "name": "Januário Cicco"},
      {"name_uf": "RN", "name": "Japi"},
      {"name_uf": "RN", "name": "Jardim de Angicos"},
      {"name_uf": "RN", "name": "Jardim de Piranhas"},
      {"name_uf": "RN", "name": "Jardim do Seridó"},
      {"name_uf": "RN", "name": "João Câmara"},
      {"name_uf": "RN", "name": "João Dias"},
      {"name_uf": "RN", "name": "José da Penha"},
      {"name_uf": "RN", "name": "Jucurutu"},
      {"name_uf": "RN", "name": "Jundiá"},
      {"name_uf": "RN", "name": "Lagoa d'Anta"},
      {"name_uf": "RN", "name": "Lagoa de Pedras"},
      {"name_uf": "RN", "name": "Lagoa de Velhos"},
      {"name_uf": "RN", "name": "Lagoa Nova"},
      {"name_uf": "RN", "name": "Lagoa Salgada"},
      {"name_uf": "RN", "name": "Lajes"},
      {"name_uf": "RN", "name": "Lajes Pintadas"},
      {"name_uf": "RN", "name": "Lucrécia"},
      {"name_uf": "RN", "name": "Luís Gomes"},
      {"name_uf": "RN", "name": "Macaíba"},
      {"name_uf": "RN", "name": "Macau"},
      {"name_uf": "RN", "name": "Major Sales"},
      {"name_uf": "RN", "name": "Marcelino Vieira"},
      {"name_uf": "RN", "name": "Martins"},
      {"name_uf": "RN", "name": "Maxaranguape"},
      {"name_uf": "RN", "name": "Messias Targino"},
      {"name_uf": "RN", "name": "Montanhas"},
      {"name_uf": "RN", "name": "Monte Alegre"},
      {"name_uf": "RN", "name": "Monte das Gameleiras"},
      {"name_uf": "RN", "name": "Mossoró"},
      {"name_uf": "RN", "name": "Natal"},
      {"name_uf": "RN", "name": "Nísia Floresta"},
      {"name_uf": "RN", "name": "Nova Cruz"},
      {"name_uf": "RN", "name": "Olho d'Água do Borges"},
      {"name_uf": "RN", "name": "Ouro Branco"},
      {"name_uf": "RN", "name": "PR"},
      {"name_uf": "RN", "name": "Paraú"},
      {"name_uf": "RN", "name": "Parazinho"},
      {"name_uf": "RN", "name": "Parelhas"},
      {"name_uf": "RN", "name": "Parnamirim"},
      {"name_uf": "RN", "name": "Passa e Fica"},
      {"name_uf": "RN", "name": "Passagem"},
      {"name_uf": "RN", "name": "Patu"},
      {"name_uf": "RN", "name": "Pau dos Ferros"},
      {"name_uf": "RN", "name": "Pedra Grande"},
      {"name_uf": "RN", "name": "Pedra Preta"},
      {"name_uf": "RN", "name": "Pedro Avelino"},
      {"name_uf": "RN", "name": "Pedro Velho"},
      {"name_uf": "RN", "name": "Pendências"},
      {"name_uf": "RN", "name": "Pilões"},
      {"name_uf": "RN", "name": "Poço Branco"},
      {"name_uf": "RN", "name": "Portalegre"},
      {"name_uf": "RN", "name": "Porto do Mangue"},
      {"name_uf": "RN", "name": "Pureza"},
      {"name_uf": "RN", "name": "Rafael Fernandes"},
      {"name_uf": "RN", "name": "Rafael Godeiro"},
      {"name_uf": "RN", "name": "Riacho da Cruz"},
      {"name_uf": "RN", "name": "Riacho de Santana"},
      {"name_uf": "RN", "name": "Riachuelo"},
      {"name_uf": "RN", "name": "Rio do Fogo"},
      {"name_uf": "RN", "name": "Rodolfo Fernandes"},
      {"name_uf": "RN", "name": "Ruy Barbosa"},
      {"name_uf": "RN", "name": "Santa Cruz"},
      {"name_uf": "RN", "name": "Santa Maria"},
      {"name_uf": "RN", "name": "Santana do Matos"},
      {"name_uf": "RN", "name": "Santana do Seridó"},
      {"name_uf": "RN", "name": "Santo Antônio"},
      {"name_uf": "RN", "name": "São Bento do Norte"},
      {"name_uf": "RN", "name": "São Bento do Trairí"},
      {"name_uf": "RN", "name": "São Fernando"},
      {"name_uf": "RN", "name": "São Francisco do Oeste"},
      {"name_uf": "RN", "name": "São Gonçalo do Amarante"},
      {"name_uf": "RN", "name": "São João do Sabugi"},
      {"name_uf": "RN", "name": "São José de Mipibu"},
      {"name_uf": "RN", "name": "São José do Campestre"},
      {"name_uf": "RN", "name": "São José do Seridó"},
      {"name_uf": "RN", "name": "São Miguel"},
      {"name_uf": "RN", "name": "São Miguel do Gostoso"},
      {"name_uf": "RN", "name": "SP do Potengi"},
      {"name_uf": "RN", "name": "São Pedro"},
      {"name_uf": "RN", "name": "São Rafael"},
      {"name_uf": "RN", "name": "São Tomé"},
      {"name_uf": "RN", "name": "São Vicente"},
      {"name_uf": "RN", "name": "Senador Elói de Souza"},
      {"name_uf": "RN", "name": "Senador Georgino Avelino"},
      {"name_uf": "RN", "name": "Serra Caiada"},
      {"name_uf": "RN", "name": "Serra de São Bento"},
      {"name_uf": "RN", "name": "Serra do Mel"},
      {"name_uf": "RN", "name": "Serra Negra do Norte"},
      {"name_uf": "RN", "name": "Serrinha"},
      {"name_uf": "RN", "name": "Serrinha dos Pintos"},
      {"name_uf": "RN", "name": "Severiano Melo"},
      {"name_uf": "RN", "name": "Sítio Novo"},
      {"name_uf": "RN", "name": "Taboleiro Grande"},
      {"name_uf": "RN", "name": "Taipu"},
      {"name_uf": "RN", "name": "Tangará"},
      {"name_uf": "RN", "name": "Tenente Ananias"},
      {"name_uf": "RN", "name": "Tenente Laurentino Cruz"},
      {"name_uf": "RN", "name": "Tibau"},
      {"name_uf": "RN", "name": "Tibau do Sul"},
      {"name_uf": "RN", "name": "Timbaúba dos Batistas"},
      {"name_uf": "RN", "name": "Touros"},
      {"name_uf": "RN", "name": "Triunfo Potiguar"},
      {"name_uf": "RN", "name": "Umarizal"},
      {"name_uf": "RN", "name": "Upanema"},
      {"name_uf": "RN", "name": "Várzea"},
      {"name_uf": "RN", "name": "Venha-Ver"},
      {"name_uf": "RN", "name": "Vera Cruz"},
      {"name_uf": "RN", "name": "Viçosa"},
      {"name_uf": "RN", "name": "Vila Flor"},
      {"name_uf": "PB", "name": "Água Branca"},
      {"name_uf": "PB", "name": "Aguiar"},
      {"name_uf": "PB", "name": "Alagoa Grande"},
      {"name_uf": "PB", "name": "Alagoa Nova"},
      {"name_uf": "PB", "name": "Alagoinha"},
      {"name_uf": "PB", "name": "Alcantil"},
      {"name_uf": "PB", "name": "Algodão de Jandaíra"},
      {"name_uf": "PB", "name": "Alhandra"},
      {"name_uf": "PB", "name": "Amparo"},
      {"name_uf": "PB", "name": "Aparecida"},
      {"name_uf": "PB", "name": "Araçagi"},
      {"name_uf": "PB", "name": "Arara"},
      {"name_uf": "PB", "name": "Araruna"},
      {"name_uf": "PB", "name": "Areia"},
      {"name_uf": "PB", "name": "Areia de Baraúnas"},
      {"name_uf": "PB", "name": "Areial"},
      {"name_uf": "PB", "name": "Aroeiras"},
      {"name_uf": "PB", "name": "Assunção"},
      {"name_uf": "PB", "name": "Baía da Traição"},
      {"name_uf": "PB", "name": "Bananeiras"},
      {"name_uf": "PB", "name": "Baraúna"},
      {"name_uf": "PB", "name": "Barra de Santa Rosa"},
      {"name_uf": "PB", "name": "Barra de Santana"},
      {"name_uf": "PB", "name": "Barra de São Miguel"},
      {"name_uf": "PB", "name": "Bayeux"},
      {"name_uf": "PB", "name": "Belém"},
      {"name_uf": "PB", "name": "Belém do Brejo do Cruz"},
      {"name_uf": "PB", "name": "Bernardino Batista"},
      {"name_uf": "PB", "name": "Boa Ventura"},
      {"name_uf": "PB", "name": "Boa Vista"},
      {"name_uf": "PB", "name": "Bom Jesus"},
      {"name_uf": "PB", "name": "Bom Sucesso"},
      {"name_uf": "PB", "name": "Bonito de Santa Fé"},
      {"name_uf": "PB", "name": "Boqueirão"},
      {"name_uf": "PB", "name": "Borborema"},
      {"name_uf": "PB", "name": "Brejo do Cruz"},
      {"name_uf": "PB", "name": "Brejo dos Santos"},
      {"name_uf": "PB", "name": "Caaporã"},
      {"name_uf": "PB", "name": "Cabaceiras"},
      {"name_uf": "PB", "name": "Cabedelo"},
      {"name_uf": "PB", "name": "Cachoeira dos Índios"},
      {"name_uf": "PB", "name": "Cacimba de Areia"},
      {"name_uf": "PB", "name": "Cacimba de Dentro"},
      {"name_uf": "PB", "name": "Cacimbas"},
      {"name_uf": "PB", "name": "Caiçara"},
      {"name_uf": "PB", "name": "Cajazeiras"},
      {"name_uf": "PB", "name": "Cajazeirinhas"},
      {"name_uf": "PB", "name": "Caldas Brandão"},
      {"name_uf": "PB", "name": "Camalaú"},
      {"name_uf": "PB", "name": "Campina Grande"},
      {"name_uf": "PB", "name": "Capim"},
      {"name_uf": "PB", "name": "Caraúbas"},
      {"name_uf": "PB", "name": "Carrapateira"},
      {"name_uf": "PB", "name": "Casserengue"},
      {"name_uf": "PB", "name": "Catingueira"},
      {"name_uf": "PB", "name": "Catolé do Rocha"},
      {"name_uf": "PB", "name": "Caturité"},
      {"name_uf": "PB", "name": "Conceição"},
      {"name_uf": "PB", "name": "Condado"},
      {"name_uf": "PB", "name": "Conde"},
      {"name_uf": "PB", "name": "Congo"},
      {"name_uf": "PB", "name": "Coremas"},
      {"name_uf": "PB", "name": "Coxixola"},
      {"name_uf": "PB", "name": "Cruz do ES"},
      {"name_uf": "PB", "name": "Cubati"},
      {"name_uf": "PB", "name": "Cuité"},
      {"name_uf": "PB", "name": "Cuité de Mamanguape"},
      {"name_uf": "PB", "name": "Cuitegi"},
      {"name_uf": "PB", "name": "Curral de Cima"},
      {"name_uf": "PB", "name": "Curral Velho"},
      {"name_uf": "PB", "name": "Damião"},
      {"name_uf": "PB", "name": "Desterro"},
      {"name_uf": "PB", "name": "Diamante"},
      {"name_uf": "PB", "name": "Dona Inês"},
      {"name_uf": "PB", "name": "Duas Estradas"},
      {"name_uf": "PB", "name": "Emas"},
      {"name_uf": "PB", "name": "Esperança"},
      {"name_uf": "PB", "name": "Fagundes"},
      {"name_uf": "PB", "name": "Frei Martinho"},
      {"name_uf": "PB", "name": "Gado Bravo"},
      {"name_uf": "PB", "name": "Guarabira"},
      {"name_uf": "PB", "name": "Gurinhém"},
      {"name_uf": "PB", "name": "Gurjão"},
      {"name_uf": "PB", "name": "Ibiara"},
      {"name_uf": "PB", "name": "Igaracy"},
      {"name_uf": "PB", "name": "Imaculada"},
      {"name_uf": "PB", "name": "Ingá"},
      {"name_uf": "PB", "name": "Itabaiana"},
      {"name_uf": "PB", "name": "Itaporanga"},
      {"name_uf": "PB", "name": "Itapororoca"},
      {"name_uf": "PB", "name": "Itatuba"},
      {"name_uf": "PB", "name": "Jacaraú"},
      {"name_uf": "PB", "name": "Jericó"},
      {"name_uf": "PB", "name": "João Pessoa"},
      {"name_uf": "PB", "name": "Joca Claudino"},
      {"name_uf": "PB", "name": "Juarez Távora"},
      {"name_uf": "PB", "name": "Juazeirinho"},
      {"name_uf": "PB", "name": "Junco do Seridó"},
      {"name_uf": "PB", "name": "Juripiranga"},
      {"name_uf": "PB", "name": "Juru"},
      {"name_uf": "PB", "name": "Lagoa"},
      {"name_uf": "PB", "name": "Lagoa de Dentro"},
      {"name_uf": "PB", "name": "Lagoa Seca"},
      {"name_uf": "PB", "name": "Lastro"},
      {"name_uf": "PB", "name": "Livramento"},
      {"name_uf": "PB", "name": "Logradouro"},
      {"name_uf": "PB", "name": "Lucena"},
      {"name_uf": "PB", "name": "Mãe d'Água"},
      {"name_uf": "PB", "name": "Malta"},
      {"name_uf": "PB", "name": "Mamanguape"},
      {"name_uf": "PB", "name": "Manaíra"},
      {"name_uf": "PB", "name": "Marcação"},
      {"name_uf": "PB", "name": "Mari"},
      {"name_uf": "PB", "name": "Marizópolis"},
      {"name_uf": "PB", "name": "Massaranduba"},
      {"name_uf": "PB", "name": "Mataraca"},
      {"name_uf": "PB", "name": "Matinhas"},
      {"name_uf": "PB", "name": "MT"},
      {"name_uf": "PB", "name": "Maturéia"},
      {"name_uf": "PB", "name": "Mogeiro"},
      {"name_uf": "PB", "name": "Montadas"},
      {"name_uf": "PB", "name": "Monte Horebe"},
      {"name_uf": "PB", "name": "Monteiro"},
      {"name_uf": "PB", "name": "Mulungu"},
      {"name_uf": "PB", "name": "Natuba"},
      {"name_uf": "PB", "name": "Nazarezinho"},
      {"name_uf": "PB", "name": "Nova Floresta"},
      {"name_uf": "PB", "name": "Nova Olinda"},
      {"name_uf": "PB", "name": "Nova Palmeira"},
      {"name_uf": "PB", "name": "Olho d'Água"},
      {"name_uf": "PB", "name": "Olivedos"},
      {"name_uf": "PB", "name": "Ouro Velho"},
      {"name_uf": "PB", "name": "Parari"},
      {"name_uf": "PB", "name": "Passagem"},
      {"name_uf": "PB", "name": "Patos"},
      {"name_uf": "PB", "name": "Paulista"},
      {"name_uf": "PB", "name": "Pedra Branca"},
      {"name_uf": "PB", "name": "Pedra Lavrada"},
      {"name_uf": "PB", "name": "Pedras de Fogo"},
      {"name_uf": "PB", "name": "Pedro Régis"},
      {"name_uf": "PB", "name": "Piancó"},
      {"name_uf": "PB", "name": "Picuí"},
      {"name_uf": "PB", "name": "Pilar"},
      {"name_uf": "PB", "name": "Pilões"},
      {"name_uf": "PB", "name": "Pilõezinhos"},
      {"name_uf": "PB", "name": "Pirpirituba"},
      {"name_uf": "PB", "name": "Pitimbu"},
      {"name_uf": "PB", "name": "Pocinhos"},
      {"name_uf": "PB", "name": "Poço Dantas"},
      {"name_uf": "PB", "name": "Poço de José de Moura"},
      {"name_uf": "PB", "name": "Pombal"},
      {"name_uf": "PB", "name": "Prata"},
      {"name_uf": "PB", "name": "Princesa Isabel"},
      {"name_uf": "PB", "name": "Puxinanã"},
      {"name_uf": "PB", "name": "Queimadas"},
      {"name_uf": "PB", "name": "Quixaba"},
      {"name_uf": "PB", "name": "Remígio"},
      {"name_uf": "PB", "name": "Riachão"},
      {"name_uf": "PB", "name": "Riachão do Bacamarte"},
      {"name_uf": "PB", "name": "Riachão do Poço"},
      {"name_uf": "PB", "name": "Riacho de Santo Antônio"},
      {"name_uf": "PB", "name": "Riacho dos Cavalos"},
      {"name_uf": "PB", "name": "Rio Tinto"},
      {"name_uf": "PB", "name": "Salgadinho"},
      {"name_uf": "PB", "name": "Salgado de São Félix"},
      {"name_uf": "PB", "name": "Santa Cecília"},
      {"name_uf": "PB", "name": "Santa Cruz"},
      {"name_uf": "PB", "name": "Santa Helena"},
      {"name_uf": "PB", "name": "Santa Inês"},
      {"name_uf": "PB", "name": "Santa Luzia"},
      {"name_uf": "PB", "name": "Santa Rita"},
      {"name_uf": "PB", "name": "Santa Teresinha"},
      {"name_uf": "PB", "name": "Santana de Mangueira"},
      {"name_uf": "PB", "name": "Santana dos Garrotes"},
      {"name_uf": "PB", "name": "Santo André"},
      {"name_uf": "PB", "name": "São Bentinho"},
      {"name_uf": "PB", "name": "São Bento"},
      {"name_uf": "PB", "name": "São Domingos"},
      {"name_uf": "PB", "name": "São Domingos do Cariri"},
      {"name_uf": "PB", "name": "São Francisco"},
      {"name_uf": "PB", "name": "São João do Cariri"},
      {"name_uf": "PB", "name": "São João do Rio do Peixe"},
      {"name_uf": "PB", "name": "São João do Tigre"},
      {"name_uf": "PB", "name": "São José da Lagoa Tapada"},
      {"name_uf": "PB", "name": "São José de Caiana"},
      {"name_uf": "PB", "name": "São José de Espinharas"},
      {"name_uf": "PB", "name": "São José de Piranhas"},
      {"name_uf": "PB", "name": "São José de Princesa"},
      {"name_uf": "PB", "name": "São José do Bonfim"},
      {"name_uf": "PB", "name": "São José do Brejo do Cruz"},
      {"name_uf": "PB", "name": "São José do Sabugi"},
      {"name_uf": "PB", "name": "São José dos Cordeiros"},
      {"name_uf": "PB", "name": "São José dos Ramos"},
      {"name_uf": "PB", "name": "São Mamede"},
      {"name_uf": "PB", "name": "São Miguel de Taipu"},
      {"name_uf": "PB", "name": "São Sebastião de Lagoa de Roça"},
      {"name_uf": "PB", "name": "São Sebastião do Umbuzeiro"},
      {"name_uf": "PB", "name": "São Vicente do Seridó"},
      {"name_uf": "PB", "name": "Sapé"},
      {"name_uf": "PB", "name": "Serra Branca"},
      {"name_uf": "PB", "name": "Serra da Raiz"},
      {"name_uf": "PB", "name": "Serra Grande"},
      {"name_uf": "PB", "name": "Serra Redonda"},
      {"name_uf": "PB", "name": "Serraria"},
      {"name_uf": "PB", "name": "Sertãozinho"},
      {"name_uf": "PB", "name": "Sobrado"},
      {"name_uf": "PB", "name": "Solânea"},
      {"name_uf": "PB", "name": "Soledade"},
      {"name_uf": "PB", "name": "Sossêgo"},
      {"name_uf": "PB", "name": "Sousa"},
      {"name_uf": "PB", "name": "Sumé"},
      {"name_uf": "PB", "name": "Tacima"},
      {"name_uf": "PB", "name": "Taperoá"},
      {"name_uf": "PB", "name": "Tavares"},
      {"name_uf": "PB", "name": "Teixeira"},
      {"name_uf": "PB", "name": "Tenório"},
      {"name_uf": "PB", "name": "Triunfo"},
      {"name_uf": "PB", "name": "Uiraúna"},
      {"name_uf": "PB", "name": "Umbuzeiro"},
      {"name_uf": "PB", "name": "Várzea"},
      {"name_uf": "PB", "name": "Vieirópolis"},
      {"name_uf": "PB", "name": "Vista Serrana"},
      {"name_uf": "PB", "name": "Zabelê"},
      {"name_uf": "PE", "name": "Abreu e Lima"},
      {"name_uf": "PE", "name": "Afogados da Ingazeira"},
      {"name_uf": "PE", "name": "Afrânio"},
      {"name_uf": "PE", "name": "Agrestina"},
      {"name_uf": "PE", "name": "Água Preta"},
      {"name_uf": "PE", "name": "Águas Belas"},
      {"name_uf": "PE", "name": "Alagoinha"},
      {"name_uf": "PE", "name": "Aliança"},
      {"name_uf": "PE", "name": "Altinho"},
      {"name_uf": "PE", "name": "Amaraji"},
      {"name_uf": "PE", "name": "Angelim"},
      {"name_uf": "PE", "name": "Araçoiaba"},
      {"name_uf": "PE", "name": "Araripina"},
      {"name_uf": "PE", "name": "Arcoverde"},
      {"name_uf": "PE", "name": "Barra de Guabiraba"},
      {"name_uf": "PE", "name": "Barreiros"},
      {"name_uf": "PE", "name": "Belém de Maria"},
      {"name_uf": "PE", "name": "Belém do São Francisco"},
      {"name_uf": "PE", "name": "Belo Jardim"},
      {"name_uf": "PE", "name": "Betânia"},
      {"name_uf": "PE", "name": "Bezerros"},
      {"name_uf": "PE", "name": "Bodocó"},
      {"name_uf": "PE", "name": "Bom Conselho"},
      {"name_uf": "PE", "name": "Bom Jardim"},
      {"name_uf": "PE", "name": "Bonito"},
      {"name_uf": "PE", "name": "Brejão"},
      {"name_uf": "PE", "name": "Brejinho"},
      {"name_uf": "PE", "name": "Brejo da Madre de Deus"},
      {"name_uf": "PE", "name": "Buenos Aires"},
      {"name_uf": "PE", "name": "Buíque"},
      {"name_uf": "PE", "name": "Cabo de Santo Agostinho"},
      {"name_uf": "PE", "name": "Cabrobó"},
      {"name_uf": "PE", "name": "Cachoeirinha"},
      {"name_uf": "PE", "name": "Caetés"},
      {"name_uf": "PE", "name": "Calçado"},
      {"name_uf": "PE", "name": "Calumbi"},
      {"name_uf": "PE", "name": "Camaragibe"},
      {"name_uf": "PE", "name": "Camocim de São Félix"},
      {"name_uf": "PE", "name": "Camutanga"},
      {"name_uf": "PE", "name": "Canhotinho"},
      {"name_uf": "PE", "name": "Capoeiras"},
      {"name_uf": "PE", "name": "Carnaíba"},
      {"name_uf": "PE", "name": "Carnaubeira da Penha"},
      {"name_uf": "PE", "name": "Carpina"},
      {"name_uf": "PE", "name": "Caruaru"},
      {"name_uf": "PE", "name": "Casinhas"},
      {"name_uf": "PE", "name": "Catende"},
      {"name_uf": "PE", "name": "Cedro"},
      {"name_uf": "PE", "name": "Chã de Alegria"},
      {"name_uf": "PE", "name": "Chã Grande"},
      {"name_uf": "PE", "name": "Condado"},
      {"name_uf": "PE", "name": "Correntes"},
      {"name_uf": "PE", "name": "Cortês"},
      {"name_uf": "PE", "name": "Cumaru"},
      {"name_uf": "PE", "name": "Cupira"},
      {"name_uf": "PE", "name": "Custódia"},
      {"name_uf": "PE", "name": "Dormentes"},
      {"name_uf": "PE", "name": "Escada"},
      {"name_uf": "PE", "name": "Exu"},
      {"name_uf": "PE", "name": "Feira Nova"},
      {"name_uf": "PE", "name": "Fernando de Noronha"},
      {"name_uf": "PE", "name": "Ferreiros"},
      {"name_uf": "PE", "name": "Flores"},
      {"name_uf": "PE", "name": "Floresta"},
      {"name_uf": "PE", "name": "Frei Miguelinho"},
      {"name_uf": "PE", "name": "Gameleira"},
      {"name_uf": "PE", "name": "Garanhuns"},
      {"name_uf": "PE", "name": "Glória do Goitá"},
      {"name_uf": "PE", "name": "Goiana"},
      {"name_uf": "PE", "name": "Granito"},
      {"name_uf": "PE", "name": "Gravatá"},
      {"name_uf": "PE", "name": "Iati"},
      {"name_uf": "PE", "name": "Ibimirim"},
      {"name_uf": "PE", "name": "Ibirajuba"},
      {"name_uf": "PE", "name": "Igarassu"},
      {"name_uf": "PE", "name": "Iguaracy"},
      {"name_uf": "PE", "name": "Ilha de Itamaracá"},
      {"name_uf": "PE", "name": "Inajá"},
      {"name_uf": "PE", "name": "Ingazeira"},
      {"name_uf": "PE", "name": "Ipojuca"},
      {"name_uf": "PE", "name": "Ipubi"},
      {"name_uf": "PE", "name": "Itacuruba"},
      {"name_uf": "PE", "name": "Itaíba"},
      {"name_uf": "PE", "name": "Itambé"},
      {"name_uf": "PE", "name": "Itapetim"},
      {"name_uf": "PE", "name": "Itapissuma"},
      {"name_uf": "PE", "name": "Itaquitinga"},
      {"name_uf": "PE", "name": "Jaboatão dos Guararapes"},
      {"name_uf": "PE", "name": "Jaqueira"},
      {"name_uf": "PE", "name": "Jataúba"},
      {"name_uf": "PE", "name": "Jatobá"},
      {"name_uf": "PE", "name": "João Alfredo"},
      {"name_uf": "PE", "name": "Joaquim Nabuco"},
      {"name_uf": "PE", "name": "Jucati"},
      {"name_uf": "PE", "name": "Jupi"},
      {"name_uf": "PE", "name": "Jurema"},
      {"name_uf": "PE", "name": "Lagoa de Itaenga"},
      {"name_uf": "PE", "name": "Lagoa do Carro"},
      {"name_uf": "PE", "name": "Lagoa do Ouro"},
      {"name_uf": "PE", "name": "Lagoa dos Gatos"},
      {"name_uf": "PE", "name": "Lagoa Grande"},
      {"name_uf": "PE", "name": "Lajedo"},
      {"name_uf": "PE", "name": "Limoeiro"},
      {"name_uf": "PE", "name": "Macaparana"},
      {"name_uf": "PE", "name": "Machados"},
      {"name_uf": "PE", "name": "Manari"},
      {"name_uf": "PE", "name": "Maraial"},
      {"name_uf": "PE", "name": "Mirandiba"},
      {"name_uf": "PE", "name": "Moreilândia"},
      {"name_uf": "PE", "name": "Moreno"},
      {"name_uf": "PE", "name": "Nazaré da Mata"},
      {"name_uf": "PE", "name": "Olinda"},
      {"name_uf": "PE", "name": "Orobó"},
      {"name_uf": "PE", "name": "Orocó"},
      {"name_uf": "PE", "name": "Ouricuri"},
      {"name_uf": "PE", "name": "Palmares"},
      {"name_uf": "PE", "name": "Palmeirina"},
      {"name_uf": "PE", "name": "Panelas"},
      {"name_uf": "PE", "name": "Paranatama"},
      {"name_uf": "PE", "name": "Parnamirim"},
      {"name_uf": "PE", "name": "Passira"},
      {"name_uf": "PE", "name": "Paudalho"},
      {"name_uf": "PE", "name": "Paulista"},
      {"name_uf": "PE", "name": "Pedra"},
      {"name_uf": "PE", "name": "Pesqueira"},
      {"name_uf": "PE", "name": "Petrolândia"},
      {"name_uf": "PE", "name": "Petrolina"},
      {"name_uf": "PE", "name": "Poção"},
      {"name_uf": "PE", "name": "Pombos"},
      {"name_uf": "PE", "name": "Primavera"},
      {"name_uf": "PE", "name": "Quipapá"},
      {"name_uf": "PE", "name": "Quixaba"},
      {"name_uf": "PE", "name": "Recife"},
      {"name_uf": "PE", "name": "Riacho das Almas"},
      {"name_uf": "PE", "name": "Ribeirão"},
      {"name_uf": "PE", "name": "Rio Formoso"},
      {"name_uf": "PE", "name": "Sairé"},
      {"name_uf": "PE", "name": "Salgadinho"},
      {"name_uf": "PE", "name": "Salgueiro"},
      {"name_uf": "PE", "name": "Saloá"},
      {"name_uf": "PE", "name": "Sanharó"},
      {"name_uf": "PE", "name": "Santa Cruz"},
      {"name_uf": "PE", "name": "Santa Cruz da Baixa Verde"},
      {"name_uf": "PE", "name": "Santa Cruz do Capibaribe"},
      {"name_uf": "PE", "name": "Santa Filomena"},
      {"name_uf": "PE", "name": "Santa Maria da Boa Vista"},
      {"name_uf": "PE", "name": "Santa Maria do Cambucá"},
      {"name_uf": "PE", "name": "Santa Terezinha"},
      {"name_uf": "PE", "name": "São Benedito do Sul"},
      {"name_uf": "PE", "name": "São Bento do Una"},
      {"name_uf": "PE", "name": "São Caitano"},
      {"name_uf": "PE", "name": "São João"},
      {"name_uf": "PE", "name": "São Joaquim do Monte"},
      {"name_uf": "PE", "name": "São José da Coroa Grande"},
      {"name_uf": "PE", "name": "São José do Belmonte"},
      {"name_uf": "PE", "name": "São José do Egito"},
      {"name_uf": "PE", "name": "São Lourenço da Mata"},
      {"name_uf": "PE", "name": "São Vicente Férrer"},
      {"name_uf": "PE", "name": "Serra Talhada"},
      {"name_uf": "PE", "name": "Serrita"},
      {"name_uf": "PE", "name": "Sertânia"},
      {"name_uf": "PE", "name": "Sirinhaém"},
      {"name_uf": "PE", "name": "Solidão"},
      {"name_uf": "PE", "name": "Surubim"},
      {"name_uf": "PE", "name": "Tabira"},
      {"name_uf": "PE", "name": "Tacaimbó"},
      {"name_uf": "PE", "name": "Tacaratu"},
      {"name_uf": "PE", "name": "Tamandaré"},
      {"name_uf": "PE", "name": "Taquaritinga do Norte"},
      {"name_uf": "PE", "name": "Terezinha"},
      {"name_uf": "PE", "name": "Terra Nova"},
      {"name_uf": "PE", "name": "Timbaúba"},
      {"name_uf": "PE", "name": "Toritama"},
      {"name_uf": "PE", "name": "Tracunhaém"},
      {"name_uf": "PE", "name": "Trindade"},
      {"name_uf": "PE", "name": "Triunfo"},
      {"name_uf": "PE", "name": "Tupanatinga"},
      {"name_uf": "PE", "name": "Tuparetama"},
      {"name_uf": "PE", "name": "Venturosa"},
      {"name_uf": "PE", "name": "Verdejante"},
      {"name_uf": "PE", "name": "Vertente do Lério"},
      {"name_uf": "PE", "name": "Vertentes"},
      {"name_uf": "PE", "name": "Vicência"},
      {"name_uf": "PE", "name": "Vitória de Santo Antão"},
      {"name_uf": "PE", "name": "Xexéu"},
      {"name_uf": "AL", "name": "Água Branca"},
      {"name_uf": "AL", "name": "Anadia"},
      {"name_uf": "AL", "name": "Arapiraca"},
      {"name_uf": "AL", "name": "Atalaia"},
      {"name_uf": "AL", "name": "Barra de Santo Antônio"},
      {"name_uf": "AL", "name": "Barra de São Miguel"},
      {"name_uf": "AL", "name": "Batalha"},
      {"name_uf": "AL", "name": "Belém"},
      {"name_uf": "AL", "name": "Belo Monte"},
      {"name_uf": "AL", "name": "Boca da Mata"},
      {"name_uf": "AL", "name": "Branquinha"},
      {"name_uf": "AL", "name": "Cacimbinhas"},
      {"name_uf": "AL", "name": "Cajueiro"},
      {"name_uf": "AL", "name": "Campestre"},
      {"name_uf": "AL", "name": "Campo Alegre"},
      {"name_uf": "AL", "name": "Campo Grande"},
      {"name_uf": "AL", "name": "Canapi"},
      {"name_uf": "AL", "name": "Capela"},
      {"name_uf": "AL", "name": "Carneiros"},
      {"name_uf": "AL", "name": "Chã Preta"},
      {"name_uf": "AL", "name": "Coité do Nóia"},
      {"name_uf": "AL", "name": "Colônia Leopoldina"},
      {"name_uf": "AL", "name": "Coqueiro Seco"},
      {"name_uf": "AL", "name": "Coruripe"},
      {"name_uf": "AL", "name": "Craíbas"},
      {"name_uf": "AL", "name": "Delmiro Gouveia"},
      {"name_uf": "AL", "name": "Dois Riachos"},
      {"name_uf": "AL", "name": "Estrela de AL"},
      {"name_uf": "AL", "name": "Feira Grande"},
      {"name_uf": "AL", "name": "Feliz Deserto"},
      {"name_uf": "AL", "name": "Flexeiras"},
      {"name_uf": "AL", "name": "Girau do Ponciano"},
      {"name_uf": "AL", "name": "Ibateguara"},
      {"name_uf": "AL", "name": "Igaci"},
      {"name_uf": "AL", "name": "Igreja Nova"},
      {"name_uf": "AL", "name": "Inhapi"},
      {"name_uf": "AL", "name": "Jacaré dos Homens"},
      {"name_uf": "AL", "name": "Jacuípe"},
      {"name_uf": "AL", "name": "Japaratinga"},
      {"name_uf": "AL", "name": "Jaramataia"},
      {"name_uf": "AL", "name": "Jequiá da Praia"},
      {"name_uf": "AL", "name": "Joaquim Gomes"},
      {"name_uf": "AL", "name": "Jundiá"},
      {"name_uf": "AL", "name": "Junqueiro"},
      {"name_uf": "AL", "name": "Lagoa da Canoa"},
      {"name_uf": "AL", "name": "Limoeiro de Anadia"},
      {"name_uf": "AL", "name": "Maceió"},
      {"name_uf": "AL", "name": "Major Isidoro"},
      {"name_uf": "AL", "name": "Mar Vermelho"},
      {"name_uf": "AL", "name": "Maragogi"},
      {"name_uf": "AL", "name": "Maravilha"},
      {"name_uf": "AL", "name": "Marechal Deodoro"},
      {"name_uf": "AL", "name": "Maribondo"},
      {"name_uf": "AL", "name": "Mata Grande"},
      {"name_uf": "AL", "name": "Matriz de Camaragibe"},
      {"name_uf": "AL", "name": "Messias"},
      {"name_uf": "AL", "name": "Minador do Negrão"},
      {"name_uf": "AL", "name": "Monteirópolis"},
      {"name_uf": "AL", "name": "Murici"},
      {"name_uf": "AL", "name": "Novo Lino"},
      {"name_uf": "AL", "name": "Olho d'Água das Flores"},
      {"name_uf": "AL", "name": "Olho d'Água do Casado"},
      {"name_uf": "AL", "name": "Olho d'Água Grande"},
      {"name_uf": "AL", "name": "Olivença"},
      {"name_uf": "AL", "name": "Ouro Branco"},
      {"name_uf": "AL", "name": "Palestina"},
      {"name_uf": "AL", "name": "Palmeira dos Índios"},
      {"name_uf": "AL", "name": "Pão de Açúcar"},
      {"name_uf": "AL", "name": "Pariconha"},
      {"name_uf": "AL", "name": "Paripueira"},
      {"name_uf": "AL", "name": "Passo de Camaragibe"},
      {"name_uf": "AL", "name": "Paulo Jacinto"},
      {"name_uf": "AL", "name": "Penedo"},
      {"name_uf": "AL", "name": "Piaçabuçu"},
      {"name_uf": "AL", "name": "Pilar"},
      {"name_uf": "AL", "name": "Pindoba"},
      {"name_uf": "AL", "name": "Piranhas"},
      {"name_uf": "AL", "name": "Poço das Trincheiras"},
      {"name_uf": "AL", "name": "Porto Calvo"},
      {"name_uf": "AL", "name": "Porto de Pedras"},
      {"name_uf": "AL", "name": "Porto Real do Colégio"},
      {"name_uf": "AL", "name": "Quebrangulo"},
      {"name_uf": "AL", "name": "Rio Largo"},
      {"name_uf": "AL", "name": "Roteiro"},
      {"name_uf": "AL", "name": "Santa Luzia do Norte"},
      {"name_uf": "AL", "name": "Santana do Ipanema"},
      {"name_uf": "AL", "name": "Santana do Mundaú"},
      {"name_uf": "AL", "name": "São Brás"},
      {"name_uf": "AL", "name": "São José da Laje"},
      {"name_uf": "AL", "name": "São José da Tapera"},
      {"name_uf": "AL", "name": "São Luís do Quitunde"},
      {"name_uf": "AL", "name": "São Miguel dos Campos"},
      {"name_uf": "AL", "name": "São Miguel dos Milagres"},
      {"name_uf": "AL", "name": "São Sebastião"},
      {"name_uf": "AL", "name": "Satuba"},
      {"name_uf": "AL", "name": "Senador Rui Palmeira"},
      {"name_uf": "AL", "name": "Tanque d'Arca"},
      {"name_uf": "AL", "name": "Taquarana"},
      {"name_uf": "AL", "name": "Teotônio Vilela"},
      {"name_uf": "AL", "name": "Traipu"},
      {"name_uf": "AL", "name": "União dos Palmares"},
      {"name_uf": "AL", "name": "Viçosa"},
      {"name_uf": "SE", "name": "Amparo de São Francisco"},
      {"name_uf": "SE", "name": "Aquidabã"},
      {"name_uf": "SE", "name": "Aracaju"},
      {"name_uf": "SE", "name": "Arauá"},
      {"name_uf": "SE", "name": "Areia Branca"},
      {"name_uf": "SE", "name": "Barra dos Coqueiros"},
      {"name_uf": "SE", "name": "Boquim"},
      {"name_uf": "SE", "name": "Brejo Grande"},
      {"name_uf": "SE", "name": "Campo do Brito"},
      {"name_uf": "SE", "name": "Canhoba"},
      {"name_uf": "SE", "name": "Canindé de São Francisco"},
      {"name_uf": "SE", "name": "Capela"},
      {"name_uf": "SE", "name": "Carira"},
      {"name_uf": "SE", "name": "Carmópolis"},
      {"name_uf": "SE", "name": "Cedro de São João"},
      {"name_uf": "SE", "name": "Cristinápolis"},
      {"name_uf": "SE", "name": "Cumbe"},
      {"name_uf": "SE", "name": "Divina Pastora"},
      {"name_uf": "SE", "name": "Estância"},
      {"name_uf": "SE", "name": "Feira Nova"},
      {"name_uf": "SE", "name": "Frei Paulo"},
      {"name_uf": "SE", "name": "Gararu"},
      {"name_uf": "SE", "name": "General Maynard"},
      {"name_uf": "SE", "name": "Gracho Cardoso"},
      {"name_uf": "SE", "name": "Ilha das Flores"},
      {"name_uf": "SE", "name": "Indiaroba"},
      {"name_uf": "SE", "name": "Itabaiana"},
      {"name_uf": "SE", "name": "Itabaianinha"},
      {"name_uf": "SE", "name": "Itabi"},
      {"name_uf": "SE", "name": "Itaporanga d'Ajuda"},
      {"name_uf": "SE", "name": "Japaratuba"},
      {"name_uf": "SE", "name": "Japoatã"},
      {"name_uf": "SE", "name": "Lagarto"},
      {"name_uf": "SE", "name": "Laranjeiras"},
      {"name_uf": "SE", "name": "Macambira"},
      {"name_uf": "SE", "name": "Malhada dos Bois"},
      {"name_uf": "SE", "name": "Malhador"},
      {"name_uf": "SE", "name": "Maruim"},
      {"name_uf": "SE", "name": "Moita Bonita"},
      {"name_uf": "SE", "name": "Monte Alegre de SE"},
      {"name_uf": "SE", "name": "Muribeca"},
      {"name_uf": "SE", "name": "Neópolis"},
      {"name_uf": "SE", "name": "Nossa Senhora Aparecida"},
      {"name_uf": "SE", "name": "Nossa Senhora da Glória"},
      {"name_uf": "SE", "name": "Nossa Senhora das Dores"},
      {"name_uf": "SE", "name": "Nossa Senhora de Lourdes"},
      {"name_uf": "SE", "name": "Nossa Senhora do Socorro"},
      {"name_uf": "SE", "name": "Pacatuba"},
      {"name_uf": "SE", "name": "Pedra Mole"},
      {"name_uf": "SE", "name": "Pedrinhas"},
      {"name_uf": "SE", "name": "Pinhão"},
      {"name_uf": "SE", "name": "Pirambu"},
      {"name_uf": "SE", "name": "Poço Redondo"},
      {"name_uf": "SE", "name": "Poço Verde"},
      {"name_uf": "SE", "name": "Porto da Folha"},
      {"name_uf": "SE", "name": "Propriá"},
      {"name_uf": "SE", "name": "Riachão do Dantas"},
      {"name_uf": "SE", "name": "Riachuelo"},
      {"name_uf": "SE", "name": "Ribeirópolis"},
      {"name_uf": "SE", "name": "Rosário do Catete"},
      {"name_uf": "SE", "name": "Salgado"},
      {"name_uf": "SE", "name": "Santa Luzia do Itanhy"},
      {"name_uf": "SE", "name": "Santa Rosa de Lima"},
      {"name_uf": "SE", "name": "Santana do São Francisco"},
      {"name_uf": "SE", "name": "Santo Amaro das Brotas"},
      {"name_uf": "SE", "name": "São Cristóvão"},
      {"name_uf": "SE", "name": "São Domingos"},
      {"name_uf": "SE", "name": "São Francisco"},
      {"name_uf": "SE", "name": "São Miguel do Aleixo"},
      {"name_uf": "SE", "name": "Simão Dias"},
      {"name_uf": "SE", "name": "Siriri"},
      {"name_uf": "SE", "name": "Telha"},
      {"name_uf": "SE", "name": "Tobias Barreto"},
      {"name_uf": "SE", "name": "Tomar do Geru"},
      {"name_uf": "SE", "name": "Umbaúba"},
      {"name_uf": "BA", "name": "Abaíra"},
      {"name_uf": "BA", "name": "Abaré"},
      {"name_uf": "BA", "name": "Acajutiba"},
      {"name_uf": "BA", "name": "Adustina"},
      {"name_uf": "BA", "name": "Água Fria"},
      {"name_uf": "BA", "name": "Aiquara"},
      {"name_uf": "BA", "name": "Alagoinhas"},
      {"name_uf": "BA", "name": "Alcobaça"},
      {"name_uf": "BA", "name": "Almadina"},
      {"name_uf": "BA", "name": "Amargosa"},
      {"name_uf": "BA", "name": "Amélia Rodrigues"},
      {"name_uf": "BA", "name": "América Dourada"},
      {"name_uf": "BA", "name": "Anagé"},
      {"name_uf": "BA", "name": "Andaraí"},
      {"name_uf": "BA", "name": "Andorinha"},
      {"name_uf": "BA", "name": "Angical"},
      {"name_uf": "BA", "name": "Anguera"},
      {"name_uf": "BA", "name": "Antas"},
      {"name_uf": "BA", "name": "Antônio Cardoso"},
      {"name_uf": "BA", "name": "Antônio Gonçalves"},
      {"name_uf": "BA", "name": "Aporá"},
      {"name_uf": "BA", "name": "Apuarema"},
      {"name_uf": "BA", "name": "Araçás"},
      {"name_uf": "BA", "name": "Aracatu"},
      {"name_uf": "BA", "name": "Araci"},
      {"name_uf": "BA", "name": "Aramari"},
      {"name_uf": "BA", "name": "Arataca"},
      {"name_uf": "BA", "name": "Aratuípe"},
      {"name_uf": "BA", "name": "Aurelino Leal"},
      {"name_uf": "BA", "name": "Baianópolis"},
      {"name_uf": "BA", "name": "Baixa Grande"},
      {"name_uf": "BA", "name": "Banzaê"},
      {"name_uf": "BA", "name": "Barra"},
      {"name_uf": "BA", "name": "Barra da Estiva"},
      {"name_uf": "BA", "name": "Barra do Choça"},
      {"name_uf": "BA", "name": "Barra do Mendes"},
      {"name_uf": "BA", "name": "Barra do Rocha"},
      {"name_uf": "BA", "name": "Barreiras"},
      {"name_uf": "BA", "name": "Barro Alto"},
      {"name_uf": "BA", "name": "Barro Preto"},
      {"name_uf": "BA", "name": "Barrocas"},
      {"name_uf": "BA", "name": "Belmonte"},
      {"name_uf": "BA", "name": "Belo Campo"},
      {"name_uf": "BA", "name": "Biritinga"},
      {"name_uf": "BA", "name": "Boa Nova"},
      {"name_uf": "BA", "name": "Boa Vista do Tupim"},
      {"name_uf": "BA", "name": "Bom Jesus da Lapa"},
      {"name_uf": "BA", "name": "Bom Jesus da Serra"},
      {"name_uf": "BA", "name": "Boninal"},
      {"name_uf": "BA", "name": "Bonito"},
      {"name_uf": "BA", "name": "Boquira"},
      {"name_uf": "BA", "name": "Botuporã"},
      {"name_uf": "BA", "name": "Brejões"},
      {"name_uf": "BA", "name": "Brejolândia"},
      {"name_uf": "BA", "name": "Brotas de Macaúbas"},
      {"name_uf": "BA", "name": "Brumado"},
      {"name_uf": "BA", "name": "Buerarema"},
      {"name_uf": "BA", "name": "Buritirama"},
      {"name_uf": "BA", "name": "Caatiba"},
      {"name_uf": "BA", "name": "Cabaceiras do Paraguaçu"},
      {"name_uf": "BA", "name": "Cachoeira"},
      {"name_uf": "BA", "name": "Caculé"},
      {"name_uf": "BA", "name": "Caém"},
      {"name_uf": "BA", "name": "Caetanos"},
      {"name_uf": "BA", "name": "Caetité"},
      {"name_uf": "BA", "name": "Cafarnaum"},
      {"name_uf": "BA", "name": "Cairu"},
      {"name_uf": "BA", "name": "Caldeirão Grande"},
      {"name_uf": "BA", "name": "Camacan"},
      {"name_uf": "BA", "name": "Camaçari"},
      {"name_uf": "BA", "name": "Camamu"},
      {"name_uf": "BA", "name": "Campo Alegre de Lourdes"},
      {"name_uf": "BA", "name": "Campo Formoso"},
      {"name_uf": "BA", "name": "Canápolis"},
      {"name_uf": "BA", "name": "Canarana"},
      {"name_uf": "BA", "name": "Canavieiras"},
      {"name_uf": "BA", "name": "Candeal"},
      {"name_uf": "BA", "name": "Candeias"},
      {"name_uf": "BA", "name": "Candiba"},
      {"name_uf": "BA", "name": "Cândido Sales"},
      {"name_uf": "BA", "name": "Cansanção"},
      {"name_uf": "BA", "name": "Canudos"},
      {"name_uf": "BA", "name": "Capela do Alto Alegre"},
      {"name_uf": "BA", "name": "Capim Grosso"},
      {"name_uf": "BA", "name": "Caraíbas"},
      {"name_uf": "BA", "name": "Caravelas"},
      {"name_uf": "BA", "name": "Cardeal da Silva"},
      {"name_uf": "BA", "name": "Carinhanha"},
      {"name_uf": "BA", "name": "Casa Nova"},
      {"name_uf": "BA", "name": "Castro Alves"},
      {"name_uf": "BA", "name": "Catolândia"},
      {"name_uf": "BA", "name": "Catu"},
      {"name_uf": "BA", "name": "Caturama"},
      {"name_uf": "BA", "name": "Central"},
      {"name_uf": "BA", "name": "Chorrochó"},
      {"name_uf": "BA", "name": "Cícero Dantas"},
      {"name_uf": "BA", "name": "Cipó"},
      {"name_uf": "BA", "name": "Coaraci"},
      {"name_uf": "BA", "name": "Cocos"},
      {"name_uf": "BA", "name": "Conceição da Feira"},
      {"name_uf": "BA", "name": "Conceição do Almeida"},
      {"name_uf": "BA", "name": "Conceição do Coité"},
      {"name_uf": "BA", "name": "Conceição do Jacuípe"},
      {"name_uf": "BA", "name": "Conde"},
      {"name_uf": "BA", "name": "Condeúba"},
      {"name_uf": "BA", "name": "Contendas do Sincorá"},
      {"name_uf": "BA", "name": "Coração de Maria"},
      {"name_uf": "BA", "name": "Cordeiros"},
      {"name_uf": "BA", "name": "Coribe"},
      {"name_uf": "BA", "name": "Coronel João Sá"},
      {"name_uf": "BA", "name": "Correntina"},
      {"name_uf": "BA", "name": "Cotegipe"},
      {"name_uf": "BA", "name": "Cravolândia"},
      {"name_uf": "BA", "name": "Crisópolis"},
      {"name_uf": "BA", "name": "Cristópolis"},
      {"name_uf": "BA", "name": "Cruz das Almas"},
      {"name_uf": "BA", "name": "Curaçá"},
      {"name_uf": "BA", "name": "Dário Meira"},
      {"name_uf": "BA", "name": "Dias d'Ávila"},
      {"name_uf": "BA", "name": "Dom Basílio"},
      {"name_uf": "BA", "name": "Dom Macedo Costa"},
      {"name_uf": "BA", "name": "Elísio Medrado"},
      {"name_uf": "BA", "name": "Encruzilhada"},
      {"name_uf": "BA", "name": "Entre Rios"},
      {"name_uf": "BA", "name": "Érico Cardoso"},
      {"name_uf": "BA", "name": "Esplanada"},
      {"name_uf": "BA", "name": "Euclides da Cunha"},
      {"name_uf": "BA", "name": "Eunápolis"},
      {"name_uf": "BA", "name": "Fátima"},
      {"name_uf": "BA", "name": "Feira da Mata"},
      {"name_uf": "BA", "name": "Feira de Santana"},
      {"name_uf": "BA", "name": "Filadélfia"},
      {"name_uf": "BA", "name": "Firmino Alves"},
      {"name_uf": "BA", "name": "Floresta Azul"},
      {"name_uf": "BA", "name": "Formosa do Rio Preto"},
      {"name_uf": "BA", "name": "Gandu"},
      {"name_uf": "BA", "name": "Gavião"},
      {"name_uf": "BA", "name": "Gentio do Ouro"},
      {"name_uf": "BA", "name": "Glória"},
      {"name_uf": "BA", "name": "Gongogi"},
      {"name_uf": "BA", "name": "Governador Mangabeira"},
      {"name_uf": "BA", "name": "Guajeru"},
      {"name_uf": "BA", "name": "Guanambi"},
      {"name_uf": "BA", "name": "Guaratinga"},
      {"name_uf": "BA", "name": "Heliópolis"},
      {"name_uf": "BA", "name": "Iaçu"},
      {"name_uf": "BA", "name": "Ibiassucê"},
      {"name_uf": "BA", "name": "Ibicaraí"},
      {"name_uf": "BA", "name": "Ibicoara"},
      {"name_uf": "BA", "name": "Ibicuí"},
      {"name_uf": "BA", "name": "Ibipeba"},
      {"name_uf": "BA", "name": "Ibipitanga"},
      {"name_uf": "BA", "name": "Ibiquera"},
      {"name_uf": "BA", "name": "Ibirapitanga"},
      {"name_uf": "BA", "name": "Ibirapuã"},
      {"name_uf": "BA", "name": "Ibirataia"},
      {"name_uf": "BA", "name": "Ibitiara"},
      {"name_uf": "BA", "name": "Ibititá"},
      {"name_uf": "BA", "name": "Ibotirama"},
      {"name_uf": "BA", "name": "Ichu"},
      {"name_uf": "BA", "name": "Igaporã"},
      {"name_uf": "BA", "name": "Igrapiúna"},
      {"name_uf": "BA", "name": "Iguaí"},
      {"name_uf": "BA", "name": "Ilhéus"},
      {"name_uf": "BA", "name": "Inhambupe"},
      {"name_uf": "BA", "name": "Ipecaetá"},
      {"name_uf": "BA", "name": "Ipiaú"},
      {"name_uf": "BA", "name": "Ipirá"},
      {"name_uf": "BA", "name": "Ipupiara"},
      {"name_uf": "BA", "name": "Irajuba"},
      {"name_uf": "BA", "name": "Iramaia"},
      {"name_uf": "BA", "name": "Iraquara"},
      {"name_uf": "BA", "name": "Irará"},
      {"name_uf": "BA", "name": "Irecê"},
      {"name_uf": "BA", "name": "Itabela"},
      {"name_uf": "BA", "name": "Itaberaba"},
      {"name_uf": "BA", "name": "Itabuna"},
      {"name_uf": "BA", "name": "Itacaré"},
      {"name_uf": "BA", "name": "Itaeté"},
      {"name_uf": "BA", "name": "Itagi"},
      {"name_uf": "BA", "name": "Itagibá"},
      {"name_uf": "BA", "name": "Itagimirim"},
      {"name_uf": "BA", "name": "Itaguaçu da BA"},
      {"name_uf": "BA", "name": "Itaju do Colônia"},
      {"name_uf": "BA", "name": "Itajuípe"},
      {"name_uf": "BA", "name": "Itamaraju"},
      {"name_uf": "BA", "name": "Itamari"},
      {"name_uf": "BA", "name": "Itambé"},
      {"name_uf": "BA", "name": "Itanagra"},
      {"name_uf": "BA", "name": "Itanhém"},
      {"name_uf": "BA", "name": "Itaparica"},
      {"name_uf": "BA", "name": "Itapé"},
      {"name_uf": "BA", "name": "Itapebi"},
      {"name_uf": "BA", "name": "Itapetinga"},
      {"name_uf": "BA", "name": "Itapicuru"},
      {"name_uf": "BA", "name": "Itapitanga"},
      {"name_uf": "BA", "name": "Itaquara"},
      {"name_uf": "BA", "name": "Itarantim"},
      {"name_uf": "BA", "name": "Itatim"},
      {"name_uf": "BA", "name": "Itiruçu"},
      {"name_uf": "BA", "name": "Itiúba"},
      {"name_uf": "BA", "name": "Itororó"},
      {"name_uf": "BA", "name": "Ituaçu"},
      {"name_uf": "BA", "name": "Ituberá"},
      {"name_uf": "BA", "name": "Iuiu"},
      {"name_uf": "BA", "name": "Jaborandi"},
      {"name_uf": "BA", "name": "Jacaraci"},
      {"name_uf": "BA", "name": "Jacobina"},
      {"name_uf": "BA", "name": "Jaguaquara"},
      {"name_uf": "BA", "name": "Jaguarari"},
      {"name_uf": "BA", "name": "Jaguaripe"},
      {"name_uf": "BA", "name": "Jandaíra"},
      {"name_uf": "BA", "name": "Jequié"},
      {"name_uf": "BA", "name": "Jeremoabo"},
      {"name_uf": "BA", "name": "Jiquiriçá"},
      {"name_uf": "BA", "name": "Jitaúna"},
      {"name_uf": "BA", "name": "João Dourado"},
      {"name_uf": "BA", "name": "Juazeiro"},
      {"name_uf": "BA", "name": "Jucuruçu"},
      {"name_uf": "BA", "name": "Jussara"},
      {"name_uf": "BA", "name": "Jussari"},
      {"name_uf": "BA", "name": "Jussiape"},
      {"name_uf": "BA", "name": "Lafaiete Coutinho"},
      {"name_uf": "BA", "name": "Lagoa Real"},
      {"name_uf": "BA", "name": "Laje"},
      {"name_uf": "BA", "name": "Lajedão"},
      {"name_uf": "BA", "name": "Lajedinho"},
      {"name_uf": "BA", "name": "Lajedo do Tabocal"},
      {"name_uf": "BA", "name": "Lamarão"},
      {"name_uf": "BA", "name": "Lapão"},
      {"name_uf": "BA", "name": "Lauro de Freitas"},
      {"name_uf": "BA", "name": "Lençóis"},
      {"name_uf": "BA", "name": "Licínio de Almeida"},
      {"name_uf": "BA", "name": "Livramento de Nossa Senhora"},
      {"name_uf": "BA", "name": "Luís Eduardo Magalhães"},
      {"name_uf": "BA", "name": "Macajuba"},
      {"name_uf": "BA", "name": "Macarani"},
      {"name_uf": "BA", "name": "Macaúbas"},
      {"name_uf": "BA", "name": "Macururé"},
      {"name_uf": "BA", "name": "Madre de Deus"},
      {"name_uf": "BA", "name": "Maetinga"},
      {"name_uf": "BA", "name": "Maiquinique"},
      {"name_uf": "BA", "name": "Mairi"},
      {"name_uf": "BA", "name": "Malhada"},
      {"name_uf": "BA", "name": "Malhada de Pedras"},
      {"name_uf": "BA", "name": "Manoel Vitorino"},
      {"name_uf": "BA", "name": "Mansidão"},
      {"name_uf": "BA", "name": "Maracás"},
      {"name_uf": "BA", "name": "Maragogipe"},
      {"name_uf": "BA", "name": "Maraú"},
      {"name_uf": "BA", "name": "Marcionílio Souza"},
      {"name_uf": "BA", "name": "Mascote"},
      {"name_uf": "BA", "name": "Mata de São João"},
      {"name_uf": "BA", "name": "Matina"},
      {"name_uf": "BA", "name": "Medeiros Neto"},
      {"name_uf": "BA", "name": "Miguel Calmon"},
      {"name_uf": "BA", "name": "Milagres"},
      {"name_uf": "BA", "name": "Mirangaba"},
      {"name_uf": "BA", "name": "Mirante"},
      {"name_uf": "BA", "name": "Monte Santo"},
      {"name_uf": "BA", "name": "MorPA"},
      {"name_uf": "BA", "name": "Morro do Chapéu"},
      {"name_uf": "BA", "name": "Mortugaba"},
      {"name_uf": "BA", "name": "Mucugê"},
      {"name_uf": "BA", "name": "Mucuri"},
      {"name_uf": "BA", "name": "Mulungu do Morro"},
      {"name_uf": "BA", "name": "Mundo Novo"},
      {"name_uf": "BA", "name": "Muniz Ferreira"},
      {"name_uf": "BA", "name": "Muquém do São Francisco"},
      {"name_uf": "BA", "name": "Muritiba"},
      {"name_uf": "BA", "name": "Mutuípe"},
      {"name_uf": "BA", "name": "Nazaré"},
      {"name_uf": "BA", "name": "Nilo Peçanha"},
      {"name_uf": "BA", "name": "Nordestina"},
      {"name_uf": "BA", "name": "Nova Canaã"},
      {"name_uf": "BA", "name": "Nova Fátima"},
      {"name_uf": "BA", "name": "Nova Ibiá"},
      {"name_uf": "BA", "name": "Nova Itarana"},
      {"name_uf": "BA", "name": "Nova Redenção"},
      {"name_uf": "BA", "name": "Nova Soure"},
      {"name_uf": "BA", "name": "Nova Viçosa"},
      {"name_uf": "BA", "name": "Novo Horizonte"},
      {"name_uf": "BA", "name": "Novo Triunfo"},
      {"name_uf": "BA", "name": "Olindina"},
      {"name_uf": "BA", "name": "Oliveira dos Brejinhos"},
      {"name_uf": "BA", "name": "Ouriçangas"},
      {"name_uf": "BA", "name": "Ourolândia"},
      {"name_uf": "BA", "name": "Palmas de Monte Alto"},
      {"name_uf": "BA", "name": "Palmeiras"},
      {"name_uf": "BA", "name": "Paramirim"},
      {"name_uf": "BA", "name": "Paratinga"},
      {"name_uf": "BA", "name": "Paripiranga"},
      {"name_uf": "BA", "name": "Pau Brasil"},
      {"name_uf": "BA", "name": "Paulo Afonso"},
      {"name_uf": "BA", "name": "Pé de Serra"},
      {"name_uf": "BA", "name": "Pedrão"},
      {"name_uf": "BA", "name": "Pedro Alexandre"},
      {"name_uf": "BA", "name": "Piatã"},
      {"name_uf": "BA", "name": "Pilão Arcado"},
      {"name_uf": "BA", "name": "Pindaí"},
      {"name_uf": "BA", "name": "Pindobaçu"},
      {"name_uf": "BA", "name": "Pintadas"},
      {"name_uf": "BA", "name": "Piraí do Norte"},
      {"name_uf": "BA", "name": "Piripá"},
      {"name_uf": "BA", "name": "Piritiba"},
      {"name_uf": "BA", "name": "Planaltino"},
      {"name_uf": "BA", "name": "Planalto"},
      {"name_uf": "BA", "name": "Poções"},
      {"name_uf": "BA", "name": "Pojuca"},
      {"name_uf": "BA", "name": "Ponto Novo"},
      {"name_uf": "BA", "name": "Porto Seguro"},
      {"name_uf": "BA", "name": "Potiraguá"},
      {"name_uf": "BA", "name": "Prado"},
      {"name_uf": "BA", "name": "Presidente Dutra"},
      {"name_uf": "BA", "name": "Presidente Jânio Quadros"},
      {"name_uf": "BA", "name": "Presidente Tancredo Neves"},
      {"name_uf": "BA", "name": "Queimadas"},
      {"name_uf": "BA", "name": "Quijingue"},
      {"name_uf": "BA", "name": "Quixabeira"},
      {"name_uf": "BA", "name": "Rafael Jambeiro"},
      {"name_uf": "BA", "name": "Remanso"},
      {"name_uf": "BA", "name": "Retirolândia"},
      {"name_uf": "BA", "name": "Riachão das Neves"},
      {"name_uf": "BA", "name": "Riachão do Jacuípe"},
      {"name_uf": "BA", "name": "Riacho de Santana"},
      {"name_uf": "BA", "name": "Ribeira do Amparo"},
      {"name_uf": "BA", "name": "Ribeira do Pombal"},
      {"name_uf": "BA", "name": "Ribeirão do Largo"},
      {"name_uf": "BA", "name": "Rio de Contas"},
      {"name_uf": "BA", "name": "Rio do Antônio"},
      {"name_uf": "BA", "name": "Rio do Pires"},
      {"name_uf": "BA", "name": "Rio Real"},
      {"name_uf": "BA", "name": "Rodelas"},
      {"name_uf": "BA", "name": "Ruy Barbosa"},
      {"name_uf": "BA", "name": "Salinas da Margarida"},
      {"name_uf": "BA", "name": "Salvador"},
      {"name_uf": "BA", "name": "Santa Bárbara"},
      {"name_uf": "BA", "name": "Santa Brígida"},
      {"name_uf": "BA", "name": "Santa Cruz Cabrália"},
      {"name_uf": "BA", "name": "Santa Cruz da Vitória"},
      {"name_uf": "BA", "name": "Santa Inês"},
      {"name_uf": "BA", "name": "Santa Luzia"},
      {"name_uf": "BA", "name": "Santa Maria da Vitória"},
      {"name_uf": "BA", "name": "Santa Rita de Cássia"},
      {"name_uf": "BA", "name": "Santa Terezinha"},
      {"name_uf": "BA", "name": "Santaluz"},
      {"name_uf": "BA", "name": "Santana"},
      {"name_uf": "BA", "name": "Santanópolis"},
      {"name_uf": "BA", "name": "Santo Amaro"},
      {"name_uf": "BA", "name": "Santo Antônio de Jesus"},
      {"name_uf": "BA", "name": "Santo Estêvão"},
      {"name_uf": "BA", "name": "São Desidério"},
      {"name_uf": "BA", "name": "São Domingos"},
      {"name_uf": "BA", "name": "São Felipe"},
      {"name_uf": "BA", "name": "São Félix"},
      {"name_uf": "BA", "name": "São Félix do Coribe"},
      {"name_uf": "BA", "name": "São Francisco do Conde"},
      {"name_uf": "BA", "name": "São Gabriel"},
      {"name_uf": "BA", "name": "São Gonçalo dos Campos"},
      {"name_uf": "BA", "name": "São José da Vitória"},
      {"name_uf": "BA", "name": "São José do Jacuípe"},
      {"name_uf": "BA", "name": "São Miguel das Matas"},
      {"name_uf": "BA", "name": "São Sebastião do Passé"},
      {"name_uf": "BA", "name": "Sapeaçu"},
      {"name_uf": "BA", "name": "Sátiro Dias"},
      {"name_uf": "BA", "name": "Saubara"},
      {"name_uf": "BA", "name": "Saúde"},
      {"name_uf": "BA", "name": "Seabra"},
      {"name_uf": "BA", "name": "Sebastião Laranjeiras"},
      {"name_uf": "BA", "name": "Senhor do Bonfim"},
      {"name_uf": "BA", "name": "Sento Sé"},
      {"name_uf": "BA", "name": "Serra do Ramalho"},
      {"name_uf": "BA", "name": "Serra Dourada"},
      {"name_uf": "BA", "name": "Serra Preta"},
      {"name_uf": "BA", "name": "Serrinha"},
      {"name_uf": "BA", "name": "Serrolândia"},
      {"name_uf": "BA", "name": "Simões Filho"},
      {"name_uf": "BA", "name": "Sítio do Mato"},
      {"name_uf": "BA", "name": "Sítio do Quinto"},
      {"name_uf": "BA", "name": "Sobradinho"},
      {"name_uf": "BA", "name": "Souto Soares"},
      {"name_uf": "BA", "name": "Tabocas do Brejo Velho"},
      {"name_uf": "BA", "name": "Tanhaçu"},
      {"name_uf": "BA", "name": "Tanque Novo"},
      {"name_uf": "BA", "name": "Tanquinho"},
      {"name_uf": "BA", "name": "Taperoá"},
      {"name_uf": "BA", "name": "Tapiramutá"},
      {"name_uf": "BA", "name": "Teixeira de Freitas"},
      {"name_uf": "BA", "name": "Teodoro Sampaio"},
      {"name_uf": "BA", "name": "Teofilândia"},
      {"name_uf": "BA", "name": "Teolândia"},
      {"name_uf": "BA", "name": "Terra Nova"},
      {"name_uf": "BA", "name": "Tremedal"},
      {"name_uf": "BA", "name": "Tucano"},
      {"name_uf": "BA", "name": "Uauá"},
      {"name_uf": "BA", "name": "Ubaíra"},
      {"name_uf": "BA", "name": "Ubaitaba"},
      {"name_uf": "BA", "name": "Ubatã"},
      {"name_uf": "BA", "name": "Uibaí"},
      {"name_uf": "BA", "name": "Umburanas"},
      {"name_uf": "BA", "name": "Una"},
      {"name_uf": "BA", "name": "Urandi"},
      {"name_uf": "BA", "name": "Uruçuca"},
      {"name_uf": "BA", "name": "Utinga"},
      {"name_uf": "BA", "name": "Valença"},
      {"name_uf": "BA", "name": "Valente"},
      {"name_uf": "BA", "name": "Várzea da Roça"},
      {"name_uf": "BA", "name": "Várzea do Poço"},
      {"name_uf": "BA", "name": "Várzea Nova"},
      {"name_uf": "BA", "name": "Varzedo"},
      {"name_uf": "BA", "name": "Vera Cruz"},
      {"name_uf": "BA", "name": "Vereda"},
      {"name_uf": "BA", "name": "Vitória da Conquista"},
      {"name_uf": "BA", "name": "Wagner"},
      {"name_uf": "BA", "name": "Wanderley"},
      {"name_uf": "BA", "name": "Wenceslau Guimarães"},
      {"name_uf": "BA", "name": "Xique-Xique"},
      {"name_uf": "MG", "name": "Abadia dos Dourados"},
      {"name_uf": "MG", "name": "Abaeté"},
      {"name_uf": "MG", "name": "Abre Campo"},
      {"name_uf": "MG", "name": "Acaiaca"},
      {"name_uf": "MG", "name": "Açucena"},
      {"name_uf": "MG", "name": "Água Boa"},
      {"name_uf": "MG", "name": "Água Comprida"},
      {"name_uf": "MG", "name": "Aguanil"},
      {"name_uf": "MG", "name": "Águas Formosas"},
      {"name_uf": "MG", "name": "Águas Vermelhas"},
      {"name_uf": "MG", "name": "Aimorés"},
      {"name_uf": "MG", "name": "Aiuruoca"},
      {"name_uf": "MG", "name": "Alagoa"},
      {"name_uf": "MG", "name": "Albertina"},
      {"name_uf": "MG", "name": "Além PB"},
      {"name_uf": "MG", "name": "Alfenas"},
      {"name_uf": "MG", "name": "Alfredo Vasconcelos"},
      {"name_uf": "MG", "name": "Almenara"},
      {"name_uf": "MG", "name": "Alpercata"},
      {"name_uf": "MG", "name": "Alpinópolis"},
      {"name_uf": "MG", "name": "Alterosa"},
      {"name_uf": "MG", "name": "Alto Caparaó"},
      {"name_uf": "MG", "name": "Alto Jequitibá"},
      {"name_uf": "MG", "name": "Alto Rio Doce"},
      {"name_uf": "MG", "name": "Alvarenga"},
      {"name_uf": "MG", "name": "Alvinópolis"},
      {"name_uf": "MG", "name": "Alvorada de Minas"},
      {"name_uf": "MG", "name": "Amparo do Serra"},
      {"name_uf": "MG", "name": "Andradas"},
      {"name_uf": "MG", "name": "Andrelândia"},
      {"name_uf": "MG", "name": "Angelândia"},
      {"name_uf": "MG", "name": "Antônio Carlos"},
      {"name_uf": "MG", "name": "Antônio Dias"},
      {"name_uf": "MG", "name": "Antônio Prado de Minas"},
      {"name_uf": "MG", "name": "Araçaí"},
      {"name_uf": "MG", "name": "Aracitaba"},
      {"name_uf": "MG", "name": "Araçuaí"},
      {"name_uf": "MG", "name": "Araguari"},
      {"name_uf": "MG", "name": "Arantina"},
      {"name_uf": "MG", "name": "Araponga"},
      {"name_uf": "MG", "name": "Araporã"},
      {"name_uf": "MG", "name": "Arapuá"},
      {"name_uf": "MG", "name": "Araújos"},
      {"name_uf": "MG", "name": "Araxá"},
      {"name_uf": "MG", "name": "Arceburgo"},
      {"name_uf": "MG", "name": "Arcos"},
      {"name_uf": "MG", "name": "Areado"},
      {"name_uf": "MG", "name": "Argirita"},
      {"name_uf": "MG", "name": "Aricanduva"},
      {"name_uf": "MG", "name": "Arinos"},
      {"name_uf": "MG", "name": "Astolfo Dutra"},
      {"name_uf": "MG", "name": "Ataléia"},
      {"name_uf": "MG", "name": "Augusto de Lima"},
      {"name_uf": "MG", "name": "Baependi"},
      {"name_uf": "MG", "name": "Baldim"},
      {"name_uf": "MG", "name": "Bambuí"},
      {"name_uf": "MG", "name": "Bandeira"},
      {"name_uf": "MG", "name": "Bandeira do Sul"},
      {"name_uf": "MG", "name": "Barão de Cocais"},
      {"name_uf": "MG", "name": "Barão de Monte Alto"},
      {"name_uf": "MG", "name": "Barbacena"},
      {"name_uf": "MG", "name": "Barra Longa"},
      {"name_uf": "MG", "name": "Barroso"},
      {"name_uf": "MG", "name": "Bela Vista de Minas"},
      {"name_uf": "MG", "name": "Belmiro Braga"},
      {"name_uf": "MG", "name": "Belo Horizonte"},
      {"name_uf": "MG", "name": "Belo Oriente"},
      {"name_uf": "MG", "name": "Belo Vale"},
      {"name_uf": "MG", "name": "Berilo"},
      {"name_uf": "MG", "name": "Berizal"},
      {"name_uf": "MG", "name": "Bertópolis"},
      {"name_uf": "MG", "name": "Betim"},
      {"name_uf": "MG", "name": "Bias Fortes"},
      {"name_uf": "MG", "name": "Bicas"},
      {"name_uf": "MG", "name": "Biquinhas"},
      {"name_uf": "MG", "name": "Boa Esperança"},
      {"name_uf": "MG", "name": "Bocaina de Minas"},
      {"name_uf": "MG", "name": "Bocaiúva"},
      {"name_uf": "MG", "name": "Bom Despacho"},
      {"name_uf": "MG", "name": "Bom Jardim de Minas"},
      {"name_uf": "MG", "name": "Bom Jesus da Penha"},
      {"name_uf": "MG", "name": "Bom Jesus do Amparo"},
      {"name_uf": "MG", "name": "Bom Jesus do Galho"},
      {"name_uf": "MG", "name": "Bom Repouso"},
      {"name_uf": "MG", "name": "Bom Sucesso"},
      {"name_uf": "MG", "name": "Bonfim"},
      {"name_uf": "MG", "name": "Bonfinópolis de Minas"},
      {"name_uf": "MG", "name": "Bonito de Minas"},
      {"name_uf": "MG", "name": "Borda da Mata"},
      {"name_uf": "MG", "name": "Botelhos"},
      {"name_uf": "MG", "name": "Botumirim"},
      {"name_uf": "MG", "name": "Brás Pires"},
      {"name_uf": "MG", "name": "Brasilândia de Minas"},
      {"name_uf": "MG", "name": "Brasília de Minas"},
      {"name_uf": "MG", "name": "Braúnas"},
      {"name_uf": "MG", "name": "Brazópolis"},
      {"name_uf": "MG", "name": "Brumadinho"},
      {"name_uf": "MG", "name": "Bueno Brandão"},
      {"name_uf": "MG", "name": "Buenópolis"},
      {"name_uf": "MG", "name": "Bugre"},
      {"name_uf": "MG", "name": "Buritis"},
      {"name_uf": "MG", "name": "Buritizeiro"},
      {"name_uf": "MG", "name": "Cabeceira Grande"},
      {"name_uf": "MG", "name": "Cabo Verde"},
      {"name_uf": "MG", "name": "Cachoeira da Prata"},
      {"name_uf": "MG", "name": "Cachoeira de Minas"},
      {"name_uf": "MG", "name": "Cachoeira de Pajeú"},
      {"name_uf": "MG", "name": "Cachoeira Dourada"},
      {"name_uf": "MG", "name": "Caetanópolis"},
      {"name_uf": "MG", "name": "Caeté"},
      {"name_uf": "MG", "name": "Caiana"},
      {"name_uf": "MG", "name": "Cajuri"},
      {"name_uf": "MG", "name": "Caldas"},
      {"name_uf": "MG", "name": "Camacho"},
      {"name_uf": "MG", "name": "Camanducaia"},
      {"name_uf": "MG", "name": "Cambuí"},
      {"name_uf": "MG", "name": "Cambuquira"},
      {"name_uf": "MG", "name": "Campanário"},
      {"name_uf": "MG", "name": "Campanha"},
      {"name_uf": "MG", "name": "Campestre"},
      {"name_uf": "MG", "name": "Campina Verde"},
      {"name_uf": "MG", "name": "Campo Azul"},
      {"name_uf": "MG", "name": "Campo Belo"},
      {"name_uf": "MG", "name": "Campo do Meio"},
      {"name_uf": "MG", "name": "Campo Florido"},
      {"name_uf": "MG", "name": "Campos Altos"},
      {"name_uf": "MG", "name": "Campos Gerais"},
      {"name_uf": "MG", "name": "Cana Verde"},
      {"name_uf": "MG", "name": "Canaã"},
      {"name_uf": "MG", "name": "Canápolis"},
      {"name_uf": "MG", "name": "Candeias"},
      {"name_uf": "MG", "name": "Cantagalo"},
      {"name_uf": "MG", "name": "Caparaó"},
      {"name_uf": "MG", "name": "Capela Nova"},
      {"name_uf": "MG", "name": "Capelinha"},
      {"name_uf": "MG", "name": "Capetinga"},
      {"name_uf": "MG", "name": "Capim Branco"},
      {"name_uf": "MG", "name": "Capinópolis"},
      {"name_uf": "MG", "name": "Capitão Andrade"},
      {"name_uf": "MG", "name": "Capitão Enéas"},
      {"name_uf": "MG", "name": "Capitólio"},
      {"name_uf": "MG", "name": "Caputira"},
      {"name_uf": "MG", "name": "Caraí"},
      {"name_uf": "MG", "name": "Caranaíba"},
      {"name_uf": "MG", "name": "Carandaí"},
      {"name_uf": "MG", "name": "Carangola"},
      {"name_uf": "MG", "name": "Caratinga"},
      {"name_uf": "MG", "name": "Carbonita"},
      {"name_uf": "MG", "name": "Careaçu"},
      {"name_uf": "MG", "name": "Carlos Chagas"},
      {"name_uf": "MG", "name": "Carmésia"},
      {"name_uf": "MG", "name": "Carmo da Cachoeira"},
      {"name_uf": "MG", "name": "Carmo da Mata"},
      {"name_uf": "MG", "name": "Carmo de Minas"},
      {"name_uf": "MG", "name": "Carmo do Cajuru"},
      {"name_uf": "MG", "name": "Carmo do Paranaíba"},
      {"name_uf": "MG", "name": "Carmo do Rio Claro"},
      {"name_uf": "MG", "name": "Carmópolis de Minas"},
      {"name_uf": "MG", "name": "Carneirinho"},
      {"name_uf": "MG", "name": "Carrancas"},
      {"name_uf": "MG", "name": "Carvalhópolis"},
      {"name_uf": "MG", "name": "Carvalhos"},
      {"name_uf": "MG", "name": "Casa Grande"},
      {"name_uf": "MG", "name": "Cascalho Rico"},
      {"name_uf": "MG", "name": "Cássia"},
      {"name_uf": "MG", "name": "Cataguases"},
      {"name_uf": "MG", "name": "Catas Altas"},
      {"name_uf": "MG", "name": "Catas Altas da Noruega"},
      {"name_uf": "MG", "name": "Catuji"},
      {"name_uf": "MG", "name": "Catuti"},
      {"name_uf": "MG", "name": "Caxambu"},
      {"name_uf": "MG", "name": "Cedro do Abaeté"},
      {"name_uf": "MG", "name": "Central de Minas"},
      {"name_uf": "MG", "name": "Centralina"},
      {"name_uf": "MG", "name": "Chácara"},
      {"name_uf": "MG", "name": "Chalé"},
      {"name_uf": "MG", "name": "Chapada do Norte"},
      {"name_uf": "MG", "name": "Chapada Gaúcha"},
      {"name_uf": "MG", "name": "Chiador"},
      {"name_uf": "MG", "name": "Cipotânea"},
      {"name_uf": "MG", "name": "Claraval"},
      {"name_uf": "MG", "name": "Claro dos Poções"},
      {"name_uf": "MG", "name": "Cláudio"},
      {"name_uf": "MG", "name": "Coimbra"},
      {"name_uf": "MG", "name": "Coluna"},
      {"name_uf": "MG", "name": "Comendador Gomes"},
      {"name_uf": "MG", "name": "Comercinho"},
      {"name_uf": "MG", "name": "Conceição da Aparecida"},
      {"name_uf": "MG", "name": "Conceição da Barra de Minas"},
      {"name_uf": "MG", "name": "Conceição das AL"},
      {"name_uf": "MG", "name": "Conceição das Pedras"},
      {"name_uf": "MG", "name": "Conceição de Ipanema"},
      {"name_uf": "MG", "name": "Conceição do Mato Dentro"},
      {"name_uf": "MG", "name": "Conceição do PA"},
      {"name_uf": "MG", "name": "Conceição do Rio Verde"},
      {"name_uf": "MG", "name": "Conceição dos Ouros"},
      {"name_uf": "MG", "name": "Cônego Marinho"},
      {"name_uf": "MG", "name": "Confins"},
      {"name_uf": "MG", "name": "Congonhal"},
      {"name_uf": "MG", "name": "Congonhas"},
      {"name_uf": "MG", "name": "Congonhas do Norte"},
      {"name_uf": "MG", "name": "Conquista"},
      {"name_uf": "MG", "name": "Conselheiro Lafaiete"},
      {"name_uf": "MG", "name": "Conselheiro Pena"},
      {"name_uf": "MG", "name": "Consolação"},
      {"name_uf": "MG", "name": "Contagem"},
      {"name_uf": "MG", "name": "Coqueiral"},
      {"name_uf": "MG", "name": "Coração de Jesus"},
      {"name_uf": "MG", "name": "Cordisburgo"},
      {"name_uf": "MG", "name": "Cordislândia"},
      {"name_uf": "MG", "name": "Corinto"},
      {"name_uf": "MG", "name": "Coroaci"},
      {"name_uf": "MG", "name": "Coromandel"},
      {"name_uf": "MG", "name": "Coronel Fabriciano"},
      {"name_uf": "MG", "name": "Coronel Murta"},
      {"name_uf": "MG", "name": "Coronel Pacheco"},
      {"name_uf": "MG", "name": "Coronel Xavier Chaves"},
      {"name_uf": "MG", "name": "Córrego Danta"},
      {"name_uf": "MG", "name": "Córrego do Bom Jesus"},
      {"name_uf": "MG", "name": "Córrego Fundo"},
      {"name_uf": "MG", "name": "Córrego Novo"},
      {"name_uf": "MG", "name": "Couto de Magalhães de Minas"},
      {"name_uf": "MG", "name": "Crisólita"},
      {"name_uf": "MG", "name": "Cristais"},
      {"name_uf": "MG", "name": "Cristália"},
      {"name_uf": "MG", "name": "Cristiano Otoni"},
      {"name_uf": "MG", "name": "Cristina"},
      {"name_uf": "MG", "name": "Crucilândia"},
      {"name_uf": "MG", "name": "Cruzeiro da Fortaleza"},
      {"name_uf": "MG", "name": "Cruzília"},
      {"name_uf": "MG", "name": "Cuparaque"},
      {"name_uf": "MG", "name": "Curral de Dentro"},
      {"name_uf": "MG", "name": "Curvelo"},
      {"name_uf": "MG", "name": "Datas"},
      {"name_uf": "MG", "name": "Delfim Moreira"},
      {"name_uf": "MG", "name": "Delfinópolis"},
      {"name_uf": "MG", "name": "Delta"},
      {"name_uf": "MG", "name": "Descoberto"},
      {"name_uf": "MG", "name": "Desterro de Entre Rios"},
      {"name_uf": "MG", "name": "Desterro do Melo"},
      {"name_uf": "MG", "name": "Diamantina"},
      {"name_uf": "MG", "name": "Diogo de Vasconcelos"},
      {"name_uf": "MG", "name": "Dionísio"},
      {"name_uf": "MG", "name": "Divinésia"},
      {"name_uf": "MG", "name": "Divino"},
      {"name_uf": "MG", "name": "Divino das Laranjeiras"},
      {"name_uf": "MG", "name": "Divinolândia de Minas"},
      {"name_uf": "MG", "name": "Divinópolis"},
      {"name_uf": "MG", "name": "Divisa Alegre"},
      {"name_uf": "MG", "name": "Divisa Nova"},
      {"name_uf": "MG", "name": "Divisópolis"},
      {"name_uf": "MG", "name": "Dom Bosco"},
      {"name_uf": "MG", "name": "Dom Cavati"},
      {"name_uf": "MG", "name": "Dom Joaquim"},
      {"name_uf": "MG", "name": "Dom Silvério"},
      {"name_uf": "MG", "name": "Dom Viçoso"},
      {"name_uf": "MG", "name": "Dona Eusébia"},
      {"name_uf": "MG", "name": "Dores de Campos"},
      {"name_uf": "MG", "name": "Dores de Guanhães"},
      {"name_uf": "MG", "name": "Dores do Indaiá"},
      {"name_uf": "MG", "name": "Dores do Turvo"},
      {"name_uf": "MG", "name": "Doresópolis"},
      {"name_uf": "MG", "name": "Douradoquara"},
      {"name_uf": "MG", "name": "Durandé"},
      {"name_uf": "MG", "name": "Elói Mendes"},
      {"name_uf": "MG", "name": "Engenheiro Caldas"},
      {"name_uf": "MG", "name": "Engenheiro Navarro"},
      {"name_uf": "MG", "name": "Entre Folhas"},
      {"name_uf": "MG", "name": "Entre Rios de Minas"},
      {"name_uf": "MG", "name": "Ervália"},
      {"name_uf": "MG", "name": "Esmeraldas"},
      {"name_uf": "MG", "name": "Espera Feliz"},
      {"name_uf": "MG", "name": "Espinosa"},
      {"name_uf": "MG", "name": "ES do Dourado"},
      {"name_uf": "MG", "name": "Estiva"},
      {"name_uf": "MG", "name": "Estrela Dalva"},
      {"name_uf": "MG", "name": "Estrela do Indaiá"},
      {"name_uf": "MG", "name": "Estrela do Sul"},
      {"name_uf": "MG", "name": "Eugenópolis"},
      {"name_uf": "MG", "name": "Ewbank da Câmara"},
      {"name_uf": "MG", "name": "Extrema"},
      {"name_uf": "MG", "name": "Fama"},
      {"name_uf": "MG", "name": "Faria Lemos"},
      {"name_uf": "MG", "name": "Felício dos Santos"},
      {"name_uf": "MG", "name": "Felisburgo"},
      {"name_uf": "MG", "name": "Felixlândia"},
      {"name_uf": "MG", "name": "Fernandes Tourinho"},
      {"name_uf": "MG", "name": "Ferros"},
      {"name_uf": "MG", "name": "Fervedouro"},
      {"name_uf": "MG", "name": "Florestal"},
      {"name_uf": "MG", "name": "Formiga"},
      {"name_uf": "MG", "name": "Formoso"},
      {"name_uf": "MG", "name": "Fortaleza de Minas"},
      {"name_uf": "MG", "name": "Fortuna de Minas"},
      {"name_uf": "MG", "name": "Francisco Badaró"},
      {"name_uf": "MG", "name": "Francisco Dumont"},
      {"name_uf": "MG", "name": "Francisco Sá"},
      {"name_uf": "MG", "name": "Franciscópolis"},
      {"name_uf": "MG", "name": "Frei Gaspar"},
      {"name_uf": "MG", "name": "Frei Inocêncio"},
      {"name_uf": "MG", "name": "Frei Lagonegro"},
      {"name_uf": "MG", "name": "Fronteira"},
      {"name_uf": "MG", "name": "Fronteira dos Vales"},
      {"name_uf": "MG", "name": "Fruta de Leite"},
      {"name_uf": "MG", "name": "Frutal"},
      {"name_uf": "MG", "name": "Funilândia"},
      {"name_uf": "MG", "name": "Galiléia"},
      {"name_uf": "MG", "name": "Gameleiras"},
      {"name_uf": "MG", "name": "Glaucilândia"},
      {"name_uf": "MG", "name": "Goiabeira"},
      {"name_uf": "MG", "name": "Goianá"},
      {"name_uf": "MG", "name": "Gonçalves"},
      {"name_uf": "MG", "name": "Gonzaga"},
      {"name_uf": "MG", "name": "Gouveia"},
      {"name_uf": "MG", "name": "Governador Valadares"},
      {"name_uf": "MG", "name": "Grão Mogol"},
      {"name_uf": "MG", "name": "Grupiara"},
      {"name_uf": "MG", "name": "Guanhães"},
      {"name_uf": "MG", "name": "Guapé"},
      {"name_uf": "MG", "name": "Guaraciaba"},
      {"name_uf": "MG", "name": "Guaraciama"},
      {"name_uf": "MG", "name": "Guaranésia"},
      {"name_uf": "MG", "name": "Guarani"},
      {"name_uf": "MG", "name": "Guarará"},
      {"name_uf": "MG", "name": "Guarda-Mor"},
      {"name_uf": "MG", "name": "Guaxupé"},
      {"name_uf": "MG", "name": "Guidoval"},
      {"name_uf": "MG", "name": "Guimarânia"},
      {"name_uf": "MG", "name": "Guiricema"},
      {"name_uf": "MG", "name": "Gurinhatã"},
      {"name_uf": "MG", "name": "Heliodora"},
      {"name_uf": "MG", "name": "Iapu"},
      {"name_uf": "MG", "name": "Ibertioga"},
      {"name_uf": "MG", "name": "Ibiá"},
      {"name_uf": "MG", "name": "Ibiaí"},
      {"name_uf": "MG", "name": "Ibiracatu"},
      {"name_uf": "MG", "name": "Ibiraci"},
      {"name_uf": "MG", "name": "Ibirité"},
      {"name_uf": "MG", "name": "Ibitiúra de Minas"},
      {"name_uf": "MG", "name": "Ibituruna"},
      {"name_uf": "MG", "name": "Icaraí de Minas"},
      {"name_uf": "MG", "name": "Igarapé"},
      {"name_uf": "MG", "name": "Igaratinga"},
      {"name_uf": "MG", "name": "Iguatama"},
      {"name_uf": "MG", "name": "Ijaci"},
      {"name_uf": "MG", "name": "Ilicínea"},
      {"name_uf": "MG", "name": "Imbé de Minas"},
      {"name_uf": "MG", "name": "Inconfidentes"},
      {"name_uf": "MG", "name": "Indaiabira"},
      {"name_uf": "MG", "name": "Indianópolis"},
      {"name_uf": "MG", "name": "Ingaí"},
      {"name_uf": "MG", "name": "Inhapim"},
      {"name_uf": "MG", "name": "Inhaúma"},
      {"name_uf": "MG", "name": "Inimutaba"},
      {"name_uf": "MG", "name": "Ipaba"},
      {"name_uf": "MG", "name": "Ipanema"},
      {"name_uf": "MG", "name": "Ipatinga"},
      {"name_uf": "MG", "name": "Ipiaçu"},
      {"name_uf": "MG", "name": "Ipuiúna"},
      {"name_uf": "MG", "name": "Iraí de Minas"},
      {"name_uf": "MG", "name": "Itabira"},
      {"name_uf": "MG", "name": "Itabirinha"},
      {"name_uf": "MG", "name": "Itabirito"},
      {"name_uf": "MG", "name": "Itacambira"},
      {"name_uf": "MG", "name": "Itacarambi"},
      {"name_uf": "MG", "name": "Itaguara"},
      {"name_uf": "MG", "name": "Itaipé"},
      {"name_uf": "MG", "name": "Itajubá"},
      {"name_uf": "MG", "name": "Itamarandiba"},
      {"name_uf": "MG", "name": "Itamarati de Minas"},
      {"name_uf": "MG", "name": "Itambacuri"},
      {"name_uf": "MG", "name": "Itambé do Mato Dentro"},
      {"name_uf": "MG", "name": "Itamogi"},
      {"name_uf": "MG", "name": "Itamonte"},
      {"name_uf": "MG", "name": "Itanhandu"},
      {"name_uf": "MG", "name": "Itanhomi"},
      {"name_uf": "MG", "name": "Itaobim"},
      {"name_uf": "MG", "name": "Itapagipe"},
      {"name_uf": "MG", "name": "Itapecerica"},
      {"name_uf": "MG", "name": "Itapeva"},
      {"name_uf": "MG", "name": "Itatiaiuçu"},
      {"name_uf": "MG", "name": "Itaú de Minas"},
      {"name_uf": "MG", "name": "Itaúna"},
      {"name_uf": "MG", "name": "Itaverava"},
      {"name_uf": "MG", "name": "Itinga"},
      {"name_uf": "MG", "name": "Itueta"},
      {"name_uf": "MG", "name": "Ituiutaba"},
      {"name_uf": "MG", "name": "Itumirim"},
      {"name_uf": "MG", "name": "Iturama"},
      {"name_uf": "MG", "name": "Itutinga"},
      {"name_uf": "MG", "name": "Jaboticatubas"},
      {"name_uf": "MG", "name": "Jacinto"},
      {"name_uf": "MG", "name": "Jacuí"},
      {"name_uf": "MG", "name": "Jacutinga"},
      {"name_uf": "MG", "name": "Jaguaraçu"},
      {"name_uf": "MG", "name": "Jaíba"},
      {"name_uf": "MG", "name": "Jampruca"},
      {"name_uf": "MG", "name": "Janaúba"},
      {"name_uf": "MG", "name": "Januária"},
      {"name_uf": "MG", "name": "JaPB"},
      {"name_uf": "MG", "name": "Japonvar"},
      {"name_uf": "MG", "name": "Jeceaba"},
      {"name_uf": "MG", "name": "Jenipapo de Minas"},
      {"name_uf": "MG", "name": "Jequeri"},
      {"name_uf": "MG", "name": "Jequitaí"},
      {"name_uf": "MG", "name": "Jequitibá"},
      {"name_uf": "MG", "name": "Jequitinhonha"},
      {"name_uf": "MG", "name": "Jesuânia"},
      {"name_uf": "MG", "name": "Joaíma"},
      {"name_uf": "MG", "name": "Joanésia"},
      {"name_uf": "MG", "name": "João Monlevade"},
      {"name_uf": "MG", "name": "João Pinheiro"},
      {"name_uf": "MG", "name": "Joaquim Felício"},
      {"name_uf": "MG", "name": "Jordânia"},
      {"name_uf": "MG", "name": "José Gonçalves de Minas"},
      {"name_uf": "MG", "name": "José Raydan"},
      {"name_uf": "MG", "name": "Josenópolis"},
      {"name_uf": "MG", "name": "Juatuba"},
      {"name_uf": "MG", "name": "Juiz de Fora"},
      {"name_uf": "MG", "name": "Juramento"},
      {"name_uf": "MG", "name": "Juruaia"},
      {"name_uf": "MG", "name": "Juvenília"},
      {"name_uf": "MG", "name": "Ladainha"},
      {"name_uf": "MG", "name": "Lagamar"},
      {"name_uf": "MG", "name": "Lagoa da Prata"},
      {"name_uf": "MG", "name": "Lagoa dos Patos"},
      {"name_uf": "MG", "name": "Lagoa Dourada"},
      {"name_uf": "MG", "name": "Lagoa Formosa"},
      {"name_uf": "MG", "name": "Lagoa Grande"},
      {"name_uf": "MG", "name": "Lagoa Santa"},
      {"name_uf": "MG", "name": "Lajinha"},
      {"name_uf": "MG", "name": "Lambari"},
      {"name_uf": "MG", "name": "Lamim"},
      {"name_uf": "MG", "name": "Laranjal"},
      {"name_uf": "MG", "name": "Lassance"},
      {"name_uf": "MG", "name": "Lavras"},
      {"name_uf": "MG", "name": "Leandro Ferreira"},
      {"name_uf": "MG", "name": "Leme do Prado"},
      {"name_uf": "MG", "name": "Leopoldina"},
      {"name_uf": "MG", "name": "Liberdade"},
      {"name_uf": "MG", "name": "Lima Duarte"},
      {"name_uf": "MG", "name": "Limeira do Oeste"},
      {"name_uf": "MG", "name": "Lontra"},
      {"name_uf": "MG", "name": "Luisburgo"},
      {"name_uf": "MG", "name": "Luislândia"},
      {"name_uf": "MG", "name": "Luminárias"},
      {"name_uf": "MG", "name": "Luz"},
      {"name_uf": "MG", "name": "Machacalis"},
      {"name_uf": "MG", "name": "Machado"},
      {"name_uf": "MG", "name": "Madre de Deus de Minas"},
      {"name_uf": "MG", "name": "Malacacheta"},
      {"name_uf": "MG", "name": "Mamonas"},
      {"name_uf": "MG", "name": "Manga"},
      {"name_uf": "MG", "name": "Manhuaçu"},
      {"name_uf": "MG", "name": "Manhumirim"},
      {"name_uf": "MG", "name": "Mantena"},
      {"name_uf": "MG", "name": "Mar de Espanha"},
      {"name_uf": "MG", "name": "Maravilhas"},
      {"name_uf": "MG", "name": "Maria da Fé"},
      {"name_uf": "MG", "name": "Mariana"},
      {"name_uf": "MG", "name": "Marilac"},
      {"name_uf": "MG", "name": "Mário Campos"},
      {"name_uf": "MG", "name": "Maripá de Minas"},
      {"name_uf": "MG", "name": "Marliéria"},
      {"name_uf": "MG", "name": "Marmelópolis"},
      {"name_uf": "MG", "name": "Martinho Campos"},
      {"name_uf": "MG", "name": "Martins Soares"},
      {"name_uf": "MG", "name": "Mata Verde"},
      {"name_uf": "MG", "name": "Materlândia"},
      {"name_uf": "MG", "name": "Mateus Leme"},
      {"name_uf": "MG", "name": "Mathias Lobato"},
      {"name_uf": "MG", "name": "Matias Barbosa"},
      {"name_uf": "MG", "name": "Matias Cardoso"},
      {"name_uf": "MG", "name": "Matipó"},
      {"name_uf": "MG", "name": "Mato Verde"},
      {"name_uf": "MG", "name": "Matozinhos"},
      {"name_uf": "MG", "name": "Matutina"},
      {"name_uf": "MG", "name": "Medeiros"},
      {"name_uf": "MG", "name": "Medina"},
      {"name_uf": "MG", "name": "Mendes Pimentel"},
      {"name_uf": "MG", "name": "Mercês"},
      {"name_uf": "MG", "name": "Mesquita"},
      {"name_uf": "MG", "name": "Minas Novas"},
      {"name_uf": "MG", "name": "Minduri"},
      {"name_uf": "MG", "name": "Mirabela"},
      {"name_uf": "MG", "name": "Miradouro"},
      {"name_uf": "MG", "name": "Miraí"},
      {"name_uf": "MG", "name": "Miravânia"},
      {"name_uf": "MG", "name": "Moeda"},
      {"name_uf": "MG", "name": "Moema"},
      {"name_uf": "MG", "name": "Monjolos"},
      {"name_uf": "MG", "name": "Monsenhor Paulo"},
      {"name_uf": "MG", "name": "Montalvânia"},
      {"name_uf": "MG", "name": "Monte Alegre de Minas"},
      {"name_uf": "MG", "name": "Monte Azul"},
      {"name_uf": "MG", "name": "Monte Belo"},
      {"name_uf": "MG", "name": "Monte Carmelo"},
      {"name_uf": "MG", "name": "Monte Formoso"},
      {"name_uf": "MG", "name": "Monte Santo de Minas"},
      {"name_uf": "MG", "name": "Monte Sião"},
      {"name_uf": "MG", "name": "Montes Claros"},
      {"name_uf": "MG", "name": "Montezuma"},
      {"name_uf": "MG", "name": "Morada Nova de Minas"},
      {"name_uf": "MG", "name": "Morro da Garça"},
      {"name_uf": "MG", "name": "Morro do Pilar"},
      {"name_uf": "MG", "name": "Munhoz"},
      {"name_uf": "MG", "name": "Muriaé"},
      {"name_uf": "MG", "name": "Mutum"},
      {"name_uf": "MG", "name": "Muzambinho"},
      {"name_uf": "MG", "name": "Nacip Raydan"},
      {"name_uf": "MG", "name": "Nanuque"},
      {"name_uf": "MG", "name": "Naque"},
      {"name_uf": "MG", "name": "Natalândia"},
      {"name_uf": "MG", "name": "Natércia"},
      {"name_uf": "MG", "name": "Nazareno"},
      {"name_uf": "MG", "name": "Nepomuceno"},
      {"name_uf": "MG", "name": "Ninheira"},
      {"name_uf": "MG", "name": "Nova Belém"},
      {"name_uf": "MG", "name": "Nova Era"},
      {"name_uf": "MG", "name": "Nova Lima"},
      {"name_uf": "MG", "name": "Nova Módica"},
      {"name_uf": "MG", "name": "Nova Ponte"},
      {"name_uf": "MG", "name": "Nova Porteirinha"},
      {"name_uf": "MG", "name": "Nova Resende"},
      {"name_uf": "MG", "name": "Nova Serrana"},
      {"name_uf": "MG", "name": "Nova União"},
      {"name_uf": "MG", "name": "Novo Cruzeiro"},
      {"name_uf": "MG", "name": "Novo Oriente de Minas"},
      {"name_uf": "MG", "name": "Novorizonte"},
      {"name_uf": "MG", "name": "Olaria"},
      {"name_uf": "MG", "name": "Olhos-d'Água"},
      {"name_uf": "MG", "name": "Olímpio Noronha"},
      {"name_uf": "MG", "name": "Oliveira"},
      {"name_uf": "MG", "name": "Oliveira Fortes"},
      {"name_uf": "MG", "name": "Onça de Pitangui"},
      {"name_uf": "MG", "name": "Oratórios"},
      {"name_uf": "MG", "name": "Orizânia"},
      {"name_uf": "MG", "name": "Ouro Branco"},
      {"name_uf": "MG", "name": "Ouro Fino"},
      {"name_uf": "MG", "name": "Ouro Preto"},
      {"name_uf": "MG", "name": "Ouro Verde de Minas"},
      {"name_uf": "MG", "name": "Padre Carvalho"},
      {"name_uf": "MG", "name": "Padre Paraíso"},
      {"name_uf": "MG", "name": "Pai Pedro"},
      {"name_uf": "MG", "name": "Paineiras"},
      {"name_uf": "MG", "name": "Pains"},
      {"name_uf": "MG", "name": "Paiva"},
      {"name_uf": "MG", "name": "Palma"},
      {"name_uf": "MG", "name": "Palmópolis"},
      {"name_uf": "MG", "name": "Papagaios"},
      {"name_uf": "MG", "name": "PA de Minas"},
      {"name_uf": "MG", "name": "Paracatu"},
      {"name_uf": "MG", "name": "Paraguaçu"},
      {"name_uf": "MG", "name": "Paraisópolis"},
      {"name_uf": "MG", "name": "Paraopeba"},
      {"name_uf": "MG", "name": "Passa Quatro"},
      {"name_uf": "MG", "name": "Passa Tempo"},
      {"name_uf": "MG", "name": "Passa Vinte"},
      {"name_uf": "MG", "name": "Passabém"},
      {"name_uf": "MG", "name": "Passos"},
      {"name_uf": "MG", "name": "Patis"},
      {"name_uf": "MG", "name": "Patos de Minas"},
      {"name_uf": "MG", "name": "Patrocínio"},
      {"name_uf": "MG", "name": "Patrocínio do Muriaé"},
      {"name_uf": "MG", "name": "Paula Cândido"},
      {"name_uf": "MG", "name": "Paulistas"},
      {"name_uf": "MG", "name": "Pavão"},
      {"name_uf": "MG", "name": "Peçanha"},
      {"name_uf": "MG", "name": "Pedra Azul"},
      {"name_uf": "MG", "name": "Pedra Bonita"},
      {"name_uf": "MG", "name": "Pedra do Anta"},
      {"name_uf": "MG", "name": "Pedra do Indaiá"},
      {"name_uf": "MG", "name": "Pedra Dourada"},
      {"name_uf": "MG", "name": "Pedralva"},
      {"name_uf": "MG", "name": "Pedras de Maria da Cruz"},
      {"name_uf": "MG", "name": "Pedrinópolis"},
      {"name_uf": "MG", "name": "Pedro Leopoldo"},
      {"name_uf": "MG", "name": "Pedro Teixeira"},
      {"name_uf": "MG", "name": "Pequeri"},
      {"name_uf": "MG", "name": "Pequi"},
      {"name_uf": "MG", "name": "Perdigão"},
      {"name_uf": "MG", "name": "Perdizes"},
      {"name_uf": "MG", "name": "Perdões"},
      {"name_uf": "MG", "name": "Periquito"},
      {"name_uf": "MG", "name": "Pescador"},
      {"name_uf": "MG", "name": "Piau"},
      {"name_uf": "MG", "name": "Piedade de Caratinga"},
      {"name_uf": "MG", "name": "Piedade de Ponte Nova"},
      {"name_uf": "MG", "name": "Piedade do Rio Grande"},
      {"name_uf": "MG", "name": "Piedade dos Gerais"},
      {"name_uf": "MG", "name": "Pimenta"},
      {"name_uf": "MG", "name": "Pingo d'Água"},
      {"name_uf": "MG", "name": "Pintópolis"},
      {"name_uf": "MG", "name": "Piracema"},
      {"name_uf": "MG", "name": "Pirajuba"},
      {"name_uf": "MG", "name": "Piranga"},
      {"name_uf": "MG", "name": "Piranguçu"},
      {"name_uf": "MG", "name": "Piranguinho"},
      {"name_uf": "MG", "name": "Pirapetinga"},
      {"name_uf": "MG", "name": "Pirapora"},
      {"name_uf": "MG", "name": "Piraúba"},
      {"name_uf": "MG", "name": "Pitangui"},
      {"name_uf": "MG", "name": "Piumhi"},
      {"name_uf": "MG", "name": "Planura"},
      {"name_uf": "MG", "name": "Poço Fundo"},
      {"name_uf": "MG", "name": "Poços de Caldas"},
      {"name_uf": "MG", "name": "Pocrane"},
      {"name_uf": "MG", "name": "Pompéu"},
      {"name_uf": "MG", "name": "Ponte Nova"},
      {"name_uf": "MG", "name": "Ponto Chique"},
      {"name_uf": "MG", "name": "Ponto dos Volantes"},
      {"name_uf": "MG", "name": "Porteirinha"},
      {"name_uf": "MG", "name": "Porto Firme"},
      {"name_uf": "MG", "name": "Poté"},
      {"name_uf": "MG", "name": "Pouso Alegre"},
      {"name_uf": "MG", "name": "Pouso Alto"},
      {"name_uf": "MG", "name": "Prados"},
      {"name_uf": "MG", "name": "Prata"},
      {"name_uf": "MG", "name": "Pratápolis"},
      {"name_uf": "MG", "name": "Pratinha"},
      {"name_uf": "MG", "name": "Presidente Bernardes"},
      {"name_uf": "MG", "name": "Presidente Juscelino"},
      {"name_uf": "MG", "name": "Presidente Kubitschek"},
      {"name_uf": "MG", "name": "Presidente Olegário"},
      {"name_uf": "MG", "name": "Prudente de Morais"},
      {"name_uf": "MG", "name": "Quartel Geral"},
      {"name_uf": "MG", "name": "Queluzito"},
      {"name_uf": "MG", "name": "Raposos"},
      {"name_uf": "MG", "name": "Raul Soares"},
      {"name_uf": "MG", "name": "Recreio"},
      {"name_uf": "MG", "name": "Reduto"},
      {"name_uf": "MG", "name": "Resende Costa"},
      {"name_uf": "MG", "name": "Resplendor"},
      {"name_uf": "MG", "name": "Ressaquinha"},
      {"name_uf": "MG", "name": "Riachinho"},
      {"name_uf": "MG", "name": "Riacho dos Machados"},
      {"name_uf": "MG", "name": "Ribeirão das Neves"},
      {"name_uf": "MG", "name": "Ribeirão Vermelho"},
      {"name_uf": "MG", "name": "Rio Acima"},
      {"name_uf": "MG", "name": "Rio Casca"},
      {"name_uf": "MG", "name": "Rio do Prado"},
      {"name_uf": "MG", "name": "Rio Doce"},
      {"name_uf": "MG", "name": "Rio Espera"},
      {"name_uf": "MG", "name": "Rio Manso"},
      {"name_uf": "MG", "name": "Rio Novo"},
      {"name_uf": "MG", "name": "Rio Paranaíba"},
      {"name_uf": "MG", "name": "Rio Pardo de Minas"},
      {"name_uf": "MG", "name": "Rio Piracicaba"},
      {"name_uf": "MG", "name": "Rio Pomba"},
      {"name_uf": "MG", "name": "Rio Preto"},
      {"name_uf": "MG", "name": "Rio Vermelho"},
      {"name_uf": "MG", "name": "Ritápolis"},
      {"name_uf": "MG", "name": "Rochedo de Minas"},
      {"name_uf": "MG", "name": "Rodeiro"},
      {"name_uf": "MG", "name": "Romaria"},
      {"name_uf": "MG", "name": "Rosário da Limeira"},
      {"name_uf": "MG", "name": "Rubelita"},
      {"name_uf": "MG", "name": "Rubim"},
      {"name_uf": "MG", "name": "Sabará"},
      {"name_uf": "MG", "name": "Sabinópolis"},
      {"name_uf": "MG", "name": "Sacramento"},
      {"name_uf": "MG", "name": "Salinas"},
      {"name_uf": "MG", "name": "Salto da Divisa"},
      {"name_uf": "MG", "name": "Santa Bárbara"},
      {"name_uf": "MG", "name": "Santa Bárbara do Leste"},
      {"name_uf": "MG", "name": "Santa Bárbara do Monte Verde"},
      {"name_uf": "MG", "name": "Santa Bárbara do Tugúrio"},
      {"name_uf": "MG", "name": "Santa Cruz de Minas"},
      {"name_uf": "MG", "name": "Santa Cruz de Salinas"},
      {"name_uf": "MG", "name": "Santa Cruz do Escalvado"},
      {"name_uf": "MG", "name": "Santa Efigênia de Minas"},
      {"name_uf": "MG", "name": "Santa Fé de Minas"},
      {"name_uf": "MG", "name": "Santa Helena de Minas"},
      {"name_uf": "MG", "name": "Santa Juliana"},
      {"name_uf": "MG", "name": "Santa Luzia"},
      {"name_uf": "MG", "name": "Santa Margarida"},
      {"name_uf": "MG", "name": "Santa Maria de Itabira"},
      {"name_uf": "MG", "name": "Santa Maria do Salto"},
      {"name_uf": "MG", "name": "Santa Maria do Suaçuí"},
      {"name_uf": "MG", "name": "Santa Rita de Caldas"},
      {"name_uf": "MG", "name": "Santa Rita de Ibitipoca"},
      {"name_uf": "MG", "name": "Santa Rita de Jacutinga"},
      {"name_uf": "MG", "name": "Santa Rita de Minas"},
      {"name_uf": "MG", "name": "Santa Rita do Itueto"},
      {"name_uf": "MG", "name": "Santa Rita do Sapucaí"},
      {"name_uf": "MG", "name": "Santa Rosa da Serra"},
      {"name_uf": "MG", "name": "Santa Vitória"},
      {"name_uf": "MG", "name": "Santana da Vargem"},
      {"name_uf": "MG", "name": "Santana de Cataguases"},
      {"name_uf": "MG", "name": "Santana de Pirapama"},
      {"name_uf": "MG", "name": "Santana do Deserto"},
      {"name_uf": "MG", "name": "Santana do Garambéu"},
      {"name_uf": "MG", "name": "Santana do Jacaré"},
      {"name_uf": "MG", "name": "Santana do Manhuaçu"},
      {"name_uf": "MG", "name": "Santana do Paraíso"},
      {"name_uf": "MG", "name": "Santana do Riacho"},
      {"name_uf": "MG", "name": "Santana dos Montes"},
      {"name_uf": "MG", "name": "Santo Antônio do Amparo"},
      {"name_uf": "MG", "name": "Santo Antônio do Aventureiro"},
      {"name_uf": "MG", "name": "Santo Antônio do Grama"},
      {"name_uf": "MG", "name": "Santo Antônio do Itambé"},
      {"name_uf": "MG", "name": "Santo Antônio do Jacinto"},
      {"name_uf": "MG", "name": "Santo Antônio do Monte"},
      {"name_uf": "MG", "name": "Santo Antônio do Retiro"},
      {"name_uf": "MG", "name": "Santo Antônio do Rio Abaixo"},
      {"name_uf": "MG", "name": "Santo Hipólito"},
      {"name_uf": "MG", "name": "Santos Dumont"},
      {"name_uf": "MG", "name": "São Bento Abade"},
      {"name_uf": "MG", "name": "São Brás do Suaçuí"},
      {"name_uf": "MG", "name": "São Domingos das Dores"},
      {"name_uf": "MG", "name": "São Domingos do Prata"},
      {"name_uf": "MG", "name": "São Félix de Minas"},
      {"name_uf": "MG", "name": "São Francisco"},
      {"name_uf": "MG", "name": "São Francisco de Paula"},
      {"name_uf": "MG", "name": "São Francisco de Sales"},
      {"name_uf": "MG", "name": "São Francisco do Glória"},
      {"name_uf": "MG", "name": "São Geraldo"},
      {"name_uf": "MG", "name": "São Geraldo da Piedade"},
      {"name_uf": "MG", "name": "São Geraldo do Baixio"},
      {"name_uf": "MG", "name": "São Gonçalo do Abaeté"},
      {"name_uf": "MG", "name": "São Gonçalo do PA"},
      {"name_uf": "MG", "name": "São Gonçalo do Rio Abaixo"},
      {"name_uf": "MG", "name": "São Gonçalo do Rio Preto"},
      {"name_uf": "MG", "name": "São Gonçalo do Sapucaí"},
      {"name_uf": "MG", "name": "São Gotardo"},
      {"name_uf": "MG", "name": "São João Batista do Glória"},
      {"name_uf": "MG", "name": "São João da Lagoa"},
      {"name_uf": "MG", "name": "São João da Mata"},
      {"name_uf": "MG", "name": "São João da Ponte"},
      {"name_uf": "MG", "name": "São João das Missões"},
      {"name_uf": "MG", "name": "São João del Rei"},
      {"name_uf": "MG", "name": "São João do Manhuaçu"},
      {"name_uf": "MG", "name": "São João do Manteninha"},
      {"name_uf": "MG", "name": "São João do Oriente"},
      {"name_uf": "MG", "name": "São João do Pacuí"},
      {"name_uf": "MG", "name": "São João do Paraíso"},
      {"name_uf": "MG", "name": "São João Evangelista"},
      {"name_uf": "MG", "name": "São João Nepomuceno"},
      {"name_uf": "MG", "name": "São Joaquim de Bicas"},
      {"name_uf": "MG", "name": "São José da Barra"},
      {"name_uf": "MG", "name": "São José da Lapa"},
      {"name_uf": "MG", "name": "São José da Safira"},
      {"name_uf": "MG", "name": "São José da Varginha"},
      {"name_uf": "MG", "name": "São José do Alegre"},
      {"name_uf": "MG", "name": "São José do Divino"},
      {"name_uf": "MG", "name": "São José do Goiabal"},
      {"name_uf": "MG", "name": "São José do Jacuri"},
      {"name_uf": "MG", "name": "São José do Mantimento"},
      {"name_uf": "MG", "name": "São Lourenço"},
      {"name_uf": "MG", "name": "São Miguel do Anta"},
      {"name_uf": "MG", "name": "São Pedro da União"},
      {"name_uf": "MG", "name": "São Pedro do Suaçuí"},
      {"name_uf": "MG", "name": "São Pedro dos Ferros"},
      {"name_uf": "MG", "name": "São Romão"},
      {"name_uf": "MG", "name": "São Roque de Minas"},
      {"name_uf": "MG", "name": "São Sebastião da Bela Vista"},
      {"name_uf": "MG", "name": "São Sebastião da Vargem Alegre"},
      {"name_uf": "MG", "name": "São Sebastião do Anta"},
      {"name_uf": "MG", "name": "São Sebastião do MA"},
      {"name_uf": "MG", "name": "São Sebastião do Oeste"},
      {"name_uf": "MG", "name": "São Sebastião do Paraíso"},
      {"name_uf": "MG", "name": "São Sebastião do Rio Preto"},
      {"name_uf": "MG", "name": "São Sebastião do Rio Verde"},
      {"name_uf": "MG", "name": "São Thomé das Letras"},
      {"name_uf": "MG", "name": "São Tiago"},
      {"name_uf": "MG", "name": "São Tomás de Aquino"},
      {"name_uf": "MG", "name": "São Vicente de Minas"},
      {"name_uf": "MG", "name": "Sapucaí-Mirim"},
      {"name_uf": "MG", "name": "Sardoá"},
      {"name_uf": "MG", "name": "Sarzedo"},
      {"name_uf": "MG", "name": "Sem-Peixe"},
      {"name_uf": "MG", "name": "Senador Amaral"},
      {"name_uf": "MG", "name": "Senador Cortes"},
      {"name_uf": "MG", "name": "Senador Firmino"},
      {"name_uf": "MG", "name": "Senador José Bento"},
      {"name_uf": "MG", "name": "Senador Modestino Gonçalves"},
      {"name_uf": "MG", "name": "Senhora de Oliveira"},
      {"name_uf": "MG", "name": "Senhora do Porto"},
      {"name_uf": "MG", "name": "Senhora dos Remédios"},
      {"name_uf": "MG", "name": "Sericita"},
      {"name_uf": "MG", "name": "Seritinga"},
      {"name_uf": "MG", "name": "Serra Azul de Minas"},
      {"name_uf": "MG", "name": "Serra da Saudade"},
      {"name_uf": "MG", "name": "Serra do Salitre"},
      {"name_uf": "MG", "name": "Serra dos Aimorés"},
      {"name_uf": "MG", "name": "Serrania"},
      {"name_uf": "MG", "name": "Serranópolis de Minas"},
      {"name_uf": "MG", "name": "Serranos"},
      {"name_uf": "MG", "name": "Serro"},
      {"name_uf": "MG", "name": "Sete Lagoas"},
      {"name_uf": "MG", "name": "Setubinha"},
      {"name_uf": "MG", "name": "Silveirânia"},
      {"name_uf": "MG", "name": "Silvianópolis"},
      {"name_uf": "MG", "name": "Simão Pereira"},
      {"name_uf": "MG", "name": "Simonésia"},
      {"name_uf": "MG", "name": "Sobrália"},
      {"name_uf": "MG", "name": "Soledade de Minas"},
      {"name_uf": "MG", "name": "Tabuleiro"},
      {"name_uf": "MG", "name": "Taiobeiras"},
      {"name_uf": "MG", "name": "Taparuba"},
      {"name_uf": "MG", "name": "Tapira"},
      {"name_uf": "MG", "name": "Tapiraí"},
      {"name_uf": "MG", "name": "Taquaraçu de Minas"},
      {"name_uf": "MG", "name": "Tarumirim"},
      {"name_uf": "MG", "name": "Teixeiras"},
      {"name_uf": "MG", "name": "Teófilo Otoni"},
      {"name_uf": "MG", "name": "Timóteo"},
      {"name_uf": "MG", "name": "Tiradentes"},
      {"name_uf": "MG", "name": "Tiros"},
      {"name_uf": "MG", "name": "TO"},
      {"name_uf": "MG", "name": "Tocos do Moji"},
      {"name_uf": "MG", "name": "Toledo"},
      {"name_uf": "MG", "name": "Tombos"},
      {"name_uf": "MG", "name": "Três Corações"},
      {"name_uf": "MG", "name": "Três Marias"},
      {"name_uf": "MG", "name": "Três Pontas"},
      {"name_uf": "MG", "name": "Tumiritinga"},
      {"name_uf": "MG", "name": "Tupaciguara"},
      {"name_uf": "MG", "name": "Turmalina"},
      {"name_uf": "MG", "name": "Turvolândia"},
      {"name_uf": "MG", "name": "Ubá"},
      {"name_uf": "MG", "name": "Ubaí"},
      {"name_uf": "MG", "name": "Ubaporanga"},
      {"name_uf": "MG", "name": "Uberaba"},
      {"name_uf": "MG", "name": "Uberlândia"},
      {"name_uf": "MG", "name": "Umburatiba"},
      {"name_uf": "MG", "name": "Unaí"},
      {"name_uf": "MG", "name": "União de Minas"},
      {"name_uf": "MG", "name": "Uruana de Minas"},
      {"name_uf": "MG", "name": "Urucânia"},
      {"name_uf": "MG", "name": "Urucuia"},
      {"name_uf": "MG", "name": "Vargem Alegre"},
      {"name_uf": "MG", "name": "Vargem Bonita"},
      {"name_uf": "MG", "name": "Vargem Grande do Rio Pardo"},
      {"name_uf": "MG", "name": "Varginha"},
      {"name_uf": "MG", "name": "Varjão de Minas"},
      {"name_uf": "MG", "name": "Várzea da Palma"},
      {"name_uf": "MG", "name": "Varzelândia"},
      {"name_uf": "MG", "name": "Vazante"},
      {"name_uf": "MG", "name": "Verdelândia"},
      {"name_uf": "MG", "name": "Veredinha"},
      {"name_uf": "MG", "name": "Veríssimo"},
      {"name_uf": "MG", "name": "Vermelho Novo"},
      {"name_uf": "MG", "name": "Vespasiano"},
      {"name_uf": "MG", "name": "Viçosa"},
      {"name_uf": "MG", "name": "Vieiras"},
      {"name_uf": "MG", "name": "Virgem da Lapa"},
      {"name_uf": "MG", "name": "Virgínia"},
      {"name_uf": "MG", "name": "Virginópolis"},
      {"name_uf": "MG", "name": "Virgolândia"},
      {"name_uf": "MG", "name": "Visconde do Rio Branco"},
      {"name_uf": "MG", "name": "Volta Grande"},
      {"name_uf": "MG", "name": "Wenceslau Braz"},
      {"name_uf": "ES", "name": "Afonso Cláudio"},
      {"name_uf": "ES", "name": "Água Doce do Norte"},
      {"name_uf": "ES", "name": "Águia Branca"},
      {"name_uf": "ES", "name": "Alegre"},
      {"name_uf": "ES", "name": "Alfredo Chaves"},
      {"name_uf": "ES", "name": "Alto Rio Novo"},
      {"name_uf": "ES", "name": "Anchieta"},
      {"name_uf": "ES", "name": "Apiacá"},
      {"name_uf": "ES", "name": "Aracruz"},
      {"name_uf": "ES", "name": "Atílio Vivacqua"},
      {"name_uf": "ES", "name": "Baixo Guandu"},
      {"name_uf": "ES", "name": "Barra de São Francisco"},
      {"name_uf": "ES", "name": "Boa Esperança"},
      {"name_uf": "ES", "name": "Bom Jesus do Norte"},
      {"name_uf": "ES", "name": "Brejetuba"},
      {"name_uf": "ES", "name": "Cachoeiro de Itapemirim"},
      {"name_uf": "ES", "name": "Cariacica"},
      {"name_uf": "ES", "name": "Castelo"},
      {"name_uf": "ES", "name": "Colatina"},
      {"name_uf": "ES", "name": "Conceição da Barra"},
      {"name_uf": "ES", "name": "Conceição do Castelo"},
      {"name_uf": "ES", "name": "Divino de São Lourenço"},
      {"name_uf": "ES", "name": "Domingos Martins"},
      {"name_uf": "ES", "name": "Dores do Rio Preto"},
      {"name_uf": "ES", "name": "Ecoporanga"},
      {"name_uf": "ES", "name": "Fundão"},
      {"name_uf": "ES", "name": "Governador Lindenberg"},
      {"name_uf": "ES", "name": "Guaçuí"},
      {"name_uf": "ES", "name": "Guarapari"},
      {"name_uf": "ES", "name": "Ibatiba"},
      {"name_uf": "ES", "name": "Ibiraçu"},
      {"name_uf": "ES", "name": "Ibitirama"},
      {"name_uf": "ES", "name": "Iconha"},
      {"name_uf": "ES", "name": "Irupi"},
      {"name_uf": "ES", "name": "Itaguaçu"},
      {"name_uf": "ES", "name": "Itapemirim"},
      {"name_uf": "ES", "name": "Itarana"},
      {"name_uf": "ES", "name": "Iúna"},
      {"name_uf": "ES", "name": "Jaguaré"},
      {"name_uf": "ES", "name": "Jerônimo Monteiro"},
      {"name_uf": "ES", "name": "João Neiva"},
      {"name_uf": "ES", "name": "Laranja da Terra"},
      {"name_uf": "ES", "name": "Linhares"},
      {"name_uf": "ES", "name": "Mantenópolis"},
      {"name_uf": "ES", "name": "Marataízes"},
      {"name_uf": "ES", "name": "Marechal Floriano"},
      {"name_uf": "ES", "name": "Marilândia"},
      {"name_uf": "ES", "name": "Mimoso do Sul"},
      {"name_uf": "ES", "name": "Montanha"},
      {"name_uf": "ES", "name": "Mucurici"},
      {"name_uf": "ES", "name": "Muniz Freire"},
      {"name_uf": "ES", "name": "Muqui"},
      {"name_uf": "ES", "name": "Nova Venécia"},
      {"name_uf": "ES", "name": "Pancas"},
      {"name_uf": "ES", "name": "Pedro Canário"},
      {"name_uf": "ES", "name": "Pinheiros"},
      {"name_uf": "ES", "name": "Piúma"},
      {"name_uf": "ES", "name": "Ponto Belo"},
      {"name_uf": "ES", "name": "Presidente Kennedy"},
      {"name_uf": "ES", "name": "Rio Bananal"},
      {"name_uf": "ES", "name": "Rio Novo do Sul"},
      {"name_uf": "ES", "name": "Santa Leopoldina"},
      {"name_uf": "ES", "name": "Santa Maria de Jetibá"},
      {"name_uf": "ES", "name": "Santa Teresa"},
      {"name_uf": "ES", "name": "São Domingos do Norte"},
      {"name_uf": "ES", "name": "São Gabriel da Palha"},
      {"name_uf": "ES", "name": "São José do Calçado"},
      {"name_uf": "ES", "name": "São Mateus"},
      {"name_uf": "ES", "name": "São Roque do Canaã"},
      {"name_uf": "ES", "name": "Serra"},
      {"name_uf": "ES", "name": "Sooretama"},
      {"name_uf": "ES", "name": "Vargem Alta"},
      {"name_uf": "ES", "name": "Venda Nova do Imigrante"},
      {"name_uf": "ES", "name": "Viana"},
      {"name_uf": "ES", "name": "Vila Pavão"},
      {"name_uf": "ES", "name": "Vila Valério"},
      {"name_uf": "ES", "name": "Vila Velha"},
      {"name_uf": "ES", "name": "Vitória"},
      {"name_uf": "RJ", "name": "Angra dos Reis"},
      {"name_uf": "RJ", "name": "Aperibé"},
      {"name_uf": "RJ", "name": "Araruama"},
      {"name_uf": "RJ", "name": "Areal"},
      {"name_uf": "RJ", "name": "Armação dos Búzios"},
      {"name_uf": "RJ", "name": "Arraial do Cabo"},
      {"name_uf": "RJ", "name": "Barra do Piraí"},
      {"name_uf": "RJ", "name": "Barra Mansa"},
      {"name_uf": "RJ", "name": "Belford Roxo"},
      {"name_uf": "RJ", "name": "Bom Jardim"},
      {"name_uf": "RJ", "name": "Bom Jesus do Itabapoana"},
      {"name_uf": "RJ", "name": "Cabo Frio"},
      {"name_uf": "RJ", "name": "Cachoeiras de Macacu"},
      {"name_uf": "RJ", "name": "Cambuci"},
      {"name_uf": "RJ", "name": "Campos dos Goytacazes"},
      {"name_uf": "RJ", "name": "Cantagalo"},
      {"name_uf": "RJ", "name": "Carapebus"},
      {"name_uf": "RJ", "name": "Cardoso Moreira"},
      {"name_uf": "RJ", "name": "Carmo"},
      {"name_uf": "RJ", "name": "Casimiro de Abreu"},
      {"name_uf": "RJ", "name": "Comendador Levy Gasparian"},
      {"name_uf": "RJ", "name": "Conceição de Macabu"},
      {"name_uf": "RJ", "name": "Cordeiro"},
      {"name_uf": "RJ", "name": "Duas Barras"},
      {"name_uf": "RJ", "name": "Duque de Caxias"},
      {"name_uf": "RJ", "name": "Engenheiro Paulo de Frontin"},
      {"name_uf": "RJ", "name": "Guapimirim"},
      {"name_uf": "RJ", "name": "Iguaba Grande"},
      {"name_uf": "RJ", "name": "Itaboraí"},
      {"name_uf": "RJ", "name": "Itaguaí"},
      {"name_uf": "RJ", "name": "Italva"},
      {"name_uf": "RJ", "name": "Itaocara"},
      {"name_uf": "RJ", "name": "Itaperuna"},
      {"name_uf": "RJ", "name": "Itatiaia"},
      {"name_uf": "RJ", "name": "Japeri"},
      {"name_uf": "RJ", "name": "Laje do Muriaé"},
      {"name_uf": "RJ", "name": "Macaé"},
      {"name_uf": "RJ", "name": "Macuco"},
      {"name_uf": "RJ", "name": "Magé"},
      {"name_uf": "RJ", "name": "Mangaratiba"},
      {"name_uf": "RJ", "name": "Maricá"},
      {"name_uf": "RJ", "name": "Mendes"},
      {"name_uf": "RJ", "name": "Mesquita"},
      {"name_uf": "RJ", "name": "Miguel Pereira"},
      {"name_uf": "RJ", "name": "Miracema"},
      {"name_uf": "RJ", "name": "Natividade"},
      {"name_uf": "RJ", "name": "Nilópolis"},
      {"name_uf": "RJ", "name": "Niterói"},
      {"name_uf": "RJ", "name": "Nova Friburgo"},
      {"name_uf": "RJ", "name": "Nova Iguaçu"},
      {"name_uf": "RJ", "name": "Paracambi"},
      {"name_uf": "RJ", "name": "PB do Sul"},
      {"name_uf": "RJ", "name": "Parati"},
      {"name_uf": "RJ", "name": "Paty do Alferes"},
      {"name_uf": "RJ", "name": "Petrópolis"},
      {"name_uf": "RJ", "name": "Pinheiral"},
      {"name_uf": "RJ", "name": "Piraí"},
      {"name_uf": "RJ", "name": "Porciúncula"},
      {"name_uf": "RJ", "name": "Porto Real"},
      {"name_uf": "RJ", "name": "Quatis"},
      {"name_uf": "RJ", "name": "Queimados"},
      {"name_uf": "RJ", "name": "Quissamã"},
      {"name_uf": "RJ", "name": "Resende"},
      {"name_uf": "RJ", "name": "Rio Bonito"},
      {"name_uf": "RJ", "name": "Rio Claro"},
      {"name_uf": "RJ", "name": "Rio das Flores"},
      {"name_uf": "RJ", "name": "Rio das Ostras"},
      {"name_uf": "RJ", "name": "RJ"},
      {"name_uf": "RJ", "name": "Santa Maria Madalena"},
      {"name_uf": "RJ", "name": "Santo Antônio de Pádua"},
      {"name_uf": "RJ", "name": "São Fidélis"},
      {"name_uf": "RJ", "name": "São Francisco de Itabapoana"},
      {"name_uf": "RJ", "name": "São Gonçalo"},
      {"name_uf": "RJ", "name": "São João da Barra"},
      {"name_uf": "RJ", "name": "São João de Meriti"},
      {"name_uf": "RJ", "name": "São José de Ubá"},
      {"name_uf": "RJ", "name": "São José do Vale do Rio Preto"},
      {"name_uf": "RJ", "name": "São Pedro da Aldeia"},
      {"name_uf": "RJ", "name": "São Sebastião do Alto"},
      {"name_uf": "RJ", "name": "Sapucaia"},
      {"name_uf": "RJ", "name": "Saquarema"},
      {"name_uf": "RJ", "name": "Seropédica"},
      {"name_uf": "RJ", "name": "Silva Jardim"},
      {"name_uf": "RJ", "name": "Sumidouro"},
      {"name_uf": "RJ", "name": "Tanguá"},
      {"name_uf": "RJ", "name": "Teresópolis"},
      {"name_uf": "RJ", "name": "Trajano de Moraes"},
      {"name_uf": "RJ", "name": "Três Rios"},
      {"name_uf": "RJ", "name": "Valença"},
      {"name_uf": "RJ", "name": "Varre-Sai"},
      {"name_uf": "RJ", "name": "Vassouras"},
      {"name_uf": "RJ", "name": "Volta Redonda"},
      {"name_uf": "SP", "name": "Adamantina"},
      {"name_uf": "SP", "name": "Adolfo"},
      {"name_uf": "SP", "name": "Aguaí"},
      {"name_uf": "SP", "name": "Águas da Prata"},
      {"name_uf": "SP", "name": "Águas de Lindóia"},
      {"name_uf": "SP", "name": "Águas de Santa Bárbara"},
      {"name_uf": "SP", "name": "Águas de São Pedro"},
      {"name_uf": "SP", "name": "Agudos"},
      {"name_uf": "SP", "name": "Alambari"},
      {"name_uf": "SP", "name": "Alfredo Marcondes"},
      {"name_uf": "SP", "name": "Altair"},
      {"name_uf": "SP", "name": "Altinópolis"},
      {"name_uf": "SP", "name": "Alto Alegre"},
      {"name_uf": "SP", "name": "Alumínio"},
      {"name_uf": "SP", "name": "Álvares Florence"},
      {"name_uf": "SP", "name": "Álvares Machado"},
      {"name_uf": "SP", "name": "Álvaro de Carvalho"},
      {"name_uf": "SP", "name": "Alvinlândia"},
      {"name_uf": "SP", "name": "Americana"},
      {"name_uf": "SP", "name": "Américo Brasiliense"},
      {"name_uf": "SP", "name": "Américo de Campos"},
      {"name_uf": "SP", "name": "Amparo"},
      {"name_uf": "SP", "name": "Analândia"},
      {"name_uf": "SP", "name": "Andradina"},
      {"name_uf": "SP", "name": "Angatuba"},
      {"name_uf": "SP", "name": "Anhembi"},
      {"name_uf": "SP", "name": "Anhumas"},
      {"name_uf": "SP", "name": "Aparecida"},
      {"name_uf": "SP", "name": "Aparecida d'Oeste"},
      {"name_uf": "SP", "name": "Apiaí"},
      {"name_uf": "SP", "name": "Araçariguama"},
      {"name_uf": "SP", "name": "Araçatuba"},
      {"name_uf": "SP", "name": "Araçoiaba da Serra"},
      {"name_uf": "SP", "name": "Aramina"},
      {"name_uf": "SP", "name": "Arandu"},
      {"name_uf": "SP", "name": "Arapeí"},
      {"name_uf": "SP", "name": "Araraquara"},
      {"name_uf": "SP", "name": "Araras"},
      {"name_uf": "SP", "name": "Arco-Íris"},
      {"name_uf": "SP", "name": "Arealva"},
      {"name_uf": "SP", "name": "Areias"},
      {"name_uf": "SP", "name": "Areiópolis"},
      {"name_uf": "SP", "name": "Ariranha"},
      {"name_uf": "SP", "name": "Artur Nogueira"},
      {"name_uf": "SP", "name": "Arujá"},
      {"name_uf": "SP", "name": "Aspásia"},
      {"name_uf": "SP", "name": "Assis"},
      {"name_uf": "SP", "name": "Atibaia"},
      {"name_uf": "SP", "name": "Auriflama"},
      {"name_uf": "SP", "name": "Avaí"},
      {"name_uf": "SP", "name": "Avanhandava"},
      {"name_uf": "SP", "name": "Avaré"},
      {"name_uf": "SP", "name": "Bady Bassitt"},
      {"name_uf": "SP", "name": "Balbinos"},
      {"name_uf": "SP", "name": "Bálsamo"},
      {"name_uf": "SP", "name": "Bananal"},
      {"name_uf": "SP", "name": "Barão de Antonina"},
      {"name_uf": "SP", "name": "Barbosa"},
      {"name_uf": "SP", "name": "Bariri"},
      {"name_uf": "SP", "name": "Barra Bonita"},
      {"name_uf": "SP", "name": "Barra do Chapéu"},
      {"name_uf": "SP", "name": "Barra do Turvo"},
      {"name_uf": "SP", "name": "Barretos"},
      {"name_uf": "SP", "name": "Barrinha"},
      {"name_uf": "SP", "name": "Barueri"},
      {"name_uf": "SP", "name": "Bastos"},
      {"name_uf": "SP", "name": "Batatais"},
      {"name_uf": "SP", "name": "Bauru"},
      {"name_uf": "SP", "name": "Bebedouro"},
      {"name_uf": "SP", "name": "Bento de Abreu"},
      {"name_uf": "SP", "name": "Bernardino de Campos"},
      {"name_uf": "SP", "name": "Bertioga"},
      {"name_uf": "SP", "name": "Bilac"},
      {"name_uf": "SP", "name": "Birigui"},
      {"name_uf": "SP", "name": "Biritiba Mirim"},
      {"name_uf": "SP", "name": "Boa Esperança do Sul"},
      {"name_uf": "SP", "name": "Bocaina"},
      {"name_uf": "SP", "name": "Bofete"},
      {"name_uf": "SP", "name": "Boituva"},
      {"name_uf": "SP", "name": "Bom Jesus dos Perdões"},
      {"name_uf": "SP", "name": "Bom Sucesso de Itararé"},
      {"name_uf": "SP", "name": "Borá"},
      {"name_uf": "SP", "name": "Boracéia"},
      {"name_uf": "SP", "name": "Borborema"},
      {"name_uf": "SP", "name": "Borebi"},
      {"name_uf": "SP", "name": "Botucatu"},
      {"name_uf": "SP", "name": "Bragança Paulista"},
      {"name_uf": "SP", "name": "Braúna"},
      {"name_uf": "SP", "name": "Brejo Alegre"},
      {"name_uf": "SP", "name": "Brodowski"},
      {"name_uf": "SP", "name": "Brotas"},
      {"name_uf": "SP", "name": "Buri"},
      {"name_uf": "SP", "name": "Buritama"},
      {"name_uf": "SP", "name": "Buritizal"},
      {"name_uf": "SP", "name": "Cabrália Paulista"},
      {"name_uf": "SP", "name": "Cabreúva"},
      {"name_uf": "SP", "name": "Caçapava"},
      {"name_uf": "SP", "name": "Cachoeira Paulista"},
      {"name_uf": "SP", "name": "Caconde"},
      {"name_uf": "SP", "name": "Cafelândia"},
      {"name_uf": "SP", "name": "Caiabu"},
      {"name_uf": "SP", "name": "Caieiras"},
      {"name_uf": "SP", "name": "Caiuá"},
      {"name_uf": "SP", "name": "Cajamar"},
      {"name_uf": "SP", "name": "Cajati"},
      {"name_uf": "SP", "name": "Cajobi"},
      {"name_uf": "SP", "name": "Cajuru"},
      {"name_uf": "SP", "name": "Campina do Monte Alegre"},
      {"name_uf": "SP", "name": "Campinas"},
      {"name_uf": "SP", "name": "Campo Limpo Paulista"},
      {"name_uf": "SP", "name": "Campos do Jordão"},
      {"name_uf": "SP", "name": "Campos Novos Paulista"},
      {"name_uf": "SP", "name": "Cananéia"},
      {"name_uf": "SP", "name": "Canas"},
      {"name_uf": "SP", "name": "Cândido Mota"},
      {"name_uf": "SP", "name": "Cândido Rodrigues"},
      {"name_uf": "SP", "name": "Canitar"},
      {"name_uf": "SP", "name": "Capão Bonito"},
      {"name_uf": "SP", "name": "Capela do Alto"},
      {"name_uf": "SP", "name": "Capivari"},
      {"name_uf": "SP", "name": "Caraguatatuba"},
      {"name_uf": "SP", "name": "Carapicuíba"},
      {"name_uf": "SP", "name": "Cardoso"},
      {"name_uf": "SP", "name": "Casa Branca"},
      {"name_uf": "SP", "name": "Cássia dos Coqueiros"},
      {"name_uf": "SP", "name": "Castilho"},
      {"name_uf": "SP", "name": "Catanduva"},
      {"name_uf": "SP", "name": "Catiguá"},
      {"name_uf": "SP", "name": "Cedral"},
      {"name_uf": "SP", "name": "Cerqueira César"},
      {"name_uf": "SP", "name": "Cerquilho"},
      {"name_uf": "SP", "name": "Cesário Lange"},
      {"name_uf": "SP", "name": "Charqueada"},
      {"name_uf": "SP", "name": "Chavantes"},
      {"name_uf": "SP", "name": "Clementina"},
      {"name_uf": "SP", "name": "Colina"},
      {"name_uf": "SP", "name": "Colômbia"},
      {"name_uf": "SP", "name": "Conchal"},
      {"name_uf": "SP", "name": "Conchas"},
      {"name_uf": "SP", "name": "Cordeirópolis"},
      {"name_uf": "SP", "name": "Coroados"},
      {"name_uf": "SP", "name": "Coronel Macedo"},
      {"name_uf": "SP", "name": "Corumbataí"},
      {"name_uf": "SP", "name": "Cosmópolis"},
      {"name_uf": "SP", "name": "Cosmorama"},
      {"name_uf": "SP", "name": "Cotia"},
      {"name_uf": "SP", "name": "Cravinhos"},
      {"name_uf": "SP", "name": "Cristais Paulista"},
      {"name_uf": "SP", "name": "Cruzália"},
      {"name_uf": "SP", "name": "Cruzeiro"},
      {"name_uf": "SP", "name": "Cubatão"},
      {"name_uf": "SP", "name": "Cunha"},
      {"name_uf": "SP", "name": "Descalvado"},
      {"name_uf": "SP", "name": "Diadema"},
      {"name_uf": "SP", "name": "Dirce Reis"},
      {"name_uf": "SP", "name": "Divinolândia"},
      {"name_uf": "SP", "name": "Dobrada"},
      {"name_uf": "SP", "name": "Dois Córregos"},
      {"name_uf": "SP", "name": "Dolcinópolis"},
      {"name_uf": "SP", "name": "Dourado"},
      {"name_uf": "SP", "name": "Dracena"},
      {"name_uf": "SP", "name": "Duartina"},
      {"name_uf": "SP", "name": "Dumont"},
      {"name_uf": "SP", "name": "Echaporã"},
      {"name_uf": "SP", "name": "Eldorado"},
      {"name_uf": "SP", "name": "Elias Fausto"},
      {"name_uf": "SP", "name": "Elisiário"},
      {"name_uf": "SP", "name": "Embaúba"},
      {"name_uf": "SP", "name": "Embu das Artes"},
      {"name_uf": "SP", "name": "Embu-Guaçu"},
      {"name_uf": "SP", "name": "Emilianópolis"},
      {"name_uf": "SP", "name": "Engenheiro Coelho"},
      {"name_uf": "SP", "name": "ES do Pinhal"},
      {"name_uf": "SP", "name": "ES do Turvo"},
      {"name_uf": "SP", "name": "Estiva Gerbi"},
      {"name_uf": "SP", "name": "Estrela do Norte"},
      {"name_uf": "SP", "name": "Estrela d'Oeste"},
      {"name_uf": "SP", "name": "Euclides da Cunha Paulista"},
      {"name_uf": "SP", "name": "Fartura"},
      {"name_uf": "SP", "name": "Fernando Prestes"},
      {"name_uf": "SP", "name": "Fernandópolis"},
      {"name_uf": "SP", "name": "Fernão"},
      {"name_uf": "SP", "name": "Ferraz de Vasconcelos"},
      {"name_uf": "SP", "name": "Flora Rica"},
      {"name_uf": "SP", "name": "Floreal"},
      {"name_uf": "SP", "name": "Flórida Paulista"},
      {"name_uf": "SP", "name": "Florínea"},
      {"name_uf": "SP", "name": "Franca"},
      {"name_uf": "SP", "name": "Francisco Morato"},
      {"name_uf": "SP", "name": "Franco da Rocha"},
      {"name_uf": "SP", "name": "Gabriel Monteiro"},
      {"name_uf": "SP", "name": "Gália"},
      {"name_uf": "SP", "name": "Garça"},
      {"name_uf": "SP", "name": "Gastão Vidigal"},
      {"name_uf": "SP", "name": "Gavião Peixoto"},
      {"name_uf": "SP", "name": "General Salgado"},
      {"name_uf": "SP", "name": "Getulina"},
      {"name_uf": "SP", "name": "Glicério"},
      {"name_uf": "SP", "name": "Guaiçara"},
      {"name_uf": "SP", "name": "Guaimbê"},
      {"name_uf": "SP", "name": "Guaíra"},
      {"name_uf": "SP", "name": "Guapiaçu"},
      {"name_uf": "SP", "name": "Guapiara"},
      {"name_uf": "SP", "name": "Guará"},
      {"name_uf": "SP", "name": "Guaraçaí"},
      {"name_uf": "SP", "name": "Guaraci"},
      {"name_uf": "SP", "name": "Guarani d'Oeste"},
      {"name_uf": "SP", "name": "Guarantã"},
      {"name_uf": "SP", "name": "Guararapes"},
      {"name_uf": "SP", "name": "Guararema"},
      {"name_uf": "SP", "name": "Guaratinguetá"},
      {"name_uf": "SP", "name": "Guareí"},
      {"name_uf": "SP", "name": "Guariba"},
      {"name_uf": "SP", "name": "Guarujá"},
      {"name_uf": "SP", "name": "Guarulhos"},
      {"name_uf": "SP", "name": "GuataPA"},
      {"name_uf": "SP", "name": "Guzolândia"},
      {"name_uf": "SP", "name": "Herculândia"},
      {"name_uf": "SP", "name": "Holambra"},
      {"name_uf": "SP", "name": "Hortolândia"},
      {"name_uf": "SP", "name": "Iacanga"},
      {"name_uf": "SP", "name": "Iacri"},
      {"name_uf": "SP", "name": "Iaras"},
      {"name_uf": "SP", "name": "Ibaté"},
      {"name_uf": "SP", "name": "Ibirá"},
      {"name_uf": "SP", "name": "Ibirarema"},
      {"name_uf": "SP", "name": "Ibitinga"},
      {"name_uf": "SP", "name": "Ibiúna"},
      {"name_uf": "SP", "name": "Icém"},
      {"name_uf": "SP", "name": "Iepê"},
      {"name_uf": "SP", "name": "Igaraçu do Tietê"},
      {"name_uf": "SP", "name": "Igarapava"},
      {"name_uf": "SP", "name": "Igaratá"},
      {"name_uf": "SP", "name": "Iguape"},
      {"name_uf": "SP", "name": "Ilha Comprida"},
      {"name_uf": "SP", "name": "Ilha Solteira"},
      {"name_uf": "SP", "name": "Ilhabela"},
      {"name_uf": "SP", "name": "Indaiatuba"},
      {"name_uf": "SP", "name": "Indiana"},
      {"name_uf": "SP", "name": "Indiaporã"},
      {"name_uf": "SP", "name": "Inúbia Paulista"},
      {"name_uf": "SP", "name": "Ipaussu"},
      {"name_uf": "SP", "name": "Iperó"},
      {"name_uf": "SP", "name": "Ipeúna"},
      {"name_uf": "SP", "name": "Ipiguá"},
      {"name_uf": "SP", "name": "Iporanga"},
      {"name_uf": "SP", "name": "Ipuã"},
      {"name_uf": "SP", "name": "Iracemápolis"},
      {"name_uf": "SP", "name": "Irapuã"},
      {"name_uf": "SP", "name": "Irapuru"},
      {"name_uf": "SP", "name": "Itaberá"},
      {"name_uf": "SP", "name": "Itaí"},
      {"name_uf": "SP", "name": "Itajobi"},
      {"name_uf": "SP", "name": "Itaju"},
      {"name_uf": "SP", "name": "Itanhaém"},
      {"name_uf": "SP", "name": "Itaoca"},
      {"name_uf": "SP", "name": "Itapecerica da Serra"},
      {"name_uf": "SP", "name": "Itapetininga"},
      {"name_uf": "SP", "name": "Itapeva"},
      {"name_uf": "SP", "name": "Itapevi"},
      {"name_uf": "SP", "name": "Itapira"},
      {"name_uf": "SP", "name": "Itapirapuã Paulista"},
      {"name_uf": "SP", "name": "Itápolis"},
      {"name_uf": "SP", "name": "Itaporanga"},
      {"name_uf": "SP", "name": "Itapuí"},
      {"name_uf": "SP", "name": "Itapura"},
      {"name_uf": "SP", "name": "Itaquaquecetuba"},
      {"name_uf": "SP", "name": "Itararé"},
      {"name_uf": "SP", "name": "Itariri"},
      {"name_uf": "SP", "name": "Itatiba"},
      {"name_uf": "SP", "name": "Itatinga"},
      {"name_uf": "SP", "name": "Itirapina"},
      {"name_uf": "SP", "name": "Itirapuã"},
      {"name_uf": "SP", "name": "Itobi"},
      {"name_uf": "SP", "name": "Itu"},
      {"name_uf": "SP", "name": "Itupeva"},
      {"name_uf": "SP", "name": "Ituverava"},
      {"name_uf": "SP", "name": "Jaborandi"},
      {"name_uf": "SP", "name": "Jaboticabal"},
      {"name_uf": "SP", "name": "Jacareí"},
      {"name_uf": "SP", "name": "Jaci"},
      {"name_uf": "SP", "name": "Jacupiranga"},
      {"name_uf": "SP", "name": "Jaguariúna"},
      {"name_uf": "SP", "name": "Jales"},
      {"name_uf": "SP", "name": "Jambeiro"},
      {"name_uf": "SP", "name": "Jandira"},
      {"name_uf": "SP", "name": "Jardinópolis"},
      {"name_uf": "SP", "name": "Jarinu"},
      {"name_uf": "SP", "name": "Jaú"},
      {"name_uf": "SP", "name": "Jeriquara"},
      {"name_uf": "SP", "name": "Joanópolis"},
      {"name_uf": "SP", "name": "João Ramalho"},
      {"name_uf": "SP", "name": "José Bonifácio"},
      {"name_uf": "SP", "name": "Júlio Mesquita"},
      {"name_uf": "SP", "name": "Jumirim"},
      {"name_uf": "SP", "name": "Jundiaí"},
      {"name_uf": "SP", "name": "Junqueirópolis"},
      {"name_uf": "SP", "name": "Juquiá"},
      {"name_uf": "SP", "name": "Juquitiba"},
      {"name_uf": "SP", "name": "Lagoinha"},
      {"name_uf": "SP", "name": "Laranjal Paulista"},
      {"name_uf": "SP", "name": "Lavínia"},
      {"name_uf": "SP", "name": "Lavrinhas"},
      {"name_uf": "SP", "name": "Leme"},
      {"name_uf": "SP", "name": "Lençóis Paulista"},
      {"name_uf": "SP", "name": "Limeira"},
      {"name_uf": "SP", "name": "Lindóia"},
      {"name_uf": "SP", "name": "Lins"},
      {"name_uf": "SP", "name": "Lorena"},
      {"name_uf": "SP", "name": "Lourdes"},
      {"name_uf": "SP", "name": "Louveira"},
      {"name_uf": "SP", "name": "Lucélia"},
      {"name_uf": "SP", "name": "Lucianópolis"},
      {"name_uf": "SP", "name": "Luís Antônio"},
      {"name_uf": "SP", "name": "Luiziânia"},
      {"name_uf": "SP", "name": "Lupércio"},
      {"name_uf": "SP", "name": "Lutécia"},
      {"name_uf": "SP", "name": "Macatuba"},
      {"name_uf": "SP", "name": "Macaubal"},
      {"name_uf": "SP", "name": "Macedônia"},
      {"name_uf": "SP", "name": "Magda"},
      {"name_uf": "SP", "name": "Mairinque"},
      {"name_uf": "SP", "name": "Mairiporã"},
      {"name_uf": "SP", "name": "Manduri"},
      {"name_uf": "SP", "name": "Marabá Paulista"},
      {"name_uf": "SP", "name": "Maracaí"},
      {"name_uf": "SP", "name": "Marapoama"},
      {"name_uf": "SP", "name": "Mariápolis"},
      {"name_uf": "SP", "name": "Marília"},
      {"name_uf": "SP", "name": "Marinópolis"},
      {"name_uf": "SP", "name": "Martinópolis"},
      {"name_uf": "SP", "name": "Matão"},
      {"name_uf": "SP", "name": "Mauá"},
      {"name_uf": "SP", "name": "Mendonça"},
      {"name_uf": "SP", "name": "Meridiano"},
      {"name_uf": "SP", "name": "Mesópolis"},
      {"name_uf": "SP", "name": "Miguelópolis"},
      {"name_uf": "SP", "name": "Mineiros do Tietê"},
      {"name_uf": "SP", "name": "Mira Estrela"},
      {"name_uf": "SP", "name": "Miracatu"},
      {"name_uf": "SP", "name": "Mirandópolis"},
      {"name_uf": "SP", "name": "Mirante do Paranapanema"},
      {"name_uf": "SP", "name": "Mirassol"},
      {"name_uf": "SP", "name": "Mirassolândia"},
      {"name_uf": "SP", "name": "Mococa"},
      {"name_uf": "SP", "name": "Mogi das Cruzes"},
      {"name_uf": "SP", "name": "Mogi Guaçu"},
      {"name_uf": "SP", "name": "Mogi Mirim"},
      {"name_uf": "SP", "name": "Mombuca"},
      {"name_uf": "SP", "name": "Monções"},
      {"name_uf": "SP", "name": "Mongaguá"},
      {"name_uf": "SP", "name": "Monte Alegre do Sul"},
      {"name_uf": "SP", "name": "Monte Alto"},
      {"name_uf": "SP", "name": "Monte Aprazível"},
      {"name_uf": "SP", "name": "Monte Azul Paulista"},
      {"name_uf": "SP", "name": "Monte Castelo"},
      {"name_uf": "SP", "name": "Monte Mor"},
      {"name_uf": "SP", "name": "Monteiro Lobato"},
      {"name_uf": "SP", "name": "Morro Agudo"},
      {"name_uf": "SP", "name": "Morungaba"},
      {"name_uf": "SP", "name": "Motuca"},
      {"name_uf": "SP", "name": "Murutinga do Sul"},
      {"name_uf": "SP", "name": "Nantes"},
      {"name_uf": "SP", "name": "Narandiba"},
      {"name_uf": "SP", "name": "Natividade da Serra"},
      {"name_uf": "SP", "name": "Nazaré Paulista"},
      {"name_uf": "SP", "name": "Neves Paulista"},
      {"name_uf": "SP", "name": "Nhandeara"},
      {"name_uf": "SP", "name": "Nipoã"},
      {"name_uf": "SP", "name": "Nova Aliança"},
      {"name_uf": "SP", "name": "Nova Campina"},
      {"name_uf": "SP", "name": "Nova Canaã Paulista"},
      {"name_uf": "SP", "name": "Nova Castilho"},
      {"name_uf": "SP", "name": "Nova Europa"},
      {"name_uf": "SP", "name": "Nova Granada"},
      {"name_uf": "SP", "name": "Nova Guataporanga"},
      {"name_uf": "SP", "name": "Nova Independência"},
      {"name_uf": "SP", "name": "Nova Luzitânia"},
      {"name_uf": "SP", "name": "Nova Odessa"},
      {"name_uf": "SP", "name": "Novais"},
      {"name_uf": "SP", "name": "Novo Horizonte"},
      {"name_uf": "SP", "name": "Nuporanga"},
      {"name_uf": "SP", "name": "Ocauçu"},
      {"name_uf": "SP", "name": "Óleo"},
      {"name_uf": "SP", "name": "Olímpia"},
      {"name_uf": "SP", "name": "Onda Verde"},
      {"name_uf": "SP", "name": "Oriente"},
      {"name_uf": "SP", "name": "Orindiúva"},
      {"name_uf": "SP", "name": "Orlândia"},
      {"name_uf": "SP", "name": "Osasco"},
      {"name_uf": "SP", "name": "Oscar Bressane"},
      {"name_uf": "SP", "name": "Osvaldo Cruz"},
      {"name_uf": "SP", "name": "Ourinhos"},
      {"name_uf": "SP", "name": "Ouro Verde"},
      {"name_uf": "SP", "name": "Ouroeste"},
      {"name_uf": "SP", "name": "Pacaembu"},
      {"name_uf": "SP", "name": "Palestina"},
      {"name_uf": "SP", "name": "Palmares Paulista"},
      {"name_uf": "SP", "name": "Palmeira d'Oeste"},
      {"name_uf": "SP", "name": "Palmital"},
      {"name_uf": "SP", "name": "Panorama"},
      {"name_uf": "SP", "name": "Paraguaçu Paulista"},
      {"name_uf": "SP", "name": "Paraibuna"},
      {"name_uf": "SP", "name": "Paraíso"},
      {"name_uf": "SP", "name": "Paranapanema"},
      {"name_uf": "SP", "name": "Paranapuã"},
      {"name_uf": "SP", "name": "Parapuã"},
      {"name_uf": "SP", "name": "Pardinho"},
      {"name_uf": "SP", "name": "Pariquera-Açu"},
      {"name_uf": "SP", "name": "Parisi"},
      {"name_uf": "SP", "name": "Patrocínio Paulista"},
      {"name_uf": "SP", "name": "Paulicéia"},
      {"name_uf": "SP", "name": "Paulínia"},
      {"name_uf": "SP", "name": "Paulistânia"},
      {"name_uf": "SP", "name": "Paulo de Faria"},
      {"name_uf": "SP", "name": "Pederneiras"},
      {"name_uf": "SP", "name": "Pedra Bela"},
      {"name_uf": "SP", "name": "Pedranópolis"},
      {"name_uf": "SP", "name": "Pedregulho"},
      {"name_uf": "SP", "name": "Pedreira"},
      {"name_uf": "SP", "name": "Pedrinhas Paulista"},
      {"name_uf": "SP", "name": "Pedro de Toledo"},
      {"name_uf": "SP", "name": "Penápolis"},
      {"name_uf": "SP", "name": "Pereira Barreto"},
      {"name_uf": "SP", "name": "Pereiras"},
      {"name_uf": "SP", "name": "Peruíbe"},
      {"name_uf": "SP", "name": "Piacatu"},
      {"name_uf": "SP", "name": "Piedade"},
      {"name_uf": "SP", "name": "Pilar do Sul"},
      {"name_uf": "SP", "name": "Pindamonhangaba"},
      {"name_uf": "SP", "name": "Pindorama"},
      {"name_uf": "SP", "name": "Pinhalzinho"},
      {"name_uf": "SP", "name": "Piquerobi"},
      {"name_uf": "SP", "name": "Piquete"},
      {"name_uf": "SP", "name": "Piracaia"},
      {"name_uf": "SP", "name": "Piracicaba"},
      {"name_uf": "SP", "name": "Piraju"},
      {"name_uf": "SP", "name": "Pirajuí"},
      {"name_uf": "SP", "name": "Pirangi"},
      {"name_uf": "SP", "name": "Pirapora do Bom Jesus"},
      {"name_uf": "SP", "name": "Pirapozinho"},
      {"name_uf": "SP", "name": "Pirassununga"},
      {"name_uf": "SP", "name": "Piratininga"},
      {"name_uf": "SP", "name": "Pitangueiras"},
      {"name_uf": "SP", "name": "Planalto"},
      {"name_uf": "SP", "name": "Platina"},
      {"name_uf": "SP", "name": "Poá"},
      {"name_uf": "SP", "name": "Poloni"},
      {"name_uf": "SP", "name": "Pompéia"},
      {"name_uf": "SP", "name": "Pongaí"},
      {"name_uf": "SP", "name": "Pontal"},
      {"name_uf": "SP", "name": "Pontalinda"},
      {"name_uf": "SP", "name": "Pontes Gestal"},
      {"name_uf": "SP", "name": "Populina"},
      {"name_uf": "SP", "name": "Porangaba"},
      {"name_uf": "SP", "name": "Porto Feliz"},
      {"name_uf": "SP", "name": "Porto Ferreira"},
      {"name_uf": "SP", "name": "Potim"},
      {"name_uf": "SP", "name": "Potirendaba"},
      {"name_uf": "SP", "name": "Pracinha"},
      {"name_uf": "SP", "name": "Pradópolis"},
      {"name_uf": "SP", "name": "Praia Grande"},
      {"name_uf": "SP", "name": "Pratânia"},
      {"name_uf": "SP", "name": "Presidente Alves"},
      {"name_uf": "SP", "name": "Presidente Bernardes"},
      {"name_uf": "SP", "name": "Presidente Epitácio"},
      {"name_uf": "SP", "name": "Presidente Prudente"},
      {"name_uf": "SP", "name": "Presidente Venceslau"},
      {"name_uf": "SP", "name": "Promissão"},
      {"name_uf": "SP", "name": "Quadra"},
      {"name_uf": "SP", "name": "Quatá"},
      {"name_uf": "SP", "name": "Queiroz"},
      {"name_uf": "SP", "name": "Queluz"},
      {"name_uf": "SP", "name": "Quintana"},
      {"name_uf": "SP", "name": "Rafard"},
      {"name_uf": "SP", "name": "Rancharia"},
      {"name_uf": "SP", "name": "Redenção da Serra"},
      {"name_uf": "SP", "name": "Regente Feijó"},
      {"name_uf": "SP", "name": "Reginópolis"},
      {"name_uf": "SP", "name": "Registro"},
      {"name_uf": "SP", "name": "Restinga"},
      {"name_uf": "SP", "name": "Ribeira"},
      {"name_uf": "SP", "name": "Ribeirão Bonito"},
      {"name_uf": "SP", "name": "Ribeirão Branco"},
      {"name_uf": "SP", "name": "Ribeirão Corrente"},
      {"name_uf": "SP", "name": "Ribeirão do Sul"},
      {"name_uf": "SP", "name": "Ribeirão dos Índios"},
      {"name_uf": "SP", "name": "Ribeirão Grande"},
      {"name_uf": "SP", "name": "Ribeirão Pires"},
      {"name_uf": "SP", "name": "Ribeirão Preto"},
      {"name_uf": "SP", "name": "Rifaina"},
      {"name_uf": "SP", "name": "Rincão"},
      {"name_uf": "SP", "name": "Rinópolis"},
      {"name_uf": "SP", "name": "Rio Claro"},
      {"name_uf": "SP", "name": "Rio das Pedras"},
      {"name_uf": "SP", "name": "Rio Grande da Serra"},
      {"name_uf": "SP", "name": "Riolândia"},
      {"name_uf": "SP", "name": "Riversul"},
      {"name_uf": "SP", "name": "Rosana"},
      {"name_uf": "SP", "name": "Roseira"},
      {"name_uf": "SP", "name": "Rubiácea"},
      {"name_uf": "SP", "name": "Rubinéia"},
      {"name_uf": "SP", "name": "Sabino"},
      {"name_uf": "SP", "name": "Sagres"},
      {"name_uf": "SP", "name": "Sales"},
      {"name_uf": "SP", "name": "Sales Oliveira"},
      {"name_uf": "SP", "name": "Salesópolis"},
      {"name_uf": "SP", "name": "Salmourão"},
      {"name_uf": "SP", "name": "Saltinho"},
      {"name_uf": "SP", "name": "Salto"},
      {"name_uf": "SP", "name": "Salto de Pirapora"},
      {"name_uf": "SP", "name": "Salto Grande"},
      {"name_uf": "SP", "name": "Sandovalina"},
      {"name_uf": "SP", "name": "Santa Adélia"},
      {"name_uf": "SP", "name": "Santa Albertina"},
      {"name_uf": "SP", "name": "Santa Bárbara d'Oeste"},
      {"name_uf": "SP", "name": "Santa Branca"},
      {"name_uf": "SP", "name": "Santa Clara d'Oeste"},
      {"name_uf": "SP", "name": "Santa Cruz da Conceição"},
      {"name_uf": "SP", "name": "Santa Cruz da Esperança"},
      {"name_uf": "SP", "name": "Santa Cruz das Palmeiras"},
      {"name_uf": "SP", "name": "Santa Cruz do Rio Pardo"},
      {"name_uf": "SP", "name": "Santa Ernestina"},
      {"name_uf": "SP", "name": "Santa Fé do Sul"},
      {"name_uf": "SP", "name": "Santa Gertrudes"},
      {"name_uf": "SP", "name": "Santa Isabel"},
      {"name_uf": "SP", "name": "Santa Lúcia"},
      {"name_uf": "SP", "name": "Santa Maria da Serra"},
      {"name_uf": "SP", "name": "Santa Mercedes"},
      {"name_uf": "SP", "name": "Santa Rita do Passa Quatro"},
      {"name_uf": "SP", "name": "Santa Rita d'Oeste"},
      {"name_uf": "SP", "name": "Santa Rosa de Viterbo"},
      {"name_uf": "SP", "name": "Santa Salete"},
      {"name_uf": "SP", "name": "Santana da Ponte Pensa"},
      {"name_uf": "SP", "name": "Santana de Parnaíba"},
      {"name_uf": "SP", "name": "Santo Anastácio"},
      {"name_uf": "SP", "name": "Santo André"},
      {"name_uf": "SP", "name": "Santo Antônio da Alegria"},
      {"name_uf": "SP", "name": "Santo Antônio de Posse"},
      {"name_uf": "SP", "name": "Santo Antônio do Aracanguá"},
      {"name_uf": "SP", "name": "Santo Antônio do Jardim"},
      {"name_uf": "SP", "name": "Santo Antônio do Pinhal"},
      {"name_uf": "SP", "name": "Santo Expedito"},
      {"name_uf": "SP", "name": "Santópolis do Aguapeí"},
      {"name_uf": "SP", "name": "Santos"},
      {"name_uf": "SP", "name": "São Bento do Sapucaí"},
      {"name_uf": "SP", "name": "São Bernardo do Campo"},
      {"name_uf": "SP", "name": "São Caetano do Sul"},
      {"name_uf": "SP", "name": "São Carlos"},
      {"name_uf": "SP", "name": "São Francisco"},
      {"name_uf": "SP", "name": "São João da Boa Vista"},
      {"name_uf": "SP", "name": "São João das Duas Pontes"},
      {"name_uf": "SP", "name": "São João de Iracema"},
      {"name_uf": "SP", "name": "São João do Pau d'Alho"},
      {"name_uf": "SP", "name": "São Joaquim da Barra"},
      {"name_uf": "SP", "name": "São José da Bela Vista"},
      {"name_uf": "SP", "name": "São José do Barreiro"},
      {"name_uf": "SP", "name": "São José do Rio Pardo"},
      {"name_uf": "SP", "name": "São José do Rio Preto"},
      {"name_uf": "SP", "name": "São José dos Campos"},
      {"name_uf": "SP", "name": "São Lourenço da Serra"},
      {"name_uf": "SP", "name": "São Luiz do Paraitinga"},
      {"name_uf": "SP", "name": "São Manuel"},
      {"name_uf": "SP", "name": "São Miguel Arcanjo"},
      {"name_uf": "SP", "name": "SP"},
      {"name_uf": "SP", "name": "São Pedro"},
      {"name_uf": "SP", "name": "São Pedro do Turvo"},
      {"name_uf": "SP", "name": "São Roque"},
      {"name_uf": "SP", "name": "São Sebastião"},
      {"name_uf": "SP", "name": "São Sebastião da Grama"},
      {"name_uf": "SP", "name": "São Simão"},
      {"name_uf": "SP", "name": "São Vicente"},
      {"name_uf": "SP", "name": "Sarapuí"},
      {"name_uf": "SP", "name": "Sarutaiá"},
      {"name_uf": "SP", "name": "Sebastianópolis do Sul"},
      {"name_uf": "SP", "name": "Serra Azul"},
      {"name_uf": "SP", "name": "Serra Negra"},
      {"name_uf": "SP", "name": "Serrana"},
      {"name_uf": "SP", "name": "Sertãozinho"},
      {"name_uf": "SP", "name": "Sete Barras"},
      {"name_uf": "SP", "name": "Severínia"},
      {"name_uf": "SP", "name": "Silveiras"},
      {"name_uf": "SP", "name": "Socorro"},
      {"name_uf": "SP", "name": "Sorocaba"},
      {"name_uf": "SP", "name": "Sud Mennucci"},
      {"name_uf": "SP", "name": "Sumaré"},
      {"name_uf": "SP", "name": "Suzanápolis"},
      {"name_uf": "SP", "name": "Suzano"},
      {"name_uf": "SP", "name": "Tabapuã"},
      {"name_uf": "SP", "name": "Tabatinga"},
      {"name_uf": "SP", "name": "Taboão da Serra"},
      {"name_uf": "SP", "name": "Taciba"},
      {"name_uf": "SP", "name": "Taguaí"},
      {"name_uf": "SP", "name": "Taiaçu"},
      {"name_uf": "SP", "name": "Taiúva"},
      {"name_uf": "SP", "name": "Tambaú"},
      {"name_uf": "SP", "name": "Tanabi"},
      {"name_uf": "SP", "name": "Tapiraí"},
      {"name_uf": "SP", "name": "Tapiratiba"},
      {"name_uf": "SP", "name": "Taquaral"},
      {"name_uf": "SP", "name": "Taquaritinga"},
      {"name_uf": "SP", "name": "Taquarituba"},
      {"name_uf": "SP", "name": "Taquarivaí"},
      {"name_uf": "SP", "name": "Tarabai"},
      {"name_uf": "SP", "name": "Tarumã"},
      {"name_uf": "SP", "name": "Tatuí"},
      {"name_uf": "SP", "name": "Taubaté"},
      {"name_uf": "SP", "name": "Tejupá"},
      {"name_uf": "SP", "name": "Teodoro Sampaio"},
      {"name_uf": "SP", "name": "Terra Roxa"},
      {"name_uf": "SP", "name": "Tietê"},
      {"name_uf": "SP", "name": "Timburi"},
      {"name_uf": "SP", "name": "Torre de Pedra"},
      {"name_uf": "SP", "name": "Torrinha"},
      {"name_uf": "SP", "name": "Trabiju"},
      {"name_uf": "SP", "name": "Tremembé"},
      {"name_uf": "SP", "name": "Três Fronteiras"},
      {"name_uf": "SP", "name": "Tuiuti"},
      {"name_uf": "SP", "name": "Tupã"},
      {"name_uf": "SP", "name": "Tupi Paulista"},
      {"name_uf": "SP", "name": "Turiúba"},
      {"name_uf": "SP", "name": "Turmalina"},
      {"name_uf": "SP", "name": "Ubarana"},
      {"name_uf": "SP", "name": "Ubatuba"},
      {"name_uf": "SP", "name": "Ubirajara"},
      {"name_uf": "SP", "name": "Uchoa"},
      {"name_uf": "SP", "name": "União Paulista"},
      {"name_uf": "SP", "name": "Urânia"},
      {"name_uf": "SP", "name": "Uru"},
      {"name_uf": "SP", "name": "Urupês"},
      {"name_uf": "SP", "name": "Valentim Gentil"},
      {"name_uf": "SP", "name": "Valinhos"},
      {"name_uf": "SP", "name": "Valparaíso"},
      {"name_uf": "SP", "name": "Vargem"},
      {"name_uf": "SP", "name": "Vargem Grande do Sul"},
      {"name_uf": "SP", "name": "Vargem Grande Paulista"},
      {"name_uf": "SP", "name": "Várzea Paulista"},
      {"name_uf": "SP", "name": "Vera Cruz"},
      {"name_uf": "SP", "name": "Vinhedo"},
      {"name_uf": "SP", "name": "Viradouro"},
      {"name_uf": "SP", "name": "Vista Alegre do Alto"},
      {"name_uf": "SP", "name": "Vitória Brasil"},
      {"name_uf": "SP", "name": "Votorantim"},
      {"name_uf": "SP", "name": "Votuporanga"},
      {"name_uf": "SP", "name": "Zacarias"},
      {"name_uf": "PR", "name": "Abatiá"},
      {"name_uf": "PR", "name": "Adrianópolis"},
      {"name_uf": "PR", "name": "Agudos do Sul"},
      {"name_uf": "PR", "name": "Almirante Tamandaré"},
      {"name_uf": "PR", "name": "Altamira do PR"},
      {"name_uf": "PR", "name": "Alto Paraíso"},
      {"name_uf": "PR", "name": "Alto PR"},
      {"name_uf": "PR", "name": "Alto Piquiri"},
      {"name_uf": "PR", "name": "Altônia"},
      {"name_uf": "PR", "name": "Alvorada do Sul"},
      {"name_uf": "PR", "name": "Amaporã"},
      {"name_uf": "PR", "name": "Ampére"},
      {"name_uf": "PR", "name": "Anahy"},
      {"name_uf": "PR", "name": "Andirá"},
      {"name_uf": "PR", "name": "Ângulo"},
      {"name_uf": "PR", "name": "Antonina"},
      {"name_uf": "PR", "name": "Antônio Olinto"},
      {"name_uf": "PR", "name": "Apucarana"},
      {"name_uf": "PR", "name": "Arapongas"},
      {"name_uf": "PR", "name": "Arapoti"},
      {"name_uf": "PR", "name": "Arapuã"},
      {"name_uf": "PR", "name": "Araruna"},
      {"name_uf": "PR", "name": "Araucária"},
      {"name_uf": "PR", "name": "Ariranha do Ivaí"},
      {"name_uf": "PR", "name": "Assaí"},
      {"name_uf": "PR", "name": "Assis Chateaubriand"},
      {"name_uf": "PR", "name": "Astorga"},
      {"name_uf": "PR", "name": "Atalaia"},
      {"name_uf": "PR", "name": "Balsa Nova"},
      {"name_uf": "PR", "name": "Bandeirantes"},
      {"name_uf": "PR", "name": "Barbosa Ferraz"},
      {"name_uf": "PR", "name": "Barra do Jacaré"},
      {"name_uf": "PR", "name": "Barracão"},
      {"name_uf": "PR", "name": "Bela Vista da Caroba"},
      {"name_uf": "PR", "name": "Bela Vista do Paraíso"},
      {"name_uf": "PR", "name": "Bituruna"},
      {"name_uf": "PR", "name": "Boa Esperança"},
      {"name_uf": "PR", "name": "Boa Esperança do Iguaçu"},
      {"name_uf": "PR", "name": "Boa Ventura de São Roque"},
      {"name_uf": "PR", "name": "Boa Vista da Aparecida"},
      {"name_uf": "PR", "name": "Bocaiúva do Sul"},
      {"name_uf": "PR", "name": "Bom Jesus do Sul"},
      {"name_uf": "PR", "name": "Bom Sucesso"},
      {"name_uf": "PR", "name": "Bom Sucesso do Sul"},
      {"name_uf": "PR", "name": "Borrazópolis"},
      {"name_uf": "PR", "name": "Braganey"},
      {"name_uf": "PR", "name": "Brasilândia do Sul"},
      {"name_uf": "PR", "name": "Cafeara"},
      {"name_uf": "PR", "name": "Cafelândia"},
      {"name_uf": "PR", "name": "Cafezal do Sul"},
      {"name_uf": "PR", "name": "Califórnia"},
      {"name_uf": "PR", "name": "Cambará"},
      {"name_uf": "PR", "name": "Cambé"},
      {"name_uf": "PR", "name": "Cambira"},
      {"name_uf": "PR", "name": "Campina da Lagoa"},
      {"name_uf": "PR", "name": "Campina do Simão"},
      {"name_uf": "PR", "name": "Campina Grande do Sul"},
      {"name_uf": "PR", "name": "Campo Bonito"},
      {"name_uf": "PR", "name": "Campo do Tenente"},
      {"name_uf": "PR", "name": "Campo Largo"},
      {"name_uf": "PR", "name": "Campo Magro"},
      {"name_uf": "PR", "name": "Campo Mourão"},
      {"name_uf": "PR", "name": "Cândido de Abreu"},
      {"name_uf": "PR", "name": "Candói"},
      {"name_uf": "PR", "name": "Cantagalo"},
      {"name_uf": "PR", "name": "Capanema"},
      {"name_uf": "PR", "name": "Capitão Leônidas Marques"},
      {"name_uf": "PR", "name": "Carambeí"},
      {"name_uf": "PR", "name": "Carlópolis"},
      {"name_uf": "PR", "name": "Cascavel"},
      {"name_uf": "PR", "name": "Castro"},
      {"name_uf": "PR", "name": "Catanduvas"},
      {"name_uf": "PR", "name": "Centenário do Sul"},
      {"name_uf": "PR", "name": "Cerro Azul"},
      {"name_uf": "PR", "name": "Céu Azul"},
      {"name_uf": "PR", "name": "Chopinzinho"},
      {"name_uf": "PR", "name": "Cianorte"},
      {"name_uf": "PR", "name": "Cidade Gaúcha"},
      {"name_uf": "PR", "name": "Clevelândia"},
      {"name_uf": "PR", "name": "Colombo"},
      {"name_uf": "PR", "name": "Colorado"},
      {"name_uf": "PR", "name": "Congonhinhas"},
      {"name_uf": "PR", "name": "Conselheiro Mairinck"},
      {"name_uf": "PR", "name": "Contenda"},
      {"name_uf": "PR", "name": "Corbélia"},
      {"name_uf": "PR", "name": "Cornélio Procópio"},
      {"name_uf": "PR", "name": "Coronel Domingos Soares"},
      {"name_uf": "PR", "name": "Coronel Vivida"},
      {"name_uf": "PR", "name": "Corumbataí do Sul"},
      {"name_uf": "PR", "name": "Cruz Machado"},
      {"name_uf": "PR", "name": "Cruzeiro do Iguaçu"},
      {"name_uf": "PR", "name": "Cruzeiro do Oeste"},
      {"name_uf": "PR", "name": "Cruzeiro do Sul"},
      {"name_uf": "PR", "name": "Cruzmaltina"},
      {"name_uf": "PR", "name": "Curitiba"},
      {"name_uf": "PR", "name": "Curiúva"},
      {"name_uf": "PR", "name": "Diamante do Norte"},
      {"name_uf": "PR", "name": "Diamante do Sul"},
      {"name_uf": "PR", "name": "Diamante D'Oeste"},
      {"name_uf": "PR", "name": "Dois Vizinhos"},
      {"name_uf": "PR", "name": "Douradina"},
      {"name_uf": "PR", "name": "Doutor Camargo"},
      {"name_uf": "PR", "name": "Doutor Ulysses"},
      {"name_uf": "PR", "name": "Enéas Marques"},
      {"name_uf": "PR", "name": "Engenheiro Beltrão"},
      {"name_uf": "PR", "name": "Entre Rios do Oeste"},
      {"name_uf": "PR", "name": "Esperança Nova"},
      {"name_uf": "PR", "name": "Espigão Alto do Iguaçu"},
      {"name_uf": "PR", "name": "Farol"},
      {"name_uf": "PR", "name": "Faxinal"},
      {"name_uf": "PR", "name": "Fazenda Rio Grande"},
      {"name_uf": "PR", "name": "Fênix"},
      {"name_uf": "PR", "name": "Fernandes Pinheiro"},
      {"name_uf": "PR", "name": "Figueira"},
      {"name_uf": "PR", "name": "Flor da Serra do Sul"},
      {"name_uf": "PR", "name": "Floraí"},
      {"name_uf": "PR", "name": "Floresta"},
      {"name_uf": "PR", "name": "Florestópolis"},
      {"name_uf": "PR", "name": "Flórida"},
      {"name_uf": "PR", "name": "Formosa do Oeste"},
      {"name_uf": "PR", "name": "Foz do Iguaçu"},
      {"name_uf": "PR", "name": "Foz do Jordão"},
      {"name_uf": "PR", "name": "Francisco Alves"},
      {"name_uf": "PR", "name": "Francisco Beltrão"},
      {"name_uf": "PR", "name": "General Carneiro"},
      {"name_uf": "PR", "name": "Godoy Moreira"},
      {"name_uf": "PR", "name": "Goioerê"},
      {"name_uf": "PR", "name": "Goioxim"},
      {"name_uf": "PR", "name": "Grandes Rios"},
      {"name_uf": "PR", "name": "Guaíra"},
      {"name_uf": "PR", "name": "Guairaçá"},
      {"name_uf": "PR", "name": "Guamiranga"},
      {"name_uf": "PR", "name": "Guapirama"},
      {"name_uf": "PR", "name": "Guaporema"},
      {"name_uf": "PR", "name": "Guaraci"},
      {"name_uf": "PR", "name": "Guaraniaçu"},
      {"name_uf": "PR", "name": "Guarapuava"},
      {"name_uf": "PR", "name": "Guaraqueçaba"},
      {"name_uf": "PR", "name": "Guaratuba"},
      {"name_uf": "PR", "name": "Honório Serpa"},
      {"name_uf": "PR", "name": "Ibaiti"},
      {"name_uf": "PR", "name": "Ibema"},
      {"name_uf": "PR", "name": "Ibiporã"},
      {"name_uf": "PR", "name": "Icaraíma"},
      {"name_uf": "PR", "name": "Iguaraçu"},
      {"name_uf": "PR", "name": "Iguatu"},
      {"name_uf": "PR", "name": "Imbaú"},
      {"name_uf": "PR", "name": "Imbituva"},
      {"name_uf": "PR", "name": "Inácio Martins"},
      {"name_uf": "PR", "name": "Inajá"},
      {"name_uf": "PR", "name": "Indianópolis"},
      {"name_uf": "PR", "name": "Ipiranga"},
      {"name_uf": "PR", "name": "Iporã"},
      {"name_uf": "PR", "name": "Iracema do Oeste"},
      {"name_uf": "PR", "name": "Irati"},
      {"name_uf": "PR", "name": "Iretama"},
      {"name_uf": "PR", "name": "Itaguajé"},
      {"name_uf": "PR", "name": "Itaipulândia"},
      {"name_uf": "PR", "name": "Itambaracá"},
      {"name_uf": "PR", "name": "Itambé"},
      {"name_uf": "PR", "name": "Itapejara d'Oeste"},
      {"name_uf": "PR", "name": "Itaperuçu"},
      {"name_uf": "PR", "name": "Itaúna do Sul"},
      {"name_uf": "PR", "name": "Ivaí"},
      {"name_uf": "PR", "name": "Ivaiporã"},
      {"name_uf": "PR", "name": "Ivaté"},
      {"name_uf": "PR", "name": "Ivatuba"},
      {"name_uf": "PR", "name": "Jaboti"},
      {"name_uf": "PR", "name": "Jacarezinho"},
      {"name_uf": "PR", "name": "Jaguapitã"},
      {"name_uf": "PR", "name": "Jaguariaíva"},
      {"name_uf": "PR", "name": "Jandaia do Sul"},
      {"name_uf": "PR", "name": "Janiópolis"},
      {"name_uf": "PR", "name": "Japira"},
      {"name_uf": "PR", "name": "Japurá"},
      {"name_uf": "PR", "name": "Jardim Alegre"},
      {"name_uf": "PR", "name": "Jardim Olinda"},
      {"name_uf": "PR", "name": "Jataizinho"},
      {"name_uf": "PR", "name": "Jesuítas"},
      {"name_uf": "PR", "name": "Joaquim Távora"},
      {"name_uf": "PR", "name": "Jundiaí do Sul"},
      {"name_uf": "PR", "name": "Juranda"},
      {"name_uf": "PR", "name": "Jussara"},
      {"name_uf": "PR", "name": "Kaloré"},
      {"name_uf": "PR", "name": "Lapa"},
      {"name_uf": "PR", "name": "Laranjal"},
      {"name_uf": "PR", "name": "Laranjeiras do Sul"},
      {"name_uf": "PR", "name": "Leópolis"},
      {"name_uf": "PR", "name": "Lidianópolis"},
      {"name_uf": "PR", "name": "Lindoeste"},
      {"name_uf": "PR", "name": "Loanda"},
      {"name_uf": "PR", "name": "Lobato"},
      {"name_uf": "PR", "name": "Londrina"},
      {"name_uf": "PR", "name": "Luiziana"},
      {"name_uf": "PR", "name": "Lunardelli"},
      {"name_uf": "PR", "name": "Lupionópolis"},
      {"name_uf": "PR", "name": "Mallet"},
      {"name_uf": "PR", "name": "Mamborê"},
      {"name_uf": "PR", "name": "Mandaguaçu"},
      {"name_uf": "PR", "name": "Mandaguari"},
      {"name_uf": "PR", "name": "Mandirituba"},
      {"name_uf": "PR", "name": "Manfrinópolis"},
      {"name_uf": "PR", "name": "Mangueirinha"},
      {"name_uf": "PR", "name": "Manoel Ribas"},
      {"name_uf": "PR", "name": "Marechal Cândido Rondon"},
      {"name_uf": "PR", "name": "Maria Helena"},
      {"name_uf": "PR", "name": "Marialva"},
      {"name_uf": "PR", "name": "Marilândia do Sul"},
      {"name_uf": "PR", "name": "Marilena"},
      {"name_uf": "PR", "name": "Mariluz"},
      {"name_uf": "PR", "name": "Maringá"},
      {"name_uf": "PR", "name": "Mariópolis"},
      {"name_uf": "PR", "name": "Maripá"},
      {"name_uf": "PR", "name": "Marmeleiro"},
      {"name_uf": "PR", "name": "Marquinho"},
      {"name_uf": "PR", "name": "Marumbi"},
      {"name_uf": "PR", "name": "Matelândia"},
      {"name_uf": "PR", "name": "Matinhos"},
      {"name_uf": "PR", "name": "Mato Rico"},
      {"name_uf": "PR", "name": "Mauá da Serra"},
      {"name_uf": "PR", "name": "Medianeira"},
      {"name_uf": "PR", "name": "Mercedes"},
      {"name_uf": "PR", "name": "Mirador"},
      {"name_uf": "PR", "name": "Miraselva"},
      {"name_uf": "PR", "name": "Missal"},
      {"name_uf": "PR", "name": "Moreira Sales"},
      {"name_uf": "PR", "name": "Morretes"},
      {"name_uf": "PR", "name": "Munhoz de Melo"},
      {"name_uf": "PR", "name": "Nossa Senhora das Graças"},
      {"name_uf": "PR", "name": "Nova Aliança do Ivaí"},
      {"name_uf": "PR", "name": "Nova América da Colina"},
      {"name_uf": "PR", "name": "Nova Aurora"},
      {"name_uf": "PR", "name": "Nova Cantu"},
      {"name_uf": "PR", "name": "Nova Esperança"},
      {"name_uf": "PR", "name": "Nova Esperança do Sudoeste"},
      {"name_uf": "PR", "name": "Nova Fátima"},
      {"name_uf": "PR", "name": "Nova Laranjeiras"},
      {"name_uf": "PR", "name": "Nova Londrina"},
      {"name_uf": "PR", "name": "Nova Olímpia"},
      {"name_uf": "PR", "name": "Nova Prata do Iguaçu"},
      {"name_uf": "PR", "name": "Nova Santa Bárbara"},
      {"name_uf": "PR", "name": "Nova Santa Rosa"},
      {"name_uf": "PR", "name": "Nova Tebas"},
      {"name_uf": "PR", "name": "Novo Itacolomi"},
      {"name_uf": "PR", "name": "Ortigueira"},
      {"name_uf": "PR", "name": "Ourizona"},
      {"name_uf": "PR", "name": "Ouro Verde do Oeste"},
      {"name_uf": "PR", "name": "Paiçandu"},
      {"name_uf": "PR", "name": "Palmas"},
      {"name_uf": "PR", "name": "Palmeira"},
      {"name_uf": "PR", "name": "Palmital"},
      {"name_uf": "PR", "name": "Palotina"},
      {"name_uf": "PR", "name": "Paraíso do Norte"},
      {"name_uf": "PR", "name": "Paranacity"},
      {"name_uf": "PR", "name": "Paranaguá"},
      {"name_uf": "PR", "name": "Paranapoema"},
      {"name_uf": "PR", "name": "Paranavaí"},
      {"name_uf": "PR", "name": "Pato Bragado"},
      {"name_uf": "PR", "name": "Pato Branco"},
      {"name_uf": "PR", "name": "Paula Freitas"},
      {"name_uf": "PR", "name": "Paulo Frontin"},
      {"name_uf": "PR", "name": "Peabiru"},
      {"name_uf": "PR", "name": "Perobal"},
      {"name_uf": "PR", "name": "Pérola"},
      {"name_uf": "PR", "name": "Pérola d'Oeste"},
      {"name_uf": "PR", "name": "Piên"},
      {"name_uf": "PR", "name": "Pinhais"},
      {"name_uf": "PR", "name": "Pinhal de São Bento"},
      {"name_uf": "PR", "name": "Pinhalão"},
      {"name_uf": "PR", "name": "Pinhão"},
      {"name_uf": "PR", "name": "Piraí do Sul"},
      {"name_uf": "PR", "name": "Piraquara"},
      {"name_uf": "PR", "name": "Pitanga"},
      {"name_uf": "PR", "name": "Pitangueiras"},
      {"name_uf": "PR", "name": "Planaltina do PR"},
      {"name_uf": "PR", "name": "Planalto"},
      {"name_uf": "PR", "name": "Ponta Grossa"},
      {"name_uf": "PR", "name": "Pontal do PR"},
      {"name_uf": "PR", "name": "Porecatu"},
      {"name_uf": "PR", "name": "Porto AM"},
      {"name_uf": "PR", "name": "Porto Barreiro"},
      {"name_uf": "PR", "name": "Porto Rico"},
      {"name_uf": "PR", "name": "Porto Vitória"},
      {"name_uf": "PR", "name": "Prado Ferreira"},
      {"name_uf": "PR", "name": "Pranchita"},
      {"name_uf": "PR", "name": "Presidente Castelo Branco"},
      {"name_uf": "PR", "name": "Primeiro de Maio"},
      {"name_uf": "PR", "name": "Prudentópolis"},
      {"name_uf": "PR", "name": "Quarto Centenário"},
      {"name_uf": "PR", "name": "Quatiguá"},
      {"name_uf": "PR", "name": "Quatro Barras"},
      {"name_uf": "PR", "name": "Quatro Pontes"},
      {"name_uf": "PR", "name": "Quedas do Iguaçu"},
      {"name_uf": "PR", "name": "Querência do Norte"},
      {"name_uf": "PR", "name": "Quinta do Sol"},
      {"name_uf": "PR", "name": "Quitandinha"},
      {"name_uf": "PR", "name": "Ramilândia"},
      {"name_uf": "PR", "name": "Rancho Alegre"},
      {"name_uf": "PR", "name": "Rancho Alegre D'Oeste"},
      {"name_uf": "PR", "name": "Realeza"},
      {"name_uf": "PR", "name": "Rebouças"},
      {"name_uf": "PR", "name": "Renascença"},
      {"name_uf": "PR", "name": "Reserva"},
      {"name_uf": "PR", "name": "Reserva do Iguaçu"},
      {"name_uf": "PR", "name": "Ribeirão Claro"},
      {"name_uf": "PR", "name": "Ribeirão do Pinhal"},
      {"name_uf": "PR", "name": "Rio Azul"},
      {"name_uf": "PR", "name": "Rio Bom"},
      {"name_uf": "PR", "name": "Rio Bonito do Iguaçu"},
      {"name_uf": "PR", "name": "Rio Branco do Ivaí"},
      {"name_uf": "PR", "name": "Rio Branco do Sul"},
      {"name_uf": "PR", "name": "Rio Negro"},
      {"name_uf": "PR", "name": "Rolândia"},
      {"name_uf": "PR", "name": "Roncador"},
      {"name_uf": "PR", "name": "Rondon"},
      {"name_uf": "PR", "name": "Rosário do Ivaí"},
      {"name_uf": "PR", "name": "Sabáudia"},
      {"name_uf": "PR", "name": "Salgado Filho"},
      {"name_uf": "PR", "name": "Salto do Itararé"},
      {"name_uf": "PR", "name": "Salto do Lontra"},
      {"name_uf": "PR", "name": "Santa Amélia"},
      {"name_uf": "PR", "name": "Santa Cecília do Pavão"},
      {"name_uf": "PR", "name": "Santa Cruz de Monte Castelo"},
      {"name_uf": "PR", "name": "Santa Fé"},
      {"name_uf": "PR", "name": "Santa Helena"},
      {"name_uf": "PR", "name": "Santa Inês"},
      {"name_uf": "PR", "name": "Santa Isabel do Ivaí"},
      {"name_uf": "PR", "name": "Santa Izabel do Oeste"},
      {"name_uf": "PR", "name": "Santa Lúcia"},
      {"name_uf": "PR", "name": "Santa Maria do Oeste"},
      {"name_uf": "PR", "name": "Santa Mariana"},
      {"name_uf": "PR", "name": "Santa Mônica"},
      {"name_uf": "PR", "name": "Santa Tereza do Oeste"},
      {"name_uf": "PR", "name": "Santa Terezinha de Itaipu"},
      {"name_uf": "PR", "name": "Santana do Itararé"},
      {"name_uf": "PR", "name": "Santo Antônio da Platina"},
      {"name_uf": "PR", "name": "Santo Antônio do Caiuá"},
      {"name_uf": "PR", "name": "Santo Antônio do Paraíso"},
      {"name_uf": "PR", "name": "Santo Antônio do Sudoeste"},
      {"name_uf": "PR", "name": "Santo Inácio"},
      {"name_uf": "PR", "name": "São Carlos do Ivaí"},
      {"name_uf": "PR", "name": "São Jerônimo da Serra"},
      {"name_uf": "PR", "name": "São João"},
      {"name_uf": "PR", "name": "São João do Caiuá"},
      {"name_uf": "PR", "name": "São João do Ivaí"},
      {"name_uf": "PR", "name": "São João do Triunfo"},
      {"name_uf": "PR", "name": "São Jorge do Ivaí"},
      {"name_uf": "PR", "name": "São Jorge do Patrocínio"},
      {"name_uf": "PR", "name": "São Jorge d'Oeste"},
      {"name_uf": "PR", "name": "São José da Boa Vista"},
      {"name_uf": "PR", "name": "São José das Palmeiras"},
      {"name_uf": "PR", "name": "São José dos Pinhais"},
      {"name_uf": "PR", "name": "São Manoel do PR"},
      {"name_uf": "PR", "name": "São Mateus do Sul"},
      {"name_uf": "PR", "name": "São Miguel do Iguaçu"},
      {"name_uf": "PR", "name": "São Pedro do Iguaçu"},
      {"name_uf": "PR", "name": "São Pedro do Ivaí"},
      {"name_uf": "PR", "name": "São Pedro do PR"},
      {"name_uf": "PR", "name": "São Sebastião da Amoreira"},
      {"name_uf": "PR", "name": "São Tomé"},
      {"name_uf": "PR", "name": "Sapopema"},
      {"name_uf": "PR", "name": "Sarandi"},
      {"name_uf": "PR", "name": "Saudade do Iguaçu"},
      {"name_uf": "PR", "name": "Sengés"},
      {"name_uf": "PR", "name": "Serranópolis do Iguaçu"},
      {"name_uf": "PR", "name": "Sertaneja"},
      {"name_uf": "PR", "name": "Sertanópolis"},
      {"name_uf": "PR", "name": "Siqueira Campos"},
      {"name_uf": "PR", "name": "Sulina"},
      {"name_uf": "PR", "name": "Tamarana"},
      {"name_uf": "PR", "name": "Tamboara"},
      {"name_uf": "PR", "name": "Tapejara"},
      {"name_uf": "PR", "name": "Tapira"},
      {"name_uf": "PR", "name": "Teixeira Soares"},
      {"name_uf": "PR", "name": "Telêmaco Borba"},
      {"name_uf": "PR", "name": "Terra Boa"},
      {"name_uf": "PR", "name": "Terra Rica"},
      {"name_uf": "PR", "name": "Terra Roxa"},
      {"name_uf": "PR", "name": "Tibagi"},
      {"name_uf": "PR", "name": "Tijucas do Sul"},
      {"name_uf": "PR", "name": "Toledo"},
      {"name_uf": "PR", "name": "Tomazina"},
      {"name_uf": "PR", "name": "Três Barras do PR"},
      {"name_uf": "PR", "name": "Tunas do PR"},
      {"name_uf": "PR", "name": "Tuneiras do Oeste"},
      {"name_uf": "PR", "name": "Tupãssi"},
      {"name_uf": "PR", "name": "Turvo"},
      {"name_uf": "PR", "name": "Ubiratã"},
      {"name_uf": "PR", "name": "Umuarama"},
      {"name_uf": "PR", "name": "União da Vitória"},
      {"name_uf": "PR", "name": "Uniflor"},
      {"name_uf": "PR", "name": "Uraí"},
      {"name_uf": "PR", "name": "Ventania"},
      {"name_uf": "PR", "name": "Vera Cruz do Oeste"},
      {"name_uf": "PR", "name": "Verê"},
      {"name_uf": "PR", "name": "Virmond"},
      {"name_uf": "PR", "name": "Vitorino"},
      {"name_uf": "PR", "name": "Wenceslau Braz"},
      {"name_uf": "PR", "name": "Xambrê"},
      {"name_uf": "SC", "name": "Abdon Batista"},
      {"name_uf": "SC", "name": "Abelardo Luz"},
      {"name_uf": "SC", "name": "Agrolândia"},
      {"name_uf": "SC", "name": "Agronômica"},
      {"name_uf": "SC", "name": "Água Doce"},
      {"name_uf": "SC", "name": "Águas de Chapecó"},
      {"name_uf": "SC", "name": "Águas Frias"},
      {"name_uf": "SC", "name": "Águas Mornas"},
      {"name_uf": "SC", "name": "Alfredo Wagner"},
      {"name_uf": "SC", "name": "Alto Bela Vista"},
      {"name_uf": "SC", "name": "Anchieta"},
      {"name_uf": "SC", "name": "Angelina"},
      {"name_uf": "SC", "name": "Anita Garibaldi"},
      {"name_uf": "SC", "name": "Anitápolis"},
      {"name_uf": "SC", "name": "Antônio Carlos"},
      {"name_uf": "SC", "name": "Apiúna"},
      {"name_uf": "SC", "name": "Arabutã"},
      {"name_uf": "SC", "name": "Araquari"},
      {"name_uf": "SC", "name": "Araranguá"},
      {"name_uf": "SC", "name": "Armazém"},
      {"name_uf": "SC", "name": "Arroio Trinta"},
      {"name_uf": "SC", "name": "Arvoredo"},
      {"name_uf": "SC", "name": "Ascurra"},
      {"name_uf": "SC", "name": "Atalanta"},
      {"name_uf": "SC", "name": "Aurora"},
      {"name_uf": "SC", "name": "Balneário Arroio do Silva"},
      {"name_uf": "SC", "name": "Balneário Barra do Sul"},
      {"name_uf": "SC", "name": "Balneário Camboriú"},
      {"name_uf": "SC", "name": "Balneário Gaivota"},
      {"name_uf": "SC", "name": "Balneário Piçarras"},
      {"name_uf": "SC", "name": "Balneário Rincão"},
      {"name_uf": "SC", "name": "Bandeirante"},
      {"name_uf": "SC", "name": "Barra Bonita"},
      {"name_uf": "SC", "name": "Barra Velha"},
      {"name_uf": "SC", "name": "Bela Vista do Toldo"},
      {"name_uf": "SC", "name": "Belmonte"},
      {"name_uf": "SC", "name": "Benedito Novo"},
      {"name_uf": "SC", "name": "Biguaçu"},
      {"name_uf": "SC", "name": "Blumenau"},
      {"name_uf": "SC", "name": "Bocaina do Sul"},
      {"name_uf": "SC", "name": "Bom Jardim da Serra"},
      {"name_uf": "SC", "name": "Bom Jesus"},
      {"name_uf": "SC", "name": "Bom Jesus do Oeste"},
      {"name_uf": "SC", "name": "Bom Retiro"},
      {"name_uf": "SC", "name": "Bombinhas"},
      {"name_uf": "SC", "name": "Botuverá"},
      {"name_uf": "SC", "name": "Braço do Norte"},
      {"name_uf": "SC", "name": "Braço do Trombudo"},
      {"name_uf": "SC", "name": "Brunópolis"},
      {"name_uf": "SC", "name": "Brusque"},
      {"name_uf": "SC", "name": "Caçador"},
      {"name_uf": "SC", "name": "Caibi"},
      {"name_uf": "SC", "name": "Calmon"},
      {"name_uf": "SC", "name": "Camboriú"},
      {"name_uf": "SC", "name": "Campo Alegre"},
      {"name_uf": "SC", "name": "Campo Belo do Sul"},
      {"name_uf": "SC", "name": "Campo Erê"},
      {"name_uf": "SC", "name": "Campos Novos"},
      {"name_uf": "SC", "name": "Canelinha"},
      {"name_uf": "SC", "name": "Canoinhas"},
      {"name_uf": "SC", "name": "Capão Alto"},
      {"name_uf": "SC", "name": "Capinzal"},
      {"name_uf": "SC", "name": "Capivari de Baixo"},
      {"name_uf": "SC", "name": "Catanduvas"},
      {"name_uf": "SC", "name": "Caxambu do Sul"},
      {"name_uf": "SC", "name": "Celso Ramos"},
      {"name_uf": "SC", "name": "Cerro Negro"},
      {"name_uf": "SC", "name": "Chapadão do Lageado"},
      {"name_uf": "SC", "name": "Chapecó"},
      {"name_uf": "SC", "name": "Cocal do Sul"},
      {"name_uf": "SC", "name": "Concórdia"},
      {"name_uf": "SC", "name": "Cordilheira Alta"},
      {"name_uf": "SC", "name": "Coronel Freitas"},
      {"name_uf": "SC", "name": "Coronel Martins"},
      {"name_uf": "SC", "name": "Correia Pinto"},
      {"name_uf": "SC", "name": "Corupá"},
      {"name_uf": "SC", "name": "Criciúma"},
      {"name_uf": "SC", "name": "Cunha Porã"},
      {"name_uf": "SC", "name": "Cunhataí"},
      {"name_uf": "SC", "name": "Curitibanos"},
      {"name_uf": "SC", "name": "Descanso"},
      {"name_uf": "SC", "name": "Dionísio Cerqueira"},
      {"name_uf": "SC", "name": "Dona Emma"},
      {"name_uf": "SC", "name": "Doutor Pedrinho"},
      {"name_uf": "SC", "name": "Entre Rios"},
      {"name_uf": "SC", "name": "Ermo"},
      {"name_uf": "SC", "name": "Erval Velho"},
      {"name_uf": "SC", "name": "Faxinal dos Guedes"},
      {"name_uf": "SC", "name": "Flor do Sertão"},
      {"name_uf": "SC", "name": "Florianópolis"},
      {"name_uf": "SC", "name": "Formosa do Sul"},
      {"name_uf": "SC", "name": "Forquilhinha"},
      {"name_uf": "SC", "name": "Fraiburgo"},
      {"name_uf": "SC", "name": "Frei Rogério"},
      {"name_uf": "SC", "name": "Galvão"},
      {"name_uf": "SC", "name": "Garopaba"},
      {"name_uf": "SC", "name": "Garuva"},
      {"name_uf": "SC", "name": "Gaspar"},
      {"name_uf": "SC", "name": "Governador Celso Ramos"},
      {"name_uf": "SC", "name": "Grão PA"},
      {"name_uf": "SC", "name": "Gravatal"},
      {"name_uf": "SC", "name": "Guabiruba"},
      {"name_uf": "SC", "name": "Guaraciaba"},
      {"name_uf": "SC", "name": "Guaramirim"},
      {"name_uf": "SC", "name": "Guarujá do Sul"},
      {"name_uf": "SC", "name": "Guatambú"},
      {"name_uf": "SC", "name": "Herval d'Oeste"},
      {"name_uf": "SC", "name": "Ibiam"},
      {"name_uf": "SC", "name": "Ibicaré"},
      {"name_uf": "SC", "name": "Ibirama"},
      {"name_uf": "SC", "name": "Içara"},
      {"name_uf": "SC", "name": "Ilhota"},
      {"name_uf": "SC", "name": "Imaruí"},
      {"name_uf": "SC", "name": "Imbituba"},
      {"name_uf": "SC", "name": "Imbuia"},
      {"name_uf": "SC", "name": "Indaial"},
      {"name_uf": "SC", "name": "Iomerê"},
      {"name_uf": "SC", "name": "Ipira"},
      {"name_uf": "SC", "name": "Iporã do Oeste"},
      {"name_uf": "SC", "name": "Ipuaçu"},
      {"name_uf": "SC", "name": "Ipumirim"},
      {"name_uf": "SC", "name": "Iraceminha"},
      {"name_uf": "SC", "name": "Irani"},
      {"name_uf": "SC", "name": "Irati"},
      {"name_uf": "SC", "name": "Irineópolis"},
      {"name_uf": "SC", "name": "Itá"},
      {"name_uf": "SC", "name": "Itaiópolis"},
      {"name_uf": "SC", "name": "Itajaí"},
      {"name_uf": "SC", "name": "Itapema"},
      {"name_uf": "SC", "name": "Itapiranga"},
      {"name_uf": "SC", "name": "Itapoá"},
      {"name_uf": "SC", "name": "Ituporanga"},
      {"name_uf": "SC", "name": "Jaborá"},
      {"name_uf": "SC", "name": "Jacinto Machado"},
      {"name_uf": "SC", "name": "Jaguaruna"},
      {"name_uf": "SC", "name": "Jaraguá do Sul"},
      {"name_uf": "SC", "name": "Jardinópolis"},
      {"name_uf": "SC", "name": "Joaçaba"},
      {"name_uf": "SC", "name": "Joinville"},
      {"name_uf": "SC", "name": "José Boiteux"},
      {"name_uf": "SC", "name": "Jupiá"},
      {"name_uf": "SC", "name": "Lacerdópolis"},
      {"name_uf": "SC", "name": "Lages"},
      {"name_uf": "SC", "name": "Laguna"},
      {"name_uf": "SC", "name": "Lajeado Grande"},
      {"name_uf": "SC", "name": "Laurentino"},
      {"name_uf": "SC", "name": "Lauro Müller"},
      {"name_uf": "SC", "name": "Lebon Régis"},
      {"name_uf": "SC", "name": "Leoberto Leal"},
      {"name_uf": "SC", "name": "Lindóia do Sul"},
      {"name_uf": "SC", "name": "Lontras"},
      {"name_uf": "SC", "name": "Luiz Alves"},
      {"name_uf": "SC", "name": "Luzerna"},
      {"name_uf": "SC", "name": "Macieira"},
      {"name_uf": "SC", "name": "Mafra"},
      {"name_uf": "SC", "name": "Major Gercino"},
      {"name_uf": "SC", "name": "Major Vieira"},
      {"name_uf": "SC", "name": "Maracajá"},
      {"name_uf": "SC", "name": "Maravilha"},
      {"name_uf": "SC", "name": "Marema"},
      {"name_uf": "SC", "name": "Massaranduba"},
      {"name_uf": "SC", "name": "Matos Costa"},
      {"name_uf": "SC", "name": "Meleiro"},
      {"name_uf": "SC", "name": "Mirim Doce"},
      {"name_uf": "SC", "name": "Modelo"},
      {"name_uf": "SC", "name": "Mondaí"},
      {"name_uf": "SC", "name": "Monte Carlo"},
      {"name_uf": "SC", "name": "Monte Castelo"},
      {"name_uf": "SC", "name": "Morro da Fumaça"},
      {"name_uf": "SC", "name": "Morro Grande"},
      {"name_uf": "SC", "name": "Navegantes"},
      {"name_uf": "SC", "name": "Nova Erechim"},
      {"name_uf": "SC", "name": "Nova Itaberaba"},
      {"name_uf": "SC", "name": "Nova Trento"},
      {"name_uf": "SC", "name": "Nova Veneza"},
      {"name_uf": "SC", "name": "Novo Horizonte"},
      {"name_uf": "SC", "name": "Orleans"},
      {"name_uf": "SC", "name": "Otacílio Costa"},
      {"name_uf": "SC", "name": "Ouro"},
      {"name_uf": "SC", "name": "Ouro Verde"},
      {"name_uf": "SC", "name": "Paial"},
      {"name_uf": "SC", "name": "Painel"},
      {"name_uf": "SC", "name": "Palhoça"},
      {"name_uf": "SC", "name": "Palma Sola"},
      {"name_uf": "SC", "name": "Palmeira"},
      {"name_uf": "SC", "name": "Palmitos"},
      {"name_uf": "SC", "name": "Papanduva"},
      {"name_uf": "SC", "name": "Paraíso"},
      {"name_uf": "SC", "name": "Passo de Torres"},
      {"name_uf": "SC", "name": "Passos Maia"},
      {"name_uf": "SC", "name": "Paulo Lopes"},
      {"name_uf": "SC", "name": "Pedras Grandes"},
      {"name_uf": "SC", "name": "Penha"},
      {"name_uf": "SC", "name": "Peritiba"},
      {"name_uf": "SC", "name": "Pescaria Brava"},
      {"name_uf": "SC", "name": "Petrolândia"},
      {"name_uf": "SC", "name": "Pinhalzinho"},
      {"name_uf": "SC", "name": "Pinheiro Preto"},
      {"name_uf": "SC", "name": "Piratuba"},
      {"name_uf": "SC", "name": "Planalto Alegre"},
      {"name_uf": "SC", "name": "Pomerode"},
      {"name_uf": "SC", "name": "Ponte Alta"},
      {"name_uf": "SC", "name": "Ponte Alta do Norte"},
      {"name_uf": "SC", "name": "Ponte Serrada"},
      {"name_uf": "SC", "name": "Porto Belo"},
      {"name_uf": "SC", "name": "Porto União"},
      {"name_uf": "SC", "name": "Pouso Redondo"},
      {"name_uf": "SC", "name": "Praia Grande"},
      {"name_uf": "SC", "name": "Presidente Castello Branco"},
      {"name_uf": "SC", "name": "Presidente Getúlio"},
      {"name_uf": "SC", "name": "Presidente Nereu"},
      {"name_uf": "SC", "name": "Princesa"},
      {"name_uf": "SC", "name": "Quilombo"},
      {"name_uf": "SC", "name": "Rancho Queimado"},
      {"name_uf": "SC", "name": "Rio das Antas"},
      {"name_uf": "SC", "name": "Rio do Campo"},
      {"name_uf": "SC", "name": "Rio do Oeste"},
      {"name_uf": "SC", "name": "Rio do Sul"},
      {"name_uf": "SC", "name": "Rio dos Cedros"},
      {"name_uf": "SC", "name": "Rio Fortuna"},
      {"name_uf": "SC", "name": "Rio Negrinho"},
      {"name_uf": "SC", "name": "Rio Rufino"},
      {"name_uf": "SC", "name": "Riqueza"},
      {"name_uf": "SC", "name": "Rodeio"},
      {"name_uf": "SC", "name": "Romelândia"},
      {"name_uf": "SC", "name": "Salete"},
      {"name_uf": "SC", "name": "Saltinho"},
      {"name_uf": "SC", "name": "Salto Veloso"},
      {"name_uf": "SC", "name": "Sangão"},
      {"name_uf": "SC", "name": "Santa Cecília"},
      {"name_uf": "SC", "name": "Santa Helena"},
      {"name_uf": "SC", "name": "Santa Rosa de Lima"},
      {"name_uf": "SC", "name": "Santa Rosa do Sul"},
      {"name_uf": "SC", "name": "Santa Terezinha"},
      {"name_uf": "SC", "name": "Santa Terezinha do Progresso"},
      {"name_uf": "SC", "name": "Santiago do Sul"},
      {"name_uf": "SC", "name": "Santo Amaro da Imperatriz"},
      {"name_uf": "SC", "name": "São Bento do Sul"},
      {"name_uf": "SC", "name": "São Bernardino"},
      {"name_uf": "SC", "name": "São Bonifácio"},
      {"name_uf": "SC", "name": "São Carlos"},
      {"name_uf": "SC", "name": "São Cristóvão do Sul"},
      {"name_uf": "SC", "name": "São Domingos"},
      {"name_uf": "SC", "name": "São Francisco do Sul"},
      {"name_uf": "SC", "name": "São João Batista"},
      {"name_uf": "SC", "name": "São João do Itaperiú"},
      {"name_uf": "SC", "name": "São João do Oeste"},
      {"name_uf": "SC", "name": "São João do Sul"},
      {"name_uf": "SC", "name": "São Joaquim"},
      {"name_uf": "SC", "name": "São José"},
      {"name_uf": "SC", "name": "São José do Cedro"},
      {"name_uf": "SC", "name": "São José do Cerrito"},
      {"name_uf": "SC", "name": "São Lourenço do Oeste"},
      {"name_uf": "SC", "name": "São Ludgero"},
      {"name_uf": "SC", "name": "São Martinho"},
      {"name_uf": "SC", "name": "São Miguel da Boa Vista"},
      {"name_uf": "SC", "name": "São Miguel do Oeste"},
      {"name_uf": "SC", "name": "São Pedro de Alcântara"},
      {"name_uf": "SC", "name": "Saudades"},
      {"name_uf": "SC", "name": "Schroeder"},
      {"name_uf": "SC", "name": "Seara"},
      {"name_uf": "SC", "name": "Serra Alta"},
      {"name_uf": "SC", "name": "Siderópolis"},
      {"name_uf": "SC", "name": "Sombrio"},
      {"name_uf": "SC", "name": "Sul Brasil"},
      {"name_uf": "SC", "name": "Taió"},
      {"name_uf": "SC", "name": "Tangará"},
      {"name_uf": "SC", "name": "Tigrinhos"},
      {"name_uf": "SC", "name": "Tijucas"},
      {"name_uf": "SC", "name": "Timbé do Sul"},
      {"name_uf": "SC", "name": "Timbó"},
      {"name_uf": "SC", "name": "Timbó Grande"},
      {"name_uf": "SC", "name": "Três Barras"},
      {"name_uf": "SC", "name": "Treviso"},
      {"name_uf": "SC", "name": "Treze de Maio"},
      {"name_uf": "SC", "name": "Treze Tílias"},
      {"name_uf": "SC", "name": "Trombudo Central"},
      {"name_uf": "SC", "name": "Tubarão"},
      {"name_uf": "SC", "name": "Tunápolis"},
      {"name_uf": "SC", "name": "Turvo"},
      {"name_uf": "SC", "name": "União do Oeste"},
      {"name_uf": "SC", "name": "Urubici"},
      {"name_uf": "SC", "name": "Urupema"},
      {"name_uf": "SC", "name": "Urussanga"},
      {"name_uf": "SC", "name": "Vargeão"},
      {"name_uf": "SC", "name": "Vargem"},
      {"name_uf": "SC", "name": "Vargem Bonita"},
      {"name_uf": "SC", "name": "Vidal Ramos"},
      {"name_uf": "SC", "name": "Videira"},
      {"name_uf": "SC", "name": "Vitor Meireles"},
      {"name_uf": "SC", "name": "Witmarsum"},
      {"name_uf": "SC", "name": "Xanxerê"},
      {"name_uf": "SC", "name": "Xavantina"},
      {"name_uf": "SC", "name": "Xaxim"},
      {"name_uf": "SC", "name": "Zortéa"},
      {"name_uf": "RS", "name": "Aceguá"},
      {"name_uf": "RS", "name": "Água Santa"},
      {"name_uf": "RS", "name": "Agudo"},
      {"name_uf": "RS", "name": "Ajuricaba"},
      {"name_uf": "RS", "name": "Alecrim"},
      {"name_uf": "RS", "name": "Alegrete"},
      {"name_uf": "RS", "name": "Alegria"},
      {"name_uf": "RS", "name": "Almirante Tamandaré do Sul"},
      {"name_uf": "RS", "name": "Alpestre"},
      {"name_uf": "RS", "name": "Alto Alegre"},
      {"name_uf": "RS", "name": "Alto Feliz"},
      {"name_uf": "RS", "name": "Alvorada"},
      {"name_uf": "RS", "name": "Amaral Ferrador"},
      {"name_uf": "RS", "name": "Ametista do Sul"},
      {"name_uf": "RS", "name": "André da Rocha"},
      {"name_uf": "RS", "name": "Anta Gorda"},
      {"name_uf": "RS", "name": "Antônio Prado"},
      {"name_uf": "RS", "name": "Arambaré"},
      {"name_uf": "RS", "name": "Araricá"},
      {"name_uf": "RS", "name": "Aratiba"},
      {"name_uf": "RS", "name": "Arroio do Meio"},
      {"name_uf": "RS", "name": "Arroio do Padre"},
      {"name_uf": "RS", "name": "Arroio do Sal"},
      {"name_uf": "RS", "name": "Arroio do Tigre"},
      {"name_uf": "RS", "name": "Arroio dos Ratos"},
      {"name_uf": "RS", "name": "Arroio Grande"},
      {"name_uf": "RS", "name": "Arvorezinha"},
      {"name_uf": "RS", "name": "Augusto Pestana"},
      {"name_uf": "RS", "name": "Áurea"},
      {"name_uf": "RS", "name": "Bagé"},
      {"name_uf": "RS", "name": "Balneário Pinhal"},
      {"name_uf": "RS", "name": "Barão"},
      {"name_uf": "RS", "name": "Barão de Cotegipe"},
      {"name_uf": "RS", "name": "Barão do Triunfo"},
      {"name_uf": "RS", "name": "Barra do Guarita"},
      {"name_uf": "RS", "name": "Barra do Quaraí"},
      {"name_uf": "RS", "name": "Barra do Ribeiro"},
      {"name_uf": "RS", "name": "Barra do Rio Azul"},
      {"name_uf": "RS", "name": "Barra Funda"},
      {"name_uf": "RS", "name": "Barracão"},
      {"name_uf": "RS", "name": "Barros Cassal"},
      {"name_uf": "RS", "name": "Benjamin Constant do Sul"},
      {"name_uf": "RS", "name": "Bento Gonçalves"},
      {"name_uf": "RS", "name": "Boa Vista das Missões"},
      {"name_uf": "RS", "name": "Boa Vista do Buricá"},
      {"name_uf": "RS", "name": "Boa Vista do Cadeado"},
      {"name_uf": "RS", "name": "Boa Vista do Incra"},
      {"name_uf": "RS", "name": "Boa Vista do Sul"},
      {"name_uf": "RS", "name": "Bom Jesus"},
      {"name_uf": "RS", "name": "Bom Princípio"},
      {"name_uf": "RS", "name": "Bom Progresso"},
      {"name_uf": "RS", "name": "Bom Retiro do Sul"},
      {"name_uf": "RS", "name": "Boqueirão do Leão"},
      {"name_uf": "RS", "name": "Bossoroca"},
      {"name_uf": "RS", "name": "Bozano"},
      {"name_uf": "RS", "name": "Braga"},
      {"name_uf": "RS", "name": "Brochier"},
      {"name_uf": "RS", "name": "Butiá"},
      {"name_uf": "RS", "name": "Caçapava do Sul"},
      {"name_uf": "RS", "name": "Cacequi"},
      {"name_uf": "RS", "name": "Cachoeira do Sul"},
      {"name_uf": "RS", "name": "Cachoeirinha"},
      {"name_uf": "RS", "name": "Cacique Doble"},
      {"name_uf": "RS", "name": "Caibaté"},
      {"name_uf": "RS", "name": "Caiçara"},
      {"name_uf": "RS", "name": "Camaquã"},
      {"name_uf": "RS", "name": "Camargo"},
      {"name_uf": "RS", "name": "Cambará do Sul"},
      {"name_uf": "RS", "name": "Campestre da Serra"},
      {"name_uf": "RS", "name": "Campina das Missões"},
      {"name_uf": "RS", "name": "Campinas do Sul"},
      {"name_uf": "RS", "name": "Campo Bom"},
      {"name_uf": "RS", "name": "Campo Novo"},
      {"name_uf": "RS", "name": "Campos Borges"},
      {"name_uf": "RS", "name": "Candelária"},
      {"name_uf": "RS", "name": "Cândido Godói"},
      {"name_uf": "RS", "name": "Candiota"},
      {"name_uf": "RS", "name": "Canela"},
      {"name_uf": "RS", "name": "Canguçu"},
      {"name_uf": "RS", "name": "Canoas"},
      {"name_uf": "RS", "name": "Canudos do Vale"},
      {"name_uf": "RS", "name": "Capão Bonito do Sul"},
      {"name_uf": "RS", "name": "Capão da Canoa"},
      {"name_uf": "RS", "name": "Capão do Cipó"},
      {"name_uf": "RS", "name": "Capão do Leão"},
      {"name_uf": "RS", "name": "Capela de Santana"},
      {"name_uf": "RS", "name": "Capitão"},
      {"name_uf": "RS", "name": "Capivari do Sul"},
      {"name_uf": "RS", "name": "Caraá"},
      {"name_uf": "RS", "name": "Carazinho"},
      {"name_uf": "RS", "name": "Carlos Barbosa"},
      {"name_uf": "RS", "name": "Carlos Gomes"},
      {"name_uf": "RS", "name": "Casca"},
      {"name_uf": "RS", "name": "Caseiros"},
      {"name_uf": "RS", "name": "Catuípe"},
      {"name_uf": "RS", "name": "Caxias do Sul"},
      {"name_uf": "RS", "name": "Centenário"},
      {"name_uf": "RS", "name": "Cerrito"},
      {"name_uf": "RS", "name": "Cerro Branco"},
      {"name_uf": "RS", "name": "Cerro Grande"},
      {"name_uf": "RS", "name": "Cerro Grande do Sul"},
      {"name_uf": "RS", "name": "Cerro Largo"},
      {"name_uf": "RS", "name": "Chapada"},
      {"name_uf": "RS", "name": "Charqueadas"},
      {"name_uf": "RS", "name": "Charrua"},
      {"name_uf": "RS", "name": "Chiapetta"},
      {"name_uf": "RS", "name": "Chuí"},
      {"name_uf": "RS", "name": "Chuvisca"},
      {"name_uf": "RS", "name": "Cidreira"},
      {"name_uf": "RS", "name": "Ciríaco"},
      {"name_uf": "RS", "name": "Colinas"},
      {"name_uf": "RS", "name": "Colorado"},
      {"name_uf": "RS", "name": "Condor"},
      {"name_uf": "RS", "name": "Constantina"},
      {"name_uf": "RS", "name": "Coqueiro Baixo"},
      {"name_uf": "RS", "name": "Coqueiros do Sul"},
      {"name_uf": "RS", "name": "Coronel Barros"},
      {"name_uf": "RS", "name": "Coronel Bicaco"},
      {"name_uf": "RS", "name": "Coronel Pilar"},
      {"name_uf": "RS", "name": "Cotiporã"},
      {"name_uf": "RS", "name": "Coxilha"},
      {"name_uf": "RS", "name": "Crissiumal"},
      {"name_uf": "RS", "name": "Cristal"},
      {"name_uf": "RS", "name": "Cristal do Sul"},
      {"name_uf": "RS", "name": "Cruz Alta"},
      {"name_uf": "RS", "name": "Cruzaltense"},
      {"name_uf": "RS", "name": "Cruzeiro do Sul"},
      {"name_uf": "RS", "name": "David Canabarro"},
      {"name_uf": "RS", "name": "Derrubadas"},
      {"name_uf": "RS", "name": "Dezesseis de Novembro"},
      {"name_uf": "RS", "name": "Dilermando de Aguiar"},
      {"name_uf": "RS", "name": "Dois Irmãos"},
      {"name_uf": "RS", "name": "Dois Irmãos das Missões"},
      {"name_uf": "RS", "name": "Dois Lajeados"},
      {"name_uf": "RS", "name": "Dom Feliciano"},
      {"name_uf": "RS", "name": "Dom Pedrito"},
      {"name_uf": "RS", "name": "Dom Pedro de Alcântara"},
      {"name_uf": "RS", "name": "Dona Francisca"},
      {"name_uf": "RS", "name": "Doutor Maurício Cardoso"},
      {"name_uf": "RS", "name": "Doutor Ricardo"},
      {"name_uf": "RS", "name": "Eldorado do Sul"},
      {"name_uf": "RS", "name": "Encantado"},
      {"name_uf": "RS", "name": "Encruzilhada do Sul"},
      {"name_uf": "RS", "name": "Engenho Velho"},
      {"name_uf": "RS", "name": "Entre Rios do Sul"},
      {"name_uf": "RS", "name": "Entre-Ijuís"},
      {"name_uf": "RS", "name": "Erebango"},
      {"name_uf": "RS", "name": "Erechim"},
      {"name_uf": "RS", "name": "Ernestina"},
      {"name_uf": "RS", "name": "Erval Grande"},
      {"name_uf": "RS", "name": "Erval Seco"},
      {"name_uf": "RS", "name": "Esmeralda"},
      {"name_uf": "RS", "name": "Esperança do Sul"},
      {"name_uf": "RS", "name": "Espumoso"},
      {"name_uf": "RS", "name": "Estação"},
      {"name_uf": "RS", "name": "Estância Velha"},
      {"name_uf": "RS", "name": "Esteio"},
      {"name_uf": "RS", "name": "Estrela"},
      {"name_uf": "RS", "name": "Estrela Velha"},
      {"name_uf": "RS", "name": "Eugênio de Castro"},
      {"name_uf": "RS", "name": "Fagundes Varela"},
      {"name_uf": "RS", "name": "Farroupilha"},
      {"name_uf": "RS", "name": "Faxinal do Soturno"},
      {"name_uf": "RS", "name": "Faxinalzinho"},
      {"name_uf": "RS", "name": "Fazenda Vilanova"},
      {"name_uf": "RS", "name": "Feliz"},
      {"name_uf": "RS", "name": "Flores da Cunha"},
      {"name_uf": "RS", "name": "Floriano Peixoto"},
      {"name_uf": "RS", "name": "Fontoura Xavier"},
      {"name_uf": "RS", "name": "Formigueiro"},
      {"name_uf": "RS", "name": "Forquetinha"},
      {"name_uf": "RS", "name": "Fortaleza dos Valos"},
      {"name_uf": "RS", "name": "Frederico Westphalen"},
      {"name_uf": "RS", "name": "Garibaldi"},
      {"name_uf": "RS", "name": "Garruchos"},
      {"name_uf": "RS", "name": "Gaurama"},
      {"name_uf": "RS", "name": "General Câmara"},
      {"name_uf": "RS", "name": "Gentil"},
      {"name_uf": "RS", "name": "Getúlio Vargas"},
      {"name_uf": "RS", "name": "Giruá"},
      {"name_uf": "RS", "name": "Glorinha"},
      {"name_uf": "RS", "name": "Gramado"},
      {"name_uf": "RS", "name": "Gramado dos Loureiros"},
      {"name_uf": "RS", "name": "Gramado Xavier"},
      {"name_uf": "RS", "name": "Gravataí"},
      {"name_uf": "RS", "name": "Guabiju"},
      {"name_uf": "RS", "name": "Guaíba"},
      {"name_uf": "RS", "name": "Guaporé"},
      {"name_uf": "RS", "name": "Guarani das Missões"},
      {"name_uf": "RS", "name": "Harmonia"},
      {"name_uf": "RS", "name": "Herval"},
      {"name_uf": "RS", "name": "Herveiras"},
      {"name_uf": "RS", "name": "Horizontina"},
      {"name_uf": "RS", "name": "Hulha Negra"},
      {"name_uf": "RS", "name": "Humaitá"},
      {"name_uf": "RS", "name": "Ibarama"},
      {"name_uf": "RS", "name": "Ibiaçá"},
      {"name_uf": "RS", "name": "Ibiraiaras"},
      {"name_uf": "RS", "name": "Ibirapuitã"},
      {"name_uf": "RS", "name": "Ibirubá"},
      {"name_uf": "RS", "name": "Igrejinha"},
      {"name_uf": "RS", "name": "Ijuí"},
      {"name_uf": "RS", "name": "Ilópolis"},
      {"name_uf": "RS", "name": "Imbé"},
      {"name_uf": "RS", "name": "Imigrante"},
      {"name_uf": "RS", "name": "Independência"},
      {"name_uf": "RS", "name": "Inhacorá"},
      {"name_uf": "RS", "name": "Ipê"},
      {"name_uf": "RS", "name": "Ipiranga do Sul"},
      {"name_uf": "RS", "name": "Iraí"},
      {"name_uf": "RS", "name": "Itaara"},
      {"name_uf": "RS", "name": "Itacurubi"},
      {"name_uf": "RS", "name": "Itapuca"},
      {"name_uf": "RS", "name": "Itaqui"},
      {"name_uf": "RS", "name": "Itati"},
      {"name_uf": "RS", "name": "Itatiba do Sul"},
      {"name_uf": "RS", "name": "Ivorá"},
      {"name_uf": "RS", "name": "Ivoti"},
      {"name_uf": "RS", "name": "Jaboticaba"},
      {"name_uf": "RS", "name": "Jacuizinho"},
      {"name_uf": "RS", "name": "Jacutinga"},
      {"name_uf": "RS", "name": "Jaguarão"},
      {"name_uf": "RS", "name": "Jaguari"},
      {"name_uf": "RS", "name": "Jaquirana"},
      {"name_uf": "RS", "name": "Jari"},
      {"name_uf": "RS", "name": "Jóia"},
      {"name_uf": "RS", "name": "Júlio de Castilhos"},
      {"name_uf": "RS", "name": "Lagoa Bonita do Sul"},
      {"name_uf": "RS", "name": "Lagoa dos Três Cantos"},
      {"name_uf": "RS", "name": "Lagoa Vermelha"},
      {"name_uf": "RS", "name": "Lagoão"},
      {"name_uf": "RS", "name": "Lajeado"},
      {"name_uf": "RS", "name": "Lajeado do Bugre"},
      {"name_uf": "RS", "name": "Lavras do Sul"},
      {"name_uf": "RS", "name": "Liberato Salzano"},
      {"name_uf": "RS", "name": "Lindolfo Collor"},
      {"name_uf": "RS", "name": "Linha Nova"},
      {"name_uf": "RS", "name": "Maçambará"},
      {"name_uf": "RS", "name": "Machadinho"},
      {"name_uf": "RS", "name": "Mampituba"},
      {"name_uf": "RS", "name": "Manoel Viana"},
      {"name_uf": "RS", "name": "Maquiné"},
      {"name_uf": "RS", "name": "Maratá"},
      {"name_uf": "RS", "name": "Marau"},
      {"name_uf": "RS", "name": "Marcelino Ramos"},
      {"name_uf": "RS", "name": "Mariana Pimentel"},
      {"name_uf": "RS", "name": "Mariano Moro"},
      {"name_uf": "RS", "name": "Marques de Souza"},
      {"name_uf": "RS", "name": "Mata"},
      {"name_uf": "RS", "name": "Mato Castelhano"},
      {"name_uf": "RS", "name": "Mato Leitão"},
      {"name_uf": "RS", "name": "Mato Queimado"},
      {"name_uf": "RS", "name": "Maximiliano de Almeida"},
      {"name_uf": "RS", "name": "Minas do Leão"},
      {"name_uf": "RS", "name": "Miraguaí"},
      {"name_uf": "RS", "name": "Montauri"},
      {"name_uf": "RS", "name": "Monte Alegre dos Campos"},
      {"name_uf": "RS", "name": "Monte Belo do Sul"},
      {"name_uf": "RS", "name": "Montenegro"},
      {"name_uf": "RS", "name": "Mormaço"},
      {"name_uf": "RS", "name": "Morrinhos do Sul"},
      {"name_uf": "RS", "name": "Morro Redondo"},
      {"name_uf": "RS", "name": "Morro Reuter"},
      {"name_uf": "RS", "name": "Mostardas"},
      {"name_uf": "RS", "name": "Muçum"},
      {"name_uf": "RS", "name": "Muitos Capões"},
      {"name_uf": "RS", "name": "Muliterno"},
      {"name_uf": "RS", "name": "Não-Me-Toque"},
      {"name_uf": "RS", "name": "Nicolau Vergueiro"},
      {"name_uf": "RS", "name": "Nonoai"},
      {"name_uf": "RS", "name": "Nova Alvorada"},
      {"name_uf": "RS", "name": "Nova Araçá"},
      {"name_uf": "RS", "name": "Nova Bassano"},
      {"name_uf": "RS", "name": "Nova Boa Vista"},
      {"name_uf": "RS", "name": "Nova Bréscia"},
      {"name_uf": "RS", "name": "Nova Candelária"},
      {"name_uf": "RS", "name": "Nova Esperança do Sul"},
      {"name_uf": "RS", "name": "Nova Hartz"},
      {"name_uf": "RS", "name": "Nova Pádua"},
      {"name_uf": "RS", "name": "Nova Palma"},
      {"name_uf": "RS", "name": "Nova Petrópolis"},
      {"name_uf": "RS", "name": "Nova Prata"},
      {"name_uf": "RS", "name": "Nova Ramada"},
      {"name_uf": "RS", "name": "Nova Roma do Sul"},
      {"name_uf": "RS", "name": "Nova Santa Rita"},
      {"name_uf": "RS", "name": "Novo Barreiro"},
      {"name_uf": "RS", "name": "Novo Cabrais"},
      {"name_uf": "RS", "name": "Novo Hamburgo"},
      {"name_uf": "RS", "name": "Novo Machado"},
      {"name_uf": "RS", "name": "Novo Tiradentes"},
      {"name_uf": "RS", "name": "Novo Xingu"},
      {"name_uf": "RS", "name": "Osório"},
      {"name_uf": "RS", "name": "Paim Filho"},
      {"name_uf": "RS", "name": "Palmares do Sul"},
      {"name_uf": "RS", "name": "Palmeira das Missões"},
      {"name_uf": "RS", "name": "Palmitinho"},
      {"name_uf": "RS", "name": "Panambi"},
      {"name_uf": "RS", "name": "Pantano Grande"},
      {"name_uf": "RS", "name": "Paraí"},
      {"name_uf": "RS", "name": "Paraíso do Sul"},
      {"name_uf": "RS", "name": "Pareci Novo"},
      {"name_uf": "RS", "name": "Parobé"},
      {"name_uf": "RS", "name": "Passa Sete"},
      {"name_uf": "RS", "name": "Passo do Sobrado"},
      {"name_uf": "RS", "name": "Passo Fundo"},
      {"name_uf": "RS", "name": "Paulo Bento"},
      {"name_uf": "RS", "name": "Paverama"},
      {"name_uf": "RS", "name": "Pedras Altas"},
      {"name_uf": "RS", "name": "Pedro Osório"},
      {"name_uf": "RS", "name": "Pejuçara"},
      {"name_uf": "RS", "name": "Pelotas"},
      {"name_uf": "RS", "name": "Picada Café"},
      {"name_uf": "RS", "name": "Pinhal"},
      {"name_uf": "RS", "name": "Pinhal da Serra"},
      {"name_uf": "RS", "name": "Pinhal Grande"},
      {"name_uf": "RS", "name": "Pinheirinho do Vale"},
      {"name_uf": "RS", "name": "Pinheiro Machado"},
      {"name_uf": "RS", "name": "Pinto Bandeira"},
      {"name_uf": "RS", "name": "Pirapó"},
      {"name_uf": "RS", "name": "Piratini"},
      {"name_uf": "RS", "name": "Planalto"},
      {"name_uf": "RS", "name": "Poço das Antas"},
      {"name_uf": "RS", "name": "Pontão"},
      {"name_uf": "RS", "name": "Ponte Preta"},
      {"name_uf": "RS", "name": "Portão"},
      {"name_uf": "RS", "name": "Porto Alegre"},
      {"name_uf": "RS", "name": "Porto Lucena"},
      {"name_uf": "RS", "name": "Porto Mauá"},
      {"name_uf": "RS", "name": "Porto Vera Cruz"},
      {"name_uf": "RS", "name": "Porto Xavier"},
      {"name_uf": "RS", "name": "Pouso Novo"},
      {"name_uf": "RS", "name": "Presidente Lucena"},
      {"name_uf": "RS", "name": "Progresso"},
      {"name_uf": "RS", "name": "Protásio Alves"},
      {"name_uf": "RS", "name": "Putinga"},
      {"name_uf": "RS", "name": "Quaraí"},
      {"name_uf": "RS", "name": "Quatro Irmãos"},
      {"name_uf": "RS", "name": "Quevedos"},
      {"name_uf": "RS", "name": "Quinze de Novembro"},
      {"name_uf": "RS", "name": "Redentora"},
      {"name_uf": "RS", "name": "Relvado"},
      {"name_uf": "RS", "name": "Restinga Sêca"},
      {"name_uf": "RS", "name": "Rio dos Índios"},
      {"name_uf": "RS", "name": "Rio Grande"},
      {"name_uf": "RS", "name": "Rio Pardo"},
      {"name_uf": "RS", "name": "Riozinho"},
      {"name_uf": "RS", "name": "Roca Sales"},
      {"name_uf": "RS", "name": "Rodeio Bonito"},
      {"name_uf": "RS", "name": "Rolador"},
      {"name_uf": "RS", "name": "Rolante"},
      {"name_uf": "RS", "name": "Ronda Alta"},
      {"name_uf": "RS", "name": "Rondinha"},
      {"name_uf": "RS", "name": "Roque Gonzales"},
      {"name_uf": "RS", "name": "Rosário do Sul"},
      {"name_uf": "RS", "name": "Sagrada Família"},
      {"name_uf": "RS", "name": "Saldanha Marinho"},
      {"name_uf": "RS", "name": "Salto do Jacuí"},
      {"name_uf": "RS", "name": "Salvador das Missões"},
      {"name_uf": "RS", "name": "Salvador do Sul"},
      {"name_uf": "RS", "name": "Sananduva"},
      {"name_uf": "RS", "name": "Santa Bárbara do Sul"},
      {"name_uf": "RS", "name": "Santa Cecília do Sul"},
      {"name_uf": "RS", "name": "Santa Clara do Sul"},
      {"name_uf": "RS", "name": "Santa Cruz do Sul"},
      {"name_uf": "RS", "name": "Santa Margarida do Sul"},
      {"name_uf": "RS", "name": "Santa Maria"},
      {"name_uf": "RS", "name": "Santa Maria do Herval"},
      {"name_uf": "RS", "name": "Santa Rosa"},
      {"name_uf": "RS", "name": "Santa Tereza"},
      {"name_uf": "RS", "name": "Santa Vitória do Palmar"},
      {"name_uf": "RS", "name": "Santana da Boa Vista"},
      {"name_uf": "RS", "name": "Sant'Ana do Livramento"},
      {"name_uf": "RS", "name": "Santiago"},
      {"name_uf": "RS", "name": "Santo Ângelo"},
      {"name_uf": "RS", "name": "Santo Antônio da Patrulha"},
      {"name_uf": "RS", "name": "Santo Antônio das Missões"},
      {"name_uf": "RS", "name": "Santo Antônio do Palma"},
      {"name_uf": "RS", "name": "Santo Antônio do Planalto"},
      {"name_uf": "RS", "name": "Santo Augusto"},
      {"name_uf": "RS", "name": "Santo Cristo"},
      {"name_uf": "RS", "name": "Santo Expedito do Sul"},
      {"name_uf": "RS", "name": "São Borja"},
      {"name_uf": "RS", "name": "São Domingos do Sul"},
      {"name_uf": "RS", "name": "São Francisco de Assis"},
      {"name_uf": "RS", "name": "São Francisco de Paula"},
      {"name_uf": "RS", "name": "São Gabriel"},
      {"name_uf": "RS", "name": "São Jerônimo"},
      {"name_uf": "RS", "name": "São João da Urtiga"},
      {"name_uf": "RS", "name": "São João do Polêsine"},
      {"name_uf": "RS", "name": "São Jorge"},
      {"name_uf": "RS", "name": "São José das Missões"},
      {"name_uf": "RS", "name": "São José do Herval"},
      {"name_uf": "RS", "name": "São José do Hortêncio"},
      {"name_uf": "RS", "name": "São José do Inhacorá"},
      {"name_uf": "RS", "name": "São José do Norte"},
      {"name_uf": "RS", "name": "São José do Ouro"},
      {"name_uf": "RS", "name": "São José do Sul"},
      {"name_uf": "RS", "name": "São José dos Ausentes"},
      {"name_uf": "RS", "name": "São Leopoldo"},
      {"name_uf": "RS", "name": "São Lourenço do Sul"},
      {"name_uf": "RS", "name": "São Luiz Gonzaga"},
      {"name_uf": "RS", "name": "São Marcos"},
      {"name_uf": "RS", "name": "São Martinho"},
      {"name_uf": "RS", "name": "São Martinho da Serra"},
      {"name_uf": "RS", "name": "São Miguel das Missões"},
      {"name_uf": "RS", "name": "São Nicolau"},
      {"name_uf": "RS", "name": "SP das Missões"},
      {"name_uf": "RS", "name": "São Pedro da Serra"},
      {"name_uf": "RS", "name": "São Pedro das Missões"},
      {"name_uf": "RS", "name": "São Pedro do Butiá"},
      {"name_uf": "RS", "name": "São Pedro do Sul"},
      {"name_uf": "RS", "name": "São Sebastião do Caí"},
      {"name_uf": "RS", "name": "São Sepé"},
      {"name_uf": "RS", "name": "São Valentim"},
      {"name_uf": "RS", "name": "São Valentim do Sul"},
      {"name_uf": "RS", "name": "São Valério do Sul"},
      {"name_uf": "RS", "name": "São Vendelino"},
      {"name_uf": "RS", "name": "São Vicente do Sul"},
      {"name_uf": "RS", "name": "Sapiranga"},
      {"name_uf": "RS", "name": "Sapucaia do Sul"},
      {"name_uf": "RS", "name": "Sarandi"},
      {"name_uf": "RS", "name": "Seberi"},
      {"name_uf": "RS", "name": "Sede Nova"},
      {"name_uf": "RS", "name": "Segredo"},
      {"name_uf": "RS", "name": "Selbach"},
      {"name_uf": "RS", "name": "Senador Salgado Filho"},
      {"name_uf": "RS", "name": "Sentinela do Sul"},
      {"name_uf": "RS", "name": "Serafina Corrêa"},
      {"name_uf": "RS", "name": "Sério"},
      {"name_uf": "RS", "name": "Sertão"},
      {"name_uf": "RS", "name": "Sertão Santana"},
      {"name_uf": "RS", "name": "Sete de Setembro"},
      {"name_uf": "RS", "name": "Severiano de Almeida"},
      {"name_uf": "RS", "name": "Silveira Martins"},
      {"name_uf": "RS", "name": "Sinimbu"},
      {"name_uf": "RS", "name": "Sobradinho"},
      {"name_uf": "RS", "name": "Soledade"},
      {"name_uf": "RS", "name": "Tabaí"},
      {"name_uf": "RS", "name": "Tapejara"},
      {"name_uf": "RS", "name": "Tapera"},
      {"name_uf": "RS", "name": "Tapes"},
      {"name_uf": "RS", "name": "Taquara"},
      {"name_uf": "RS", "name": "Taquari"},
      {"name_uf": "RS", "name": "Taquaruçu do Sul"},
      {"name_uf": "RS", "name": "Tavares"},
      {"name_uf": "RS", "name": "Tenente Portela"},
      {"name_uf": "RS", "name": "Terra de Areia"},
      {"name_uf": "RS", "name": "Teutônia"},
      {"name_uf": "RS", "name": "Tio Hugo"},
      {"name_uf": "RS", "name": "Tiradentes do Sul"},
      {"name_uf": "RS", "name": "Toropi"},
      {"name_uf": "RS", "name": "Torres"},
      {"name_uf": "RS", "name": "Tramandaí"},
      {"name_uf": "RS", "name": "Travesseiro"},
      {"name_uf": "RS", "name": "Três Arroios"},
      {"name_uf": "RS", "name": "Três Cachoeiras"},
      {"name_uf": "RS", "name": "Três Coroas"},
      {"name_uf": "RS", "name": "Três de Maio"},
      {"name_uf": "RS", "name": "Três Forquilhas"},
      {"name_uf": "RS", "name": "Três Palmeiras"},
      {"name_uf": "RS", "name": "Três Passos"},
      {"name_uf": "RS", "name": "Trindade do Sul"},
      {"name_uf": "RS", "name": "Triunfo"},
      {"name_uf": "RS", "name": "Tucunduva"},
      {"name_uf": "RS", "name": "Tunas"},
      {"name_uf": "RS", "name": "Tupanci do Sul"},
      {"name_uf": "RS", "name": "Tupanciretã"},
      {"name_uf": "RS", "name": "Tupandi"},
      {"name_uf": "RS", "name": "Tuparendi"},
      {"name_uf": "RS", "name": "Turuçu"},
      {"name_uf": "RS", "name": "Ubiretama"},
      {"name_uf": "RS", "name": "União da Serra"},
      {"name_uf": "RS", "name": "Unistalda"},
      {"name_uf": "RS", "name": "Uruguaiana"},
      {"name_uf": "RS", "name": "Vacaria"},
      {"name_uf": "RS", "name": "Vale do Sol"},
      {"name_uf": "RS", "name": "Vale Real"},
      {"name_uf": "RS", "name": "Vale Verde"},
      {"name_uf": "RS", "name": "Vanini"},
      {"name_uf": "RS", "name": "Venâncio Aires"},
      {"name_uf": "RS", "name": "Vera Cruz"},
      {"name_uf": "RS", "name": "Veranópolis"},
      {"name_uf": "RS", "name": "Vespasiano Corrêa"},
      {"name_uf": "RS", "name": "Viadutos"},
      {"name_uf": "RS", "name": "Viamão"},
      {"name_uf": "RS", "name": "Vicente Dutra"},
      {"name_uf": "RS", "name": "Victor Graeff"},
      {"name_uf": "RS", "name": "Vila Flores"},
      {"name_uf": "RS", "name": "Vila Lângaro"},
      {"name_uf": "RS", "name": "Vila Maria"},
      {"name_uf": "RS", "name": "Vila Nova do Sul"},
      {"name_uf": "RS", "name": "Vista Alegre"},
      {"name_uf": "RS", "name": "Vista Alegre do Prata"},
      {"name_uf": "RS", "name": "Vista Gaúcha"},
      {"name_uf": "RS", "name": "Vitória das Missões"},
      {"name_uf": "RS", "name": "Westfália"},
      {"name_uf": "RS", "name": "Xangri-lá"},
      {"name_uf": "MS", "name": "Água Clara"},
      {"name_uf": "MS", "name": "Alcinópolis"},
      {"name_uf": "MS", "name": "Amambai"},
      {"name_uf": "MS", "name": "Anastácio"},
      {"name_uf": "MS", "name": "Anaurilândia"},
      {"name_uf": "MS", "name": "Angélica"},
      {"name_uf": "MS", "name": "Antônio João"},
      {"name_uf": "MS", "name": "Aparecida do Taboado"},
      {"name_uf": "MS", "name": "Aquidauana"},
      {"name_uf": "MS", "name": "Aral Moreira"},
      {"name_uf": "MS", "name": "Bandeirantes"},
      {"name_uf": "MS", "name": "Bataguassu"},
      {"name_uf": "MS", "name": "Batayporã"},
      {"name_uf": "MS", "name": "Bela Vista"},
      {"name_uf": "MS", "name": "Bodoquena"},
      {"name_uf": "MS", "name": "Bonito"},
      {"name_uf": "MS", "name": "Brasilândia"},
      {"name_uf": "MS", "name": "Caarapó"},
      {"name_uf": "MS", "name": "Camapuã"},
      {"name_uf": "MS", "name": "Campo Grande"},
      {"name_uf": "MS", "name": "Caracol"},
      {"name_uf": "MS", "name": "Cassilândia"},
      {"name_uf": "MS", "name": "Chapadão do Sul"},
      {"name_uf": "MS", "name": "Corguinho"},
      {"name_uf": "MS", "name": "Coronel Sapucaia"},
      {"name_uf": "MS", "name": "Corumbá"},
      {"name_uf": "MS", "name": "Costa Rica"},
      {"name_uf": "MS", "name": "Coxim"},
      {"name_uf": "MS", "name": "Deodápolis"},
      {"name_uf": "MS", "name": "Dois Irmãos do Buriti"},
      {"name_uf": "MS", "name": "Douradina"},
      {"name_uf": "MS", "name": "Dourados"},
      {"name_uf": "MS", "name": "Eldorado"},
      {"name_uf": "MS", "name": "Fátima do Sul"},
      {"name_uf": "MS", "name": "Figueirão"},
      {"name_uf": "MS", "name": "Glória de Dourados"},
      {"name_uf": "MS", "name": "Guia Lopes da Laguna"},
      {"name_uf": "MS", "name": "Iguatemi"},
      {"name_uf": "MS", "name": "Inocência"},
      {"name_uf": "MS", "name": "Itaporã"},
      {"name_uf": "MS", "name": "Itaquiraí"},
      {"name_uf": "MS", "name": "Ivinhema"},
      {"name_uf": "MS", "name": "Japorã"},
      {"name_uf": "MS", "name": "Jaraguari"},
      {"name_uf": "MS", "name": "Jardim"},
      {"name_uf": "MS", "name": "Jateí"},
      {"name_uf": "MS", "name": "Juti"},
      {"name_uf": "MS", "name": "Ladário"},
      {"name_uf": "MS", "name": "Laguna Carapã"},
      {"name_uf": "MS", "name": "Maracaju"},
      {"name_uf": "MS", "name": "Miranda"},
      {"name_uf": "MS", "name": "Mundo Novo"},
      {"name_uf": "MS", "name": "Naviraí"},
      {"name_uf": "MS", "name": "Nioaque"},
      {"name_uf": "MS", "name": "Nova Alvorada do Sul"},
      {"name_uf": "MS", "name": "Nova Andradina"},
      {"name_uf": "MS", "name": "Novo Horizonte do Sul"},
      {"name_uf": "MS", "name": "Paraíso das Águas"},
      {"name_uf": "MS", "name": "Paranaíba"},
      {"name_uf": "MS", "name": "Paranhos"},
      {"name_uf": "MS", "name": "Pedro Gomes"},
      {"name_uf": "MS", "name": "Ponta Porã"},
      {"name_uf": "MS", "name": "Porto Murtinho"},
      {"name_uf": "MS", "name": "Ribas do Rio Pardo"},
      {"name_uf": "MS", "name": "Rio Brilhante"},
      {"name_uf": "MS", "name": "Rio Negro"},
      {"name_uf": "MS", "name": "Rio Verde de MT"},
      {"name_uf": "MS", "name": "Rochedo"},
      {"name_uf": "MS", "name": "Santa Rita do Pardo"},
      {"name_uf": "MS", "name": "São Gabriel do Oeste"},
      {"name_uf": "MS", "name": "Selvíria"},
      {"name_uf": "MS", "name": "Sete Quedas"},
      {"name_uf": "MS", "name": "Sidrolândia"},
      {"name_uf": "MS", "name": "Sonora"},
      {"name_uf": "MS", "name": "Tacuru"},
      {"name_uf": "MS", "name": "Taquarussu"},
      {"name_uf": "MS", "name": "Terenos"},
      {"name_uf": "MS", "name": "Três Lagoas"},
      {"name_uf": "MS", "name": "Vicentina"},
      {"name_uf": "MT", "name": "Acorizal"},
      {"name_uf": "MT", "name": "Água Boa"},
      {"name_uf": "MT", "name": "Alta Floresta"},
      {"name_uf": "MT", "name": "Alto Araguaia"},
      {"name_uf": "MT", "name": "Alto Boa Vista"},
      {"name_uf": "MT", "name": "Alto Garças"},
      {"name_uf": "MT", "name": "Alto Paraguai"},
      {"name_uf": "MT", "name": "Alto Taquari"},
      {"name_uf": "MT", "name": "Apiacás"},
      {"name_uf": "MT", "name": "Araguaiana"},
      {"name_uf": "MT", "name": "Araguainha"},
      {"name_uf": "MT", "name": "Araputanga"},
      {"name_uf": "MT", "name": "Arenápolis"},
      {"name_uf": "MT", "name": "Aripuanã"},
      {"name_uf": "MT", "name": "Barão de Melgaço"},
      {"name_uf": "MT", "name": "Barra do Bugres"},
      {"name_uf": "MT", "name": "Barra do Garças"},
      {"name_uf": "MT", "name": "Bom Jesus do Araguaia"},
      {"name_uf": "MT", "name": "Brasnorte"},
      {"name_uf": "MT", "name": "Cáceres"},
      {"name_uf": "MT", "name": "Campinápolis"},
      {"name_uf": "MT", "name": "Campo Novo do Parecis"},
      {"name_uf": "MT", "name": "Campo Verde"},
      {"name_uf": "MT", "name": "Campos de Júlio"},
      {"name_uf": "MT", "name": "Canabrava do Norte"},
      {"name_uf": "MT", "name": "Canarana"},
      {"name_uf": "MT", "name": "Carlinda"},
      {"name_uf": "MT", "name": "Castanheira"},
      {"name_uf": "MT", "name": "Chapada dos Guimarães"},
      {"name_uf": "MT", "name": "Cláudia"},
      {"name_uf": "MT", "name": "Cocalinho"},
      {"name_uf": "MT", "name": "Colíder"},
      {"name_uf": "MT", "name": "Colniza"},
      {"name_uf": "MT", "name": "Comodoro"},
      {"name_uf": "MT", "name": "Confresa"},
      {"name_uf": "MT", "name": "Conquista D'Oeste"},
      {"name_uf": "MT", "name": "Cotriguaçu"},
      {"name_uf": "MT", "name": "Cuiabá"},
      {"name_uf": "MT", "name": "Curvelândia"},
      {"name_uf": "MT", "name": "Denise"},
      {"name_uf": "MT", "name": "Diamantino"},
      {"name_uf": "MT", "name": "Dom Aquino"},
      {"name_uf": "MT", "name": "Feliz Natal"},
      {"name_uf": "MT", "name": "Figueirópolis D'Oeste"},
      {"name_uf": "MT", "name": "Gaúcha do Norte"},
      {"name_uf": "MT", "name": "General Carneiro"},
      {"name_uf": "MT", "name": "Glória D'Oeste"},
      {"name_uf": "MT", "name": "Guarantã do Norte"},
      {"name_uf": "MT", "name": "Guiratinga"},
      {"name_uf": "MT", "name": "Indiavaí"},
      {"name_uf": "MT", "name": "Ipiranga do Norte"},
      {"name_uf": "MT", "name": "Itanhangá"},
      {"name_uf": "MT", "name": "Itaúba"},
      {"name_uf": "MT", "name": "Itiquira"},
      {"name_uf": "MT", "name": "Jaciara"},
      {"name_uf": "MT", "name": "Jangada"},
      {"name_uf": "MT", "name": "Jauru"},
      {"name_uf": "MT", "name": "Juara"},
      {"name_uf": "MT", "name": "Juína"},
      {"name_uf": "MT", "name": "Juruena"},
      {"name_uf": "MT", "name": "Juscimeira"},
      {"name_uf": "MT", "name": "Lambari D'Oeste"},
      {"name_uf": "MT", "name": "Lucas do Rio Verde"},
      {"name_uf": "MT", "name": "Luciara"},
      {"name_uf": "MT", "name": "Marcelândia"},
      {"name_uf": "MT", "name": "Matupá"},
      {"name_uf": "MT", "name": "Mirassol d'Oeste"},
      {"name_uf": "MT", "name": "Nobres"},
      {"name_uf": "MT", "name": "Nortelândia"},
      {"name_uf": "MT", "name": "Nossa Senhora do Livramento"},
      {"name_uf": "MT", "name": "Nova Bandeirantes"},
      {"name_uf": "MT", "name": "Nova Brasilândia"},
      {"name_uf": "MT", "name": "Nova Canaã do Norte"},
      {"name_uf": "MT", "name": "Nova Guarita"},
      {"name_uf": "MT", "name": "Nova Lacerda"},
      {"name_uf": "MT", "name": "Nova Marilândia"},
      {"name_uf": "MT", "name": "Nova Maringá"},
      {"name_uf": "MT", "name": "Nova Monte Verde"},
      {"name_uf": "MT", "name": "Nova Mutum"},
      {"name_uf": "MT", "name": "Nova Nazaré"},
      {"name_uf": "MT", "name": "Nova Olímpia"},
      {"name_uf": "MT", "name": "Nova Santa Helena"},
      {"name_uf": "MT", "name": "Nova Ubiratã"},
      {"name_uf": "MT", "name": "Nova Xavantina"},
      {"name_uf": "MT", "name": "Novo Horizonte do Norte"},
      {"name_uf": "MT", "name": "Novo Mundo"},
      {"name_uf": "MT", "name": "Novo Santo Antônio"},
      {"name_uf": "MT", "name": "Novo São Joaquim"},
      {"name_uf": "MT", "name": "Paranaíta"},
      {"name_uf": "MT", "name": "Paranatinga"},
      {"name_uf": "MT", "name": "Pedra Preta"},
      {"name_uf": "MT", "name": "Peixoto de Azevedo"},
      {"name_uf": "MT", "name": "Planalto da Serra"},
      {"name_uf": "MT", "name": "Poconé"},
      {"name_uf": "MT", "name": "Pontal do Araguaia"},
      {"name_uf": "MT", "name": "Ponte Branca"},
      {"name_uf": "MT", "name": "Pontes e Lacerda"},
      {"name_uf": "MT", "name": "Porto Alegre do Norte"},
      {"name_uf": "MT", "name": "Porto dos Gaúchos"},
      {"name_uf": "MT", "name": "Porto Esperidião"},
      {"name_uf": "MT", "name": "Porto Estrela"},
      {"name_uf": "MT", "name": "Poxoréu"},
      {"name_uf": "MT", "name": "Primavera do Leste"},
      {"name_uf": "MT", "name": "Querência"},
      {"name_uf": "MT", "name": "Reserva do Cabaçal"},
      {"name_uf": "MT", "name": "Ribeirão Cascalheira"},
      {"name_uf": "MT", "name": "Ribeirãozinho"},
      {"name_uf": "MT", "name": "Rio Branco"},
      {"name_uf": "MT", "name": "Rondolândia"},
      {"name_uf": "MT", "name": "Rondonópolis"},
      {"name_uf": "MT", "name": "Rosário Oeste"},
      {"name_uf": "MT", "name": "Salto do Céu"},
      {"name_uf": "MT", "name": "Santa Carmem"},
      {"name_uf": "MT", "name": "Santa Cruz do Xingu"},
      {"name_uf": "MT", "name": "Santa Rita do Trivelato"},
      {"name_uf": "MT", "name": "Santa Terezinha"},
      {"name_uf": "MT", "name": "Santo Afonso"},
      {"name_uf": "MT", "name": "Santo Antônio do Leste"},
      {"name_uf": "MT", "name": "Santo Antônio do Leverger"},
      {"name_uf": "MT", "name": "São Félix do Araguaia"},
      {"name_uf": "MT", "name": "São José do Povo"},
      {"name_uf": "MT", "name": "São José do Rio Claro"},
      {"name_uf": "MT", "name": "São José do Xingu"},
      {"name_uf": "MT", "name": "São José dos Quatro Marcos"},
      {"name_uf": "MT", "name": "São Pedro da Cipa"},
      {"name_uf": "MT", "name": "Sapezal"},
      {"name_uf": "MT", "name": "Serra Nova Dourada"},
      {"name_uf": "MT", "name": "Sinop"},
      {"name_uf": "MT", "name": "Sorriso"},
      {"name_uf": "MT", "name": "Tabaporã"},
      {"name_uf": "MT", "name": "Tangará da Serra"},
      {"name_uf": "MT", "name": "Tapurah"},
      {"name_uf": "MT", "name": "Terra Nova do Norte"},
      {"name_uf": "MT", "name": "Tesouro"},
      {"name_uf": "MT", "name": "Torixoréu"},
      {"name_uf": "MT", "name": "União do Sul"},
      {"name_uf": "MT", "name": "Vale de São Domingos"},
      {"name_uf": "MT", "name": "Várzea Grande"},
      {"name_uf": "MT", "name": "Vera"},
      {"name_uf": "MT", "name": "Vila Bela da Santíssima Trindade"},
      {"name_uf": "MT", "name": "Vila Rica"},
      {"name_uf": "GO", "name": "Abadia de GO"},
      {"name_uf": "GO", "name": "Abadiânia"},
      {"name_uf": "GO", "name": "ACúna"},
      {"name_uf": "GO", "name": "Adelândia"},
      {"name_uf": "GO", "name": "Água Fria de GO"},
      {"name_uf": "GO", "name": "Água Limpa"},
      {"name_uf": "GO", "name": "Águas Lindas de GO"},
      {"name_uf": "GO", "name": "Alexânia"},
      {"name_uf": "GO", "name": "Aloândia"},
      {"name_uf": "GO", "name": "Alto Horizonte"},
      {"name_uf": "GO", "name": "Alto Paraíso de GO"},
      {"name_uf": "GO", "name": "Alvorada do Norte"},
      {"name_uf": "GO", "name": "Amaralina"},
      {"name_uf": "GO", "name": "Americano do Brasil"},
      {"name_uf": "GO", "name": "Amorinópolis"},
      {"name_uf": "GO", "name": "Anápolis"},
      {"name_uf": "GO", "name": "Anhanguera"},
      {"name_uf": "GO", "name": "Anicuns"},
      {"name_uf": "GO", "name": "Aparecida de Goiânia"},
      {"name_uf": "GO", "name": "Aparecida do Rio Doce"},
      {"name_uf": "GO", "name": "Aporé"},
      {"name_uf": "GO", "name": "Araçu"},
      {"name_uf": "GO", "name": "Aragarças"},
      {"name_uf": "GO", "name": "Aragoiânia"},
      {"name_uf": "GO", "name": "Araguapaz"},
      {"name_uf": "GO", "name": "Arenópolis"},
      {"name_uf": "GO", "name": "Aruanã"},
      {"name_uf": "GO", "name": "Aurilândia"},
      {"name_uf": "GO", "name": "Avelinópolis"},
      {"name_uf": "GO", "name": "Baliza"},
      {"name_uf": "GO", "name": "Barro Alto"},
      {"name_uf": "GO", "name": "Bela Vista de GO"},
      {"name_uf": "GO", "name": "Bom Jardim de GO"},
      {"name_uf": "GO", "name": "Bom Jesus de GO"},
      {"name_uf": "GO", "name": "Bonfinópolis"},
      {"name_uf": "GO", "name": "Bonópolis"},
      {"name_uf": "GO", "name": "Brazabrantes"},
      {"name_uf": "GO", "name": "Britânia"},
      {"name_uf": "GO", "name": "Buriti Alegre"},
      {"name_uf": "GO", "name": "Buriti de GO"},
      {"name_uf": "GO", "name": "Buritinópolis"},
      {"name_uf": "GO", "name": "Cabeceiras"},
      {"name_uf": "GO", "name": "Cachoeira Alta"},
      {"name_uf": "GO", "name": "Cachoeira de GO"},
      {"name_uf": "GO", "name": "Cachoeira Dourada"},
      {"name_uf": "GO", "name": "Caçu"},
      {"name_uf": "GO", "name": "Caiapônia"},
      {"name_uf": "GO", "name": "Caldas Novas"},
      {"name_uf": "GO", "name": "Caldazinha"},
      {"name_uf": "GO", "name": "Campestre de GO"},
      {"name_uf": "GO", "name": "Campinaçu"},
      {"name_uf": "GO", "name": "Campinorte"},
      {"name_uf": "GO", "name": "Campo Alegre de GO"},
      {"name_uf": "GO", "name": "Campo Limpo de GO"},
      {"name_uf": "GO", "name": "Campos Belos"},
      {"name_uf": "GO", "name": "Campos Verdes"},
      {"name_uf": "GO", "name": "Carmo do Rio Verde"},
      {"name_uf": "GO", "name": "Castelândia"},
      {"name_uf": "GO", "name": "Catalão"},
      {"name_uf": "GO", "name": "Caturaí"},
      {"name_uf": "GO", "name": "Cavalcante"},
      {"name_uf": "GO", "name": "Ceres"},
      {"name_uf": "GO", "name": "Cezarina"},
      {"name_uf": "GO", "name": "Chapadão do Céu"},
      {"name_uf": "GO", "name": "Cidade Ocidental"},
      {"name_uf": "GO", "name": "Cocalzinho de GO"},
      {"name_uf": "GO", "name": "Colinas do Sul"},
      {"name_uf": "GO", "name": "Córrego do Ouro"},
      {"name_uf": "GO", "name": "Corumbá de GO"},
      {"name_uf": "GO", "name": "Corumbaíba"},
      {"name_uf": "GO", "name": "Cristalina"},
      {"name_uf": "GO", "name": "Cristianópolis"},
      {"name_uf": "GO", "name": "Crixás"},
      {"name_uf": "GO", "name": "Cromínia"},
      {"name_uf": "GO", "name": "Cumari"},
      {"name_uf": "GO", "name": "Damianópolis"},
      {"name_uf": "GO", "name": "Damolândia"},
      {"name_uf": "GO", "name": "Davinópolis"},
      {"name_uf": "GO", "name": "Diorama"},
      {"name_uf": "GO", "name": "Divinópolis de GO"},
      {"name_uf": "GO", "name": "Doverlândia"},
      {"name_uf": "GO", "name": "Edealina"},
      {"name_uf": "GO", "name": "Edéia"},
      {"name_uf": "GO", "name": "Estrela do Norte"},
      {"name_uf": "GO", "name": "Faina"},
      {"name_uf": "GO", "name": "Fazenda Nova"},
      {"name_uf": "GO", "name": "Firminópolis"},
      {"name_uf": "GO", "name": "Flores de GO"},
      {"name_uf": "GO", "name": "Formosa"},
      {"name_uf": "GO", "name": "Formoso"},
      {"name_uf": "GO", "name": "Gameleira de GO"},
      {"name_uf": "GO", "name": "Goianápolis"},
      {"name_uf": "GO", "name": "Goiandira"},
      {"name_uf": "GO", "name": "Goianésia"},
      {"name_uf": "GO", "name": "Goiânia"},
      {"name_uf": "GO", "name": "Goianira"},
      {"name_uf": "GO", "name": "GO"},
      {"name_uf": "GO", "name": "Goiatuba"},
      {"name_uf": "GO", "name": "Gouvelândia"},
      {"name_uf": "GO", "name": "Guapó"},
      {"name_uf": "GO", "name": "Guaraíta"},
      {"name_uf": "GO", "name": "Guarani de GO"},
      {"name_uf": "GO", "name": "Guarinos"},
      {"name_uf": "GO", "name": "Heitoraí"},
      {"name_uf": "GO", "name": "Hidrolândia"},
      {"name_uf": "GO", "name": "Hidrolina"},
      {"name_uf": "GO", "name": "Iaciara"},
      {"name_uf": "GO", "name": "Inaciolândia"},
      {"name_uf": "GO", "name": "Indiara"},
      {"name_uf": "GO", "name": "Inhumas"},
      {"name_uf": "GO", "name": "Ipameri"},
      {"name_uf": "GO", "name": "Ipiranga de GO"},
      {"name_uf": "GO", "name": "Iporá"},
      {"name_uf": "GO", "name": "Israelândia"},
      {"name_uf": "GO", "name": "Itaberaí"},
      {"name_uf": "GO", "name": "Itaguari"},
      {"name_uf": "GO", "name": "Itaguaru"},
      {"name_uf": "GO", "name": "Itajá"},
      {"name_uf": "GO", "name": "Itapaci"},
      {"name_uf": "GO", "name": "Itapirapuã"},
      {"name_uf": "GO", "name": "Itapuranga"},
      {"name_uf": "GO", "name": "Itarumã"},
      {"name_uf": "GO", "name": "Itauçu"},
      {"name_uf": "GO", "name": "Itumbiara"},
      {"name_uf": "GO", "name": "Ivolândia"},
      {"name_uf": "GO", "name": "Jandaia"},
      {"name_uf": "GO", "name": "Jaraguá"},
      {"name_uf": "GO", "name": "Jataí"},
      {"name_uf": "GO", "name": "Jaupaci"},
      {"name_uf": "GO", "name": "Jesúpolis"},
      {"name_uf": "GO", "name": "Joviânia"},
      {"name_uf": "GO", "name": "Jussara"},
      {"name_uf": "GO", "name": "Lagoa Santa"},
      {"name_uf": "GO", "name": "Leopoldo de Bulhões"},
      {"name_uf": "GO", "name": "Luziânia"},
      {"name_uf": "GO", "name": "Mairipotaba"},
      {"name_uf": "GO", "name": "Mambaí"},
      {"name_uf": "GO", "name": "Mara Rosa"},
      {"name_uf": "GO", "name": "Marzagão"},
      {"name_uf": "GO", "name": "Matrinchã"},
      {"name_uf": "GO", "name": "Maurilândia"},
      {"name_uf": "GO", "name": "Mimoso de GO"},
      {"name_uf": "GO", "name": "Minaçu"},
      {"name_uf": "GO", "name": "Mineiros"},
      {"name_uf": "GO", "name": "Moiporá"},
      {"name_uf": "GO", "name": "Monte Alegre de GO"},
      {"name_uf": "GO", "name": "Montes Claros de GO"},
      {"name_uf": "GO", "name": "Montividiu"},
      {"name_uf": "GO", "name": "Montividiu do Norte"},
      {"name_uf": "GO", "name": "Morrinhos"},
      {"name_uf": "GO", "name": "Morro Agudo de GO"},
      {"name_uf": "GO", "name": "Mossâmedes"},
      {"name_uf": "GO", "name": "Mozarlândia"},
      {"name_uf": "GO", "name": "Mundo Novo"},
      {"name_uf": "GO", "name": "Mutunópolis"},
      {"name_uf": "GO", "name": "Nazário"},
      {"name_uf": "GO", "name": "Nerópolis"},
      {"name_uf": "GO", "name": "Niquelândia"},
      {"name_uf": "GO", "name": "Nova América"},
      {"name_uf": "GO", "name": "Nova Aurora"},
      {"name_uf": "GO", "name": "Nova Crixás"},
      {"name_uf": "GO", "name": "Nova Glória"},
      {"name_uf": "GO", "name": "Nova Iguaçu de GO"},
      {"name_uf": "GO", "name": "Nova Roma"},
      {"name_uf": "GO", "name": "Nova Veneza"},
      {"name_uf": "GO", "name": "Novo Brasil"},
      {"name_uf": "GO", "name": "Novo Gama"},
      {"name_uf": "GO", "name": "Novo Planalto"},
      {"name_uf": "GO", "name": "Orizona"},
      {"name_uf": "GO", "name": "Ouro Verde de GO"},
      {"name_uf": "GO", "name": "Ouvidor"},
      {"name_uf": "GO", "name": "Padre Bernardo"},
      {"name_uf": "GO", "name": "Palestina de GO"},
      {"name_uf": "GO", "name": "Palmeiras de GO"},
      {"name_uf": "GO", "name": "Palmelo"},
      {"name_uf": "GO", "name": "Palminópolis"},
      {"name_uf": "GO", "name": "Panamá"},
      {"name_uf": "GO", "name": "Paranaiguara"},
      {"name_uf": "GO", "name": "Paraúna"},
      {"name_uf": "GO", "name": "Perolândia"},
      {"name_uf": "GO", "name": "Petrolina de GO"},
      {"name_uf": "GO", "name": "Pilar de GO"},
      {"name_uf": "GO", "name": "Piracanjuba"},
      {"name_uf": "GO", "name": "Piranhas"},
      {"name_uf": "GO", "name": "Pirenópolis"},
      {"name_uf": "GO", "name": "Pires do Rio"},
      {"name_uf": "GO", "name": "Planaltina"},
      {"name_uf": "GO", "name": "Pontalina"},
      {"name_uf": "GO", "name": "Porangatu"},
      {"name_uf": "GO", "name": "Porteirão"},
      {"name_uf": "GO", "name": "Portelândia"},
      {"name_uf": "GO", "name": "Posse"},
      {"name_uf": "GO", "name": "Professor Jamil"},
      {"name_uf": "GO", "name": "Quirinópolis"},
      {"name_uf": "GO", "name": "Rialma"},
      {"name_uf": "GO", "name": "Rianápolis"},
      {"name_uf": "GO", "name": "Rio Quente"},
      {"name_uf": "GO", "name": "Rio Verde"},
      {"name_uf": "GO", "name": "Rubiataba"},
      {"name_uf": "GO", "name": "Sanclerlândia"},
      {"name_uf": "GO", "name": "Santa Bárbara de GO"},
      {"name_uf": "GO", "name": "Santa Cruz de GO"},
      {"name_uf": "GO", "name": "Santa Fé de GO"},
      {"name_uf": "GO", "name": "Santa Helena de GO"},
      {"name_uf": "GO", "name": "Santa Isabel"},
      {"name_uf": "GO", "name": "Santa Rita do Araguaia"},
      {"name_uf": "GO", "name": "Santa Rita do Novo Destino"},
      {"name_uf": "GO", "name": "Santa Rosa de GO"},
      {"name_uf": "GO", "name": "Santa Tereza de GO"},
      {"name_uf": "GO", "name": "Santa Terezinha de GO"},
      {"name_uf": "GO", "name": "Santo Antônio da Barra"},
      {"name_uf": "GO", "name": "Santo Antônio de GO"},
      {"name_uf": "GO", "name": "Santo Antônio do Descoberto"},
      {"name_uf": "GO", "name": "São Domingos"},
      {"name_uf": "GO", "name": "São Francisco de GO"},
      {"name_uf": "GO", "name": "São João da Paraúna"},
      {"name_uf": "GO", "name": "São João d'Aliança"},
      {"name_uf": "GO", "name": "São Luís de Montes Belos"},
      {"name_uf": "GO", "name": "São Luiz do Norte"},
      {"name_uf": "GO", "name": "São Miguel do Araguaia"},
      {"name_uf": "GO", "name": "São Miguel do Passa Quatro"},
      {"name_uf": "GO", "name": "São Patrício"},
      {"name_uf": "GO", "name": "São Simão"},
      {"name_uf": "GO", "name": "Senador Canedo"},
      {"name_uf": "GO", "name": "Serranópolis"},
      {"name_uf": "GO", "name": "Silvânia"},
      {"name_uf": "GO", "name": "Simolândia"},
      {"name_uf": "GO", "name": "Sítio d'Abadia"},
      {"name_uf": "GO", "name": "Taquaral de GO"},
      {"name_uf": "GO", "name": "Teresina de GO"},
      {"name_uf": "GO", "name": "Terezópolis de GO"},
      {"name_uf": "GO", "name": "Três Ranchos"},
      {"name_uf": "GO", "name": "Trindade"},
      {"name_uf": "GO", "name": "Trombas"},
      {"name_uf": "GO", "name": "Turvânia"},
      {"name_uf": "GO", "name": "Turvelândia"},
      {"name_uf": "GO", "name": "Uirapuru"},
      {"name_uf": "GO", "name": "Uruaçu"},
      {"name_uf": "GO", "name": "Uruana"},
      {"name_uf": "GO", "name": "Urutaí"},
      {"name_uf": "GO", "name": "Valparaíso de GO"},
      {"name_uf": "GO", "name": "Varjão"},
      {"name_uf": "GO", "name": "Vianópolis"},
      {"name_uf": "GO", "name": "Vicentinópolis"},
      {"name_uf": "GO", "name": "Vila Boa"},
      {"name_uf": "GO", "name": "Vila Propício"},
      {"name_uf": "DF", "name": "Brasília"}
    ]
  }
export default Estados;